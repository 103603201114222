import React from 'react';
import { HOME_URL, INFO_HOME_URL, INFO_FAQ_URL, INFO_CONTACT_URL, INFO_TERM_OF_SERVICE_URL, CL_PRIVACY_POLICY_URL, DSS_LOGO_URL, CL_LOGO_URL } from '../../AppConstants';

const Footer = () => {
  return (
    <footer className='jds-footer'>
      <div className='sitemap'>
        <div className='container-fluid'>
          <h3 className='jds-footer-sitemap-header text-left'>
            <a className="navbar-brand" href={HOME_URL} target='_blank'>
              <img
                src={DSS_LOGO_URL}
                alt='Document Signing Service'
              />
            </a>
          </h3>
          <div className='footer-links'>
            <a href={INFO_HOME_URL} target="_blank">About</a>
            <a href={INFO_FAQ_URL} target="_blank">FAQs</a>
            <a href={INFO_CONTACT_URL} target="_blank">Contact us</a>
          </div>
        </div>
      </div>
      <hr className='divider' />
      <div className='copyright'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='footer-links'>
                <a href={CL_PRIVACY_POLICY_URL} target="_blank">Privacy Policy</a>
                <a href={INFO_TERM_OF_SERVICE_URL} target="_blank">Terms of Use</a>
              </div>
            </div>
            <div className='col-lg-6 mobile-mt-2 text-right'>
              <div className='powered-by'>
                Powered by <img src={CL_LOGO_URL} alt='CrimsonLogic Pte Ltd' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
