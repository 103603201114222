import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import {
    fetchNewDraftArrangement, submitArrangement,
    fetchNewDraftArrangementForm, fetchDocumentSnapshots, fetchDocumentPageSnapshots
} from './signingRequestWizardAPI';
import { v4 as guid } from 'uuid';
//import { SIGNATURE_DIMENSION } from '../../appSettings';
import { IDENTIFICATION_TYPE_LIST } from '../../../AppConstants';
import { loadAppSettings } from '../../../AppSettings';
import { isStringNullOrEmpty } from '../../utils/validation';



const initialState = {

    arrangementId: '',
    uploadedDoc: {
        content: '',
        name: '',
        snapshots: [],
        title: '',
        forum: '',
        requirePageSerialization: false,
        state: 'new',
        pageCount: 0
    },
    //recipients: [],
    recipients: [{
        tag: 'Recipient 1',
        name: '',
        identificationNum: '',
        //identificationType: 'NRIC',
        //identificationTypeList: [{ key: 0, value: 'NRIC' }, { key: 1, value: 'Email' }],
        identificationTypeList: IDENTIFICATION_TYPE_LIST,
        identificationType: IDENTIFICATION_TYPE_LIST.find(idType => idType.selected).key.toString(),
        emailAddress: '',
        mobileNum: '',
        signingmethodList: [{ key: 1, value: 'Singpass' }, { key: 2, value: 'CL Digital' }],
        signingmethod: 0,
        signingOrder: 1,
        id: guid(),
        //dueDate: new Date().toISOString()
        dueDate: '',
        errorMessage: [],
        reminderTriggerDays: 1,
        emailTemplateContent: 'Dear {{rcpt_fullName}},\r\n'
            + '<br />\r\n'
            + '<br />\r\n'
            + 'This is a reminder sent by CrimsonLogic Digital Signing Service for a request pending your signature by {{sigreq_arranger}} for {{document_title}}. You have until {{sigreq_expiration}} to sign. After this date, this document will be deemed declined and the signing request will be terminated automatically.\r\n'
            + '<br />\r\n'
            + '<br />\r\n'
            + 'This is an auto - generated email.Please do not send any email to this sender.\r\n'
            + '<br />',
        emailTemplateSubject: '',
        isReminderRequired: false,
        isReminderDisplayed: false
    }],
    // recipients: [{ name: "UserA", signingOrder: 1 }, { name: "UserB", signingOrder: 2 }, { name: "UserC", signingOrder: 3 }],
    accessCodes: [],
    directLink: '',
    stamps: {},
    stampCounterTracker: {},
    currentCanvasPage: 1,
    currentStep: 1,
    //dueDate: new Date().toISOString(),
    dueDate: '',
    status: 'idle',
    error: null,
    appSettings: null,
    showProgressModal: false,
    rasterProgress: 0,
    storeSigOrderType: 'Sequential',
    errMessageDt: ''
};


const MAX_PAGE_WIDTH = 890;
const MAX_PAGE_HEIGHT = 1259;


// const MAX_STEP = 4;
// const MIN_STEP = 1;

export const signingRequestWizardSlice = createSlice({
    name: 'signingRequestWizard',
    initialState,

    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        unSetError: (state) => {
            state.error = null;
        },
        setStep1Complete: (state, action) => {
            state.progress = {
                ...state.progress,
                sOne: true
            };
        },
        updateDocumentName: (state, action) => {
            state.uploadedDoc = {
                ...state.uploadedDoc,
                name: action.payload
            };
        },
        updateDocumentTitle: (state, action) => {
            state.uploadedDoc = {
                ...state.uploadedDoc,
                title: action.payload
            };
        },
        updateDocumentForum: (state, action) => {
            state.uploadedDoc = {
                ...state.uploadedDoc,
                forum: action.payload
            };
        },
        updatePageSerialization: (state, action) => {
            state.uploadedDoc = {
                ...state.uploadedDoc,
                requirePageSerialization: action.payload
            };
        },
        updateDueDate: (state, action) => {
            let recipientsDueDate = state.recipients
                .map(recipient => new Date(recipient.dueDate))
                .concat(new Date(action.payload))
                .filter(date => !isNaN(date));
            let requestDueDate = new Date(Math.max.apply(null, recipientsDueDate)).toISOString();
            console.log('Due Date:', requestDueDate);
            state.dueDate = requestDueDate;

        },

        updaterecipientduedate: (state, action) => {

            console.log('Recipient Due Date:', action.payload)
            state.recipients[action.payload.index].dueDate = action.payload.dateValue;

        },

        addRecipientDetails: (state, action) => {
            state.recipients[action.payload.index].id = guid();

            if (action.payload.propertyname === 'Recipient') {
                state.recipients[action.payload.index].name = action.payload.name;
            }
            if (action.payload.propertyname === 'IdentNum') {
                state.recipients[action.payload.index].identificationNum = action.payload.name;
            }

            if (action.payload.propertyname === 'email') {
                state.recipients[action.payload.index].emailAddress = action.payload.name;
            }

            if (action.payload.propertyname === 'mobilenum') {
                state.recipients[action.payload.index].mobileNum = action.payload.name;
            }

            if (action.payload.propertyname === 'signmethod') {
                state.recipients[action.payload.index].signingmethod = action.payload.name;
            }

            if (action.payload.propertyname === 'identityType') {
                state.recipients[action.payload.index].identificationType = action.payload.name;
            }
            if (action.payload.propertyname === 'emailTemplateContent') {
                state.recipients[action.payload.index].emailTemplateContent = action.payload.name;
            }
            if (action.payload.propertyname === 'reminderTriggerDays') {
                state.recipients[action.payload.index].reminderTriggerDays = action.payload.name;
            }
            if (action.payload.propertyname === 'isReminderRequired') {
                state.recipients[action.payload.index].isReminderRequired = action.payload.name;
            }
            if (action.payload.propertyname === 'isReminderDisplayed') {
                state.recipients[action.payload.index].isReminderDisplayed = action.payload.name;
            }
        },
        addRecipient: (state, action) => {
            state.recipients =
                [
                    ...state.recipients,
                    action.payload,
                ]
            console.log('Adding Recipient:', state.recipients);

        },

        replaceRecipient: (state, action) => {
            console.log('In Dispatch State Recipients:', action.payload);
            state.recipients = action.payload;
        },
        addStamp: (state, action) => {
            const payload = action.payload;

            let stamp = {
                ...action.payload
            };
            state.error = null;
            if (state.stamps.hasOwnProperty(payload.signerId)) {
                stamp.counter = state.stampCounterTracker[payload.signerId] + 1;
                state.stampCounterTracker[payload.signerId] = state.stampCounterTracker[payload.signerId] + 1;
                const stampsFilteredBySigner = state.stamps[payload.signerId];


                let hasCollision = false;
                do {
                    stamp.id = guid();
                    let existing = stampsFilteredBySigner.find((item) => item.id === stamp.id);
                    if (existing !== undefined) {
                        hasCollision = true;
                    }
                } while (hasCollision);

                state.stamps[payload.signerId] = [...stampsFilteredBySigner, stamp];
            } else {

                stamp.id = guid();
                state.stamps[payload.signerId] = [stamp];

                state.stampCounterTracker[payload.signerId] = 1;
            }
        },

        deleteRecipient: (state, action) => {
            const id = action.payload;
            const recipientToDelete = state.recipients.find((d) => d.id === id);
            const newRecipientSet = state.recipients.filter((d) => d.id !== id);
            const data = newRecipientSet.map((d) => {
                if (d.signingOrder > recipientToDelete.signingOrder) {
                    d.signingOrder--;
                }
                return d;
            });
            state.recipients = data;
            if (state.stamps.hasOwnProperty(id)) {
                delete state.stamps[id];
            }

            //update docDueDate
            let newDueDate = new Date().toISOString();
            if (data.length > 0) {
                var dd = data[data.length - 1].dueDate;
                console.log(dd);
                newDueDate = dd;
            }

            state.dueDate = newDueDate;
        },

        deleteStamp: (state, action) => {
            const payload = action.payload;
            state.error = null;
            if (state.stamps.hasOwnProperty(payload.signerId)) {
                const matchesPayloadId = (e) => e.id === payload.id;
                const iToRemove = state.stamps[payload.signerId].findIndex(matchesPayloadId);
                state.stamps[payload.signerId] = [...state.stamps[payload.signerId].slice(0, iToRemove), ...state.stamps[payload.signerId].slice(iToRemove + 1)];
            }
        },

        storeSignatureOrderType: (state, action) => {
            const payload = action.payload;
            console.log('Signature Order Type:', action);
            state.storeSigOrderType = action.payload;
        },
        toggleSigOrderType: (state) => {
            console.log(`[togglesigordertype]  sig order type is: ${state.storeSigOrderType}`);
            if (state.storeSigOrderType === "Concurrent") {
                state.storeSigOrderType = "Sequential";
            } else if (state.storeSigOrderType === "Sequential") {
                console.log(`toggling sigOrderType to Concurrent`);
                state.storeSigOrderType = "Concurrent";
                state.errMessageDt = '';
                console.log(`sigOrderType is ${state.storeSigOrderType}`);
            }
        },


        validateRecipientStore: (state) => {
            console.log('Validate Recipients List:', state.recipients);

            state.recipients.map((item) => {
                item.errorMessage = [];
                console.log('Identification Type:', item.identificationType)
                console.log('Identification Number:', item.identificationNum)

                if (item.identificationType === '') {
                    item.errorMessage.push({ propertyname: 'identityType', msg: 'Identification Type is required' })
                }

                if ((item.emailAddress !== '') && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item.emailAddress))) {
                    item.errorMessage.push({ propertyname: 'emailAddress', msg: 'Email is invalid' })
                }

                if (item.identificationNum !== '') {
                    if (item.identificationNum.length === 4) {
                        let numbers = item.identificationNum.substring(0, 3);
                        let lastChar = item.identificationNum.substring(3, 4);
                        if (isNaN(numbers) || (!/^[a-zA-Z]+$/.test(lastChar))) {
                            item.errorMessage.push({ propertyname: 'identificationNum', msg: 'NRIC entered is invalid' })
                        }

                    }
                }



                if (item.identificationType === '0') {
                    item.signingmethod = 30
                } else if (item.identificationType === '1') {
                    item.signingmethod = 50
                } else if (item.identificationType === '2') {
                    item.signingmethod = 0
                }

                if (item.identificationNum !== '' && item.identificationNum.length === 4) {
                    let numbers = item.identificationNum.substring(0, 3);
                    let lastChar = item.identificationNum.substring(3, 4);
                    if (isNaN(numbers) || (!/^[a-zA-Z]+$/.test(lastChar))) {
                        item.errorMessage.push({ propertyname: 'identificationNum', msg: 'NRIC entered is invalid' })
                    }
                }

                if ((item.emailAddress !== '') && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item.emailAddress))) {
                    item.errorMessage.push({ propertyname: 'emailAddress', msg: 'Email is invalid' })
                }

                if (item.name === '') {
                    item.errorMessage.push({ propertyname: 'name', msg: 'Recipient Name is required' })
                }
                if (item.dueDate === '') {
                    item.errorMessage.push({ propertyname: 'dueDate', msg: 'Sign by end value is required' })
                }
                if (item.isReminderRequired === true && item.emailAddress === '') {
                    item.errorMessage.push({ propertyname: 'emailAddress', msg: 'Email address is required to send reminder' })
                }

                var today = new Date();
                var dueDate = new Date(item.dueDate);

                today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                dueDate = new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate());

                const diffTime = Math.abs(dueDate - today);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


                if (item.isReminderRequired === true && item.reminderTriggerDays > diffDays - 1) {
                    item.errorMessage.push({ propertyname: 'reminderTriggerDays', msg: 'Amount of days should not exceed expiry date or be more than the overall duration' })
                }

            })

            const DateArry = [];
            let isDueDateavailable = true;
            if (state.storeSigOrderType.toLowerCase() === 'sequential') {
                [...state.recipients].forEach((r) => {
                    if (r.dueDate === '') {
                        isDueDateavailable = false;
                    }
                    DateArry.push(new Date(Date.parse(r.dueDate)))
                });

                if (isDueDateavailable === true) {
                    const sortedDates = DateArry =>
                        DateArry.every((val, i) => !i || DateArry[i - 1] <= val);
                    console.log('Date Array:', DateArry);
                    console.log(sortedDates(DateArry));
                    if (sortedDates(DateArry) === false) {
                        state.errMessageDt = 'Please ensure the recipient\'s due date are in sequential order';
                    }
                    else {
                        state.errMessageDt = '';
                    }
                }
            }
        },

        validateRecipientPlaceholder: (state) => {
            const SIGNATURE_DIMENSION = state.appSettings.signatureDimension;
            let notAvailable = [];
            state.recipients.forEach((r) => {
                if (!(state.stamps.hasOwnProperty(r.id) && state.stamps[r.id].length > 0)) notAvailable = [...notAvailable, r.name];
            });

            let collision = [];
            const data = Object.values(state.stamps).flat();
            if (data.length > 1) {
                for (let r1 of data) {
                    const r1ScaleRatio = r1.coordinate.scaleRatio;
                    const pHeight = SIGNATURE_DIMENSION.height / r1ScaleRatio;
                    const r1Width = r1.hasSecureCode ? SIGNATURE_DIMENSION.widthWithSecureCode / r1ScaleRatio : SIGNATURE_DIMENSION.width / r1ScaleRatio;
                    for (let r2 of data) {
                        if (r1.coordinate.page === r2.coordinate.page) {
                            if (r1.signerId === r2.signerId && r1.counter === r2.counter) break;
                            else {
                                const r2Width = r2.hasSecureCode ? SIGNATURE_DIMENSION.widthWithSecureCode / r2.coordinate.scaleRatio : SIGNATURE_DIMENSION.width / r2.coordinate.scaleRatio;
                                if (
                                    !(
                                        r2.coordinate.x > r1.coordinate.x + r1Width ||
                                        r2.coordinate.x + r2Width < r1.coordinate.x ||
                                        r2.coordinate.y > r1.coordinate.y + pHeight ||
                                        r2.coordinate.y + pHeight < r1.coordinate.y
                                    )
                                ) {
                                    collision = [...collision, `${r2.signerName} (${r2.counter}) - ${r1.signerName} (${r1.counter})`];
                                }
                            }
                        }
                    }
                }
            }



            if (notAvailable.length > 0 || collision.length > 0) {
                let errorMessage = '';
                if (notAvailable.length > 0) {
                    errorMessage = `There is no placeholder for the following`;
                    errorMessage = notAvailable.length > 1 ? `${errorMessage} recipients` : `${errorMessage} recipient`;
                    errorMessage = `${errorMessage} (${notAvailable.join(', ')}).`;
                }

                if (collision.length > 0) {
                    const collisionErrorMessage = `Collision detection found for the following placeholders (${collision.join(', ')}).`;
                    errorMessage = errorMessage === '' ? `${collisionErrorMessage}` : `${errorMessage}\n${collisionErrorMessage}`;
                }
                state.error = errorMessage;
            } else {
                state.error = null;
                state.currentStep = state.currentStep + 1;
            }
        },
        updateStampCoordinateAction: (state, action) => {
            const payload = action.payload;
            state.error = null;
            if (state.stamps.hasOwnProperty(payload.signerId)) {
                const matchesPayloadId = (e) => e.id === payload.id;
                const iToUpdate = state.stamps[payload.signerId].findIndex(matchesPayloadId);
                state.stamps[payload.signerId][iToUpdate] = {
                    ...state.stamps[payload.signerId][iToUpdate],
                    coordinate: {
                        ...payload.coordinate
                    }
                };
            }
        },
        incrementCanvasPage: (state, action) => {
            state.currentCanvasPage = state.currentCanvasPage + 1;
        },
        decrementCanvasPage: (state, action) => {
            state.currentCanvasPage = state.currentCanvasPage - 1;
        },



        incrementCurrentStep: (state, action) => {
            console.log('incrementCurrentStep is called', state.currentStep);
            if (state.currentStep === 3) {
                signingRequestWizardSlice.caseReducers.validateRecipientPlaceholder(state);
            } else {
                if (state.currentStep < 4) {
                    state.currentStep = state.currentStep + 1;
                    console.log('First step:', state.currentStep);
                }
                state.error = null;
            }
        },
        decrementCurrentStep: (state, action) => {
            if (state.currentStep > 1) {
                state.currentStep = state.currentStep - 1;
            }
            state.error = null;
        },
        showModal: (state, action) => {
            state.showProgressModal = action.payload;
        },
        setRasterProgress: (state, action) => {
            console.log(action);
            if (action.payload > state.rasterProgress)
                state.rasterProgress = action.payload;
        },


        initStartStep: (state, action) => {
            state.currentStep = 1;
            state.arrangementId = '';
            state.uploadedDoc = {
                content: '',
                name: '',
                snapshots: [],
                title: '',
                forum: '',
                requirePageSerialization: false
            };
            // state.recipients = [];

            state.recipients = [{
                tag: 'Recipient 1',
                name: '',
                identificationNum: '',
                //identificationType: 'NRIC',
                identificationType: IDENTIFICATION_TYPE_LIST.find(idType => idType.selected).key.toString(),
                //identificationTypeList: [{ key: 0, value: 'NRIC' }, { key: 1, value: 'Email' }],
                identificationTypeList: IDENTIFICATION_TYPE_LIST,
                emailAddress: '',
                mobileNum: '',
                signingOrder: 1,
                signingmethodList: [{ key: 1, value: 'Singpass' }, { key: 2, value: 'CL Digital' }],
                signingmethod: 0,
                id: guid(),
                //dueDate: new Date().toISOString()
                dueDate: '',
                errorMessage: [],
                reminderTriggerDays: 1,
                emailTemplateContent: 'Dear {{rcpt_fullName}},\r\n'
                    + '<br />\r\n'
                    + '<br />\r\n'
                    + 'This is a reminder sent by CrimsonLogic Digital Signing Service for a request pending your signature by {{sigreq_arranger}} for {{document_title}}. You have until {{sigreq_expiration}} to sign. After this date, this document will be deemed declined and the signing request will be terminated automatically.\r\n'
                    + '<br />\r\n'
                    + '<br />\r\n'
                    + 'This is an auto - generated email.Please do not send any email to this sender.\r\n'
                    + '<br />',
                emailTemplateSubject: '',
                isReminderRequired: false,
                isReminderDisplayed: false
            }];
            state.accessCodes = [];
            state.directLink = '';
            state.stamps = {};
            state.stampCounterTracker = {};
            state.currentCanvasPage = 1;
            state.error = null;
            state.dueDate = new Date().toISOString();
            //state.dueDate = '';
            state.rasterProgress = 0;
            state.storeSigOrderType = 'Sequential';
            state.appSettings = null
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            //new draft arrangement
            .addCase(newDraftArrangementAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(newDraftArrangementAsync.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.error = null;
                state.arrangementId = action.payload.arrangementId;

                state.uploadedDoc = {
                    ...state.uploadedDoc,
                    snapshots: updateSnapshotsWithScaleRatio(action.payload.snapshots),
                    content: action.payload.fileContent,
                    name: action.payload.fileName,
                    title: action.payload.docTitle,

                };
            })
            .addCase(newDraftArrangementAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            //new draft arrangement Form
            .addCase(newDraftArrangementFormAsync.pending, (state) => {
                state.status = 'loading';
                state.uploadedDoc = {
                    ...state.uploadedDoc,
                    uploaded: false
                }
            })
            .addCase(newDraftArrangementFormAsync.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.error = null;
                state.arrangementId = action.payload.arrangementId;

                state.uploadedDoc = {
                    ...state.uploadedDoc,
                    name: action.payload.fileName,
                    title: action.payload.docTitle,
                    state: 'uploaded',
                    pageCount: action.payload.pageCount
                };
            })
            .addCase(newDraftArrangementFormAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            //
            // fetchDocSnapshots
            //
            .addCase(fetchDocumentSnapshotsAsync.pending, (state, action) => {
                state.status = 'loading-silent';
                state.error = null;

            })
            .addCase(fetchDocumentSnapshotsAsync.fulfilled, (state, action) => {
                console.log(action.payload);
                state.status = 'fulfilled';
                state.error = null;

                if (action.payload.snapshots.length > 0) {
                    state.uploadedDoc = {
                        ...state.uploadedDoc,
                        snapshots: updateSnapshotsWithScaleRatio(action.payload.snapshots),
                        state: 'rasterized'
                    };

                };

            })
            .addCase(fetchDocumentSnapshotsAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            //
            // fetchDocPageSnapshots
            //
            .addCase(fetchDocumentPageSnapshotsAsync.pending, (state, action) => {
                state.status = 'loading-silent';
                state.error = null;
                state.rasterProgress = 98;

            })
            .addCase(fetchDocumentPageSnapshotsAsync.fulfilled, (state, action) => {
                console.log(action.payload);
                state.status = 'fulfilled';
                state.error = null;
                state.rasterProgress = 100;

                if (action.payload.snapshots.length > 0) {
                    console.log("checking if the snapshot is already in session")
                    console.log(action.payload.snapshots.page)

                    var filteredSnapshots = action.payload.snapshots.filter(
                        (snapshot) => {
                            if (snapshot !== undefined) {
                                let pageSnapshot = state.uploadedDoc.snapshots.find((t) => t.page === snapshot.page);
                                console.log("pagesnapshot", pageSnapshot, "snapshot", snapshot)
                                if (pageSnapshot === undefined) return { ...snapshot }
                            }
                        }
                    )

                    if (filteredSnapshots.length > 0) {
                        console.log("filteredSnapshot:", filteredSnapshots);
                        console.log("snapshot is not in session.  concatenating snapshot");

                        const newSnapshot = updateSnapshotsWithScaleRatio(filteredSnapshots);
                        let snapshotArray = [...state.uploadedDoc.snapshots, ...newSnapshot];
                        console.log(snapshotArray);
                        state.uploadedDoc = {
                            ...state.uploadedDoc,
                            snapshots: snapshotArray,
                            state: 'rasterized'
                        };
                    }

                    // let pageSnapshot = state.uploadedDoc.snapshots.find((t) => t.page === action.payload.snapshots.page);

                    // if (pageSnapshot === undefined) {
                    //     console.log("snapshot is not in session.  concatenating snapshot");

                    //     const newSnapshot = updateSnapshotsWithScaleRatio(action.payload.snapshots);
                    //     let snapshotArray = [...state.uploadedDoc.snapshots, ...newSnapshot];
                    //     console.log(snapshotArray);
                    //     state.uploadedDoc = {
                    //         ...state.uploadedDoc,
                    //         snapshots: snapshotArray,
                    //         state: 'rasterized'
                    //     };
                    // }



                };

            })
            .addCase(fetchDocumentPageSnapshotsAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })


            //submit arrangement
            .addCase(submitArrangementAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(submitArrangementAsync.fulfilled, (state, action) => {
                state.status = 'fulfilled';
                state.currentStep = 5;
                state.accessCodes = action.payload.accessCodes;
                state.directLink = action.payload.directLink;
            })
            .addCase(submitArrangementAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchAppSettingsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAppSettingsAsync.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.status = 'fulfilled';
                    state.appSettings = action.payload;
                }
            })
            .addCase(fetchAppSettingsAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

const updateSnapshotsWithScaleRatio = (oSnapshots) => {
    return oSnapshots.map((snap) => {
        const isPortraitSnap = snap.height > snap.width;
        let snapScaleRatio = 1;
        if (isPortraitSnap) {
            if (snap.height > MAX_PAGE_HEIGHT) {
                snapScaleRatio = snap.height / MAX_PAGE_HEIGHT;
            }
        } else {
            if (snap.width > MAX_PAGE_WIDTH) {
                snapScaleRatio = snap.width / MAX_PAGE_WIDTH;
            }
        }
        return {
            ...snap,
            scaleRatio: snapScaleRatio
        };
    });
};

export const {
    updateDocumentName,
    updateDocumentTitle,
    updateDocumentForum,
    updatePageSerialization,
    setStep1Complete,
    addRecipient,
    addRecipientDetails,
    addStamp,
    updateDueDate,
    updateStampCoordinateAction,
    deleteStamp,
    deleteRecipient,
    replaceRecipient,
    incrementCanvasPage,
    decrementCanvasPage,
    incrementCurrentStep,
    decrementCurrentStep,
    initStartStep,
    unSetError,
    validateRecipientPlaceholder,
    showModal,
    setRasterProgress,
    validateRecipientStore,
    storeSignatureOrderType,
    toggleSigOrderType,
    updaterecipientduedate

} = signingRequestWizardSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectStatus = (state) => state.signingRequestWizard.status;
export const selectError = (state) => state.signingRequestWizard.error;
export const selectIsLoading = (state) => state.signingRequestWizard.status === 'loading';
export const selectArrangementId = (state) => state.signingRequestWizard.arrangementId;

export const selectUploadedDocContent = (state) => state.signingRequestWizard.uploadedDoc.content;
export const selectUploadedDocName = (state) => state.signingRequestWizard.uploadedDoc.name;
export const selectUploadedDocCompleted = (state) => state.signingRequestWizard.uploadedDoc.uploaded;
export const selectUploadedDocSnapshot = (state) => state.signingRequestWizard.uploadedDoc.snapshots;
export const selectCurrentPageSnapshot = (state) => state.signingRequestWizard.uploadedDoc.snapshots.find((t) => t.page === state.signingRequestWizard.currentCanvasPage);

export const selectUploadedDocTitle = (state) => state.signingRequestWizard.uploadedDoc.title;
export const selectUploadedDocForum = (state) => state.signingRequestWizard.uploadedDoc.forum;
export const selectUploadedDocPageSerialization = (state) => state.signingRequestWizard.uploadedDoc.requirePageSerialization;
export const selectTotalPageCount = (state) => state.signingRequestWizard.uploadedDoc.pageCount;
export const selectAccessCodes = (state) => state.signingRequestWizard.accessCodes;
export const selectDirectLink = (state) => state.signingRequestWizard.directLink;
export const selectRecipients = (state) => state.signingRequestWizard.recipients;

export const selectCurrentCanvasPage = (state) => state.signingRequestWizard.currentCanvasPage;
export const selectCurrentStep = (state) => state.signingRequestWizard.currentStep;
export const selectIsStep1Complete = (state) => {
    return (
        !isStringNullOrEmpty(state.signingRequestWizard.arrangementId) &&
        (state.signingRequestWizard.uploadedDoc?.state === 'uploaded' ||
            state.signingRequestWizard.uploadedDoc?.state === 'rasterized') &&
        !isStringNullOrEmpty(state.signingRequestWizard.uploadedDoc?.title) &&
        !isStringNullOrEmpty(state.signingRequestWizard.uploadedDoc?.forum)
    );
};

export const selectIsDocumentRasterized = (state) => {
    return (state.signingRequestWizard.uploadedDoc?.state === 'rasterized');
}

export const selectDueDate = (state) => state.signingRequestWizard.dueDate;

export const selectShowProgressModal = (state) => state.signingRequestWizard.showProgressModal;

export const selectRasterProgress = (state) => state.signingRequestWizard.rasterProgress;

export const errorMessageDate = (state) => state.signingRequestWizard.errMessageDt

export const storeSignatureOrder = (state) => state.signingRequestWizard.storeSigOrderType;
export const selectStoreSigOrderType = (state) => state.signingRequestWizard.storeSigOrderType;
export const selectSignatureOrderTypeCode = (state) => {
    if (state.signingRequestWizard.storeSigOrderType === undefined) return 10;

    if (state.signingRequestWizard.storeSigOrderType.toLowerCase() === "sequential") return 10;
    if (state.signingRequestWizard.storeSigOrderType.toLowerCase() === "concurrent")
        return 20;

    return 10;

}

export const selectIsSignatureOrderTypeSequential = (state) => {
    if (state.signingRequestWizard.storeSigOrderType === undefined) return true;
    return state.signingRequestWizard.storeSigOrderType.toLowerCase() === "sequential";
}

export const selectAppSettings = (state) => state.signingRequestWizard.appSettings;

export const selectRecipientsList = (state) => state.signingRequestWizard.recipients;


export const selectStamps = (page) => (state) => {
    if (state.signingRequestWizard.stamps !== undefined && state.signingRequestWizard.stamps.length > 0) {
        let stampsInPage = {};

        Object.entries(state.signingRequestWizard.stamps).map((item) => {
            stampsInPage[item[0]] = item[1].find((t) => t.coordinate.page === page);
        });

        return stampsInPage;
    } else return {};
};

export const selectAllStampsInDoc = (state) => state.signingRequestWizard.stamps;

export const selectStampsAsFlatArray = (page) => (state) => {
    if (state.signingRequestWizard.stamps !== undefined) {
        let stampsInPage = [];
        for (const signer in state.signingRequestWizard.stamps) {
            const sss = state.signingRequestWizard.stamps[signer].filter((t) => t.coordinate.page === page);
            stampsInPage = stampsInPage.concat(sss);
        }
        return stampsInPage;
    } else return [];
};

export const selectIsStep2Complete = (state) => {
    return state.signingRequestWizard.recipients?.length > 0;
};

export const selectIsStep2Complete1 = (state) => {
    return state.signingRequestWizard.name?.length > 0;
};

export const selectIsStep3Complete = (state) => {
    return Object.getOwnPropertyNames(state.signingRequestWizard.stamps).length > 0;
};

export const selectErrorMsg = (state) => state.signingRequestWizard.error;

export default signingRequestWizardSlice.reducer;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const newDraftArrangementAsync = createAsyncThunk('signingRequestWizard/newDraftArrangement', async (payload) => {
    const response = await fetchNewDraftArrangement(payload.fileContent, payload.fileName, payload.docTitle, payload.docForum, payload.docRequirePageSerialization);
    const t = {
        ...response,
        fileContent: payload.fileContent,
        docTitle: payload.docTitle,
        fileName: payload.fileName,
        docForum: payload.docForum
    };
    console.log(t);
    // The value we return becomes the `fulfilled` action payload
    return t;
});

export const newDraftArrangementFormAsync = createAsyncThunk('signingRequestWizard/newDraftArrangementForm', async (payload) => {
    console.log("[slice]: file to upload:");
    console.log(payload.file);

    const response = await fetchNewDraftArrangementForm(payload.file, payload.docTitle, payload.docForum, payload.docRequirePageSerialization);
    const t = {
        ...response,
        docTitle: payload.docTitle,
        fileName: payload.file.name,
        docForum: payload.docForum
    };
    console.log(t);
    // The value we return becomes the `fulfilled` action payload
    return t;
});

export const fetchDocumentSnapshotsAsync = createAsyncThunk('signingRequestWizard/fetchDocumentSnapshots', async (arrangementId) => {
    const response = await fetchDocumentSnapshots(arrangementId);
    return response;
});


export const fetchDocumentPageSnapshotsAsync = createAsyncThunk('signingRequestWizard/fetchDocumentPageSnapshots', async (payload) => {
    console.log("[slice] fetchDocumentPageSnapshotAsync");
    console.log(payload.arrangementId);
    const response = await fetchDocumentPageSnapshots(payload.arrangementId, payload.pageNum);
    console.log("[slice] fetchDocumentPageSnapshotAsync completed");
    console.log(response);
    return response;
});


export const submitArrangementAsync = createAsyncThunk('signingRequestWizard/submitArrangement', async (payload) => {
    console.log('Submitting to Server:', payload);
    return await submitArrangement(payload);
});

export const updateDraftArrangementAsync = createAsyncThunk('signingRequestWizard/updateDraftArrangement', async ({ getState }) => {
    const state = getState();
    console.log('From this');
    console.log(state.arrangementId);
    // console.log(JSON.stringify(arg));
    // return arg;
});

export const fetchAppSettingsAsync = createAsyncThunk('signingRequestWizard/appSettings', async () => {
    const response = await loadAppSettings();
    return response
})
