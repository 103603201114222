import { configureStore } from '@reduxjs/toolkit';
import myDashboardReducer from '../app/features/myDashboard/myDashboardSlice';
import signingRequestWizardReducer from '../app/features/newSigningRequestWizard/signingRequestWizardSlice';

export const store = configureStore({
    reducer: {
        myDashboard: myDashboardReducer,
        signingRequestWizard: signingRequestWizardReducer
    }
});
