const defaultPxLength = 8;
const characterPxLength = {
    ' ': 3,
    1: 5,
    2: 8,
    3: 6,
    4: 7,
    5: 7,
    6: 6,
    7: 7,
    7: 7,
    8: 7,
    9: 6,
    0: 5,
    a: 5,
    b: 6.5,
    c: 6,
    d: 6.5,
    e: 7,
    f: 3,
    g: 7,
    h: 6.5,
    i: 2.5,
    j: 2.5,
    k: 6.5,
    l: 3,
    m: 9.5,
    n: 6.5,
    o: 7,
    p: 6.5,
    q: 6.5,
    r: 4.5,
    s: 5.5,
    t: 3.5,
    u: 7,
    v: 6,
    w: 8,
    x: 6.5,
    y: 6,
    z: 6,
    A: 7.5,
    B: 7.5,
    C: 8.5,
    D: 8.5,
    E: 8,
    F: 7.5,
    G: 9.5,
    H: 9,
    I: 3,
    J: 6,
    K: 9,
    L: 6,
    M: 10,
    N: 9,
    O: 9,
    P: 8,
    Q: 9,
    R: 9,
    S: 8,
    T: 8,
    U: 8,
    V: 8,
    W: 11,
    X: 8,
    Y: 8,
    Z: 8
}

export const GetTextPixelLength = (text) => {
    try {
        const characters = text.split('');
        const length = characters.reduce((partialSum, current) => {
            const currentCharLength = characterPxLength[current] ? characterPxLength[current] : defaultPxLength;
            return partialSum + currentCharLength;
        }, 0);

        console.debug(length);
        return length;
    } catch (err) {
        console.error(err);
        return 0;
    }
}