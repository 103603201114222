import React, { useState, useRef, useEffect, Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InPageAlert } from '../shared/inPageAlert.jsx';
import { addRecipient, addRecipientDetails, selectDueDate, updateDueDate, selectRecipientsList, selectIsDocumentRasterized, fetchDocumentSnapshotsAsync, fetchDocumentPageSnapshotsAsync, selectArrangementId, selectStatus, selectShowProgressModal, selectRasterProgress, incrementCurrentStep, showModal, setRasterProgress, replaceRecipient, storeSignatureOrderType, selectStoreSigOrderType, selectIsSignatureOrderTypeSequential, toggleSigOrderType, selectRecipients, updaterecipientduedate, errorMessageDate, selectAppSettings } from '../signingRequestWizardSlice';
import Flatpickr from 'react-flatpickr';
import css from './../shared/flatpickr.min.css';
import './../shared/recipient.css';
import { Button, NavItem } from 'react-bootstrap';
import { selectUploadedDocName } from "../signingRequestWizardSlice";
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { error } from 'jquery';
import { v4 as guid } from 'uuid';
import { RecipientsList } from './recipientsList.jsx';
import { IDENTIFICATION_TYPE_LIST } from '../../../../AppConstants.js';
import { Tooltip } from 'react-tooltip';




const defaultData = {
    name: '',
    identificationNum: '',
    identificationType: 'NRIC',
    emailAddress: '',
    mobileNum: '',
    signingOrder: 1,
    id: '',
    //dueDate: new Date().toISOString(),
    dueDate: ''
};


export function AddRecipientForm(props) {
    const [name, setName] = useState('');
    const [identificationNum, setIdentificationNum] = useState('');
    const [identificationType, setIdentificationType] = useState('NRIC');
    const [emailAddress, setEmailAddress] = useState('');
    const [mobileNum, setMobileNum] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [signingOrder, setSigningOrder] = useState(1);
    const datePicker = useRef();
    const [isName, setIsName] = useState(false);
    const [isDueDate, setIsDueDate] = useState(false);
    const [isNric, setIsNric] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const docDueDate = useSelector(selectDueDate);
    const closeRef = useRef();
    const dispatch = useDispatch();
    const fileName = useSelector(selectUploadedDocName);
    const showProgressModal = useSelector(selectShowProgressModal);
    const rasterProgress = useSelector(selectRasterProgress);
    const [count, setCount] = useState(1);
    const isDocRasterized = useSelector(selectIsDocumentRasterized);
    const arrangementId = useSelector(selectArrangementId);
    const recipients = useSelector(selectRecipientsList);
    const formStatus = useSelector(selectStatus);
    const storeSignatureOrder = useSelector(selectStoreSigOrderType);
    const errMessageDate = useSelector(errorMessageDate);
    const [data, setData] = useState(['']);

    const dragItem = useRef();
    const dragOverItem = useRef();
    const [draggable, setDraggable] = useState(true)
    const [checked, setChecked] = useState(true);
    const allowSignerReordering = useSelector(selectIsSignatureOrderTypeSequential);

    const appSettings = useSelector(selectAppSettings);


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
         in place of 'smooth' */
        });
    };
    //let SigOrderType;
    //dispatch(storeSignatureOrderType(draggable ? SigOrderType = 'Concurrent' : 'Sequential'));
    //const appSettings = await loadAppSettings();

    const recipient = {
        tag: '', // Recipient 2
        name: '',
        identificationNum: '',
        identificationType: IDENTIFICATION_TYPE_LIST.find(idType => idType.selected).key.toString(),
        //identificationTypeList: [{ key: 0, value: 'NRIC' }, { key: 1, value: 'Email' }],
        identificationTypeList: IDENTIFICATION_TYPE_LIST,
        emailAddress: '',
        mobileNum: '',
        // signingmethod: ['singpass', 'CL Digital'],
        signingmethodList: [{ key: 1, value: 'Singpass' }, { key: 2, value: 'CL Digital' }],
        signingmethod: 0,
        signingOrder: (storeSignatureOrder === 'Sequential' ? (recipients.length + 1) : 1),
        // id: guid(),
        id: '',
        // dueDate: new Date().toISOString()
        dueDate: '',
        errorMessage: [],
        reminderTriggerDays: 1,
        emailTemplateContent: 'Dear {{rcpt_fullName}},\r\n'
            + '<br />\r\n'
            + '<br />\r\n'
            + 'This is a reminder sent by CrimsonLogic Digital Signing Service for a request pending your signature by {{sigreq_arranger}} for {{document_title}}. You have until {{sigreq_expiration}} to sign. After this date, this document will be deemed declined and the signing request will be terminated automatically.\r\n'
            + '<br />\r\n'
            + '<br />\r\n'
            + 'This is an auto - generated email.Please do not send any email to this sender.\r\n'
            + '<br />',
        emailTemplateSubject: '',
        isReminderRequired: false,
        isReminderDisplayed: false
    };





    useEffect(() => { }, [dueDate]);

    useEffect(() => {
        if (showProgressModal && rasterProgress === 100) {
            scrollToTop();
            dispatch(showModal(false));
            let errorFound = false;
            for (let i = 0; i < recipients.length; i++) {
                if (recipients[i].errorMessage.length > 0) {
                    errorFound = true;
                    break;
                }
            }
            console.log('ErrDate from Rcpt:', errMessageDate)
            if ((!errorFound) && (errMessageDate === '')) {
                dispatch(incrementCurrentStep());
            }


        }
    });


    const updaterecipientstoredate = (dateValue, index) => {
        console.log('Date Value:', dateValue);
        let cDate = new Date(dateValue);
        cDate.setHours(23, 59, 0);
        let rcptDuedate = {
            index: index,
            dateValue: cDate.toISOString(),
        }
        dispatch(updaterecipientduedate(rcptDuedate));
        dispatch(updateDueDate(cDate.toISOString()));
    }

    const updaterecipientstore = (e, propertyname, index) => {
        if (propertyname === undefined) {
            let cDate = new Date(e);
            cDate.setHours(23, 59, 0);
            console.log('Sign by Date:', cDate)
            dispatch(updateDueDate(cDate.toISOString()))
            console.log('Date in Recipient List:', cDate.toISOString());
            let rcptlist = {
                index: index,
                e: cDate.toISOString(),
                //propertyname: propertyname
            }
            //dispatch(addRcptName(cDate.toISOString()), index);
            // dispatch(updaterecipientduedate(a));
        }
        else {
            let rcptlist = {
                index: index,
                name: e,
                propertyname: propertyname,
            }
            console.log('From Signing method type:', rcptlist);
            dispatch(addRecipientDetails(rcptlist));
        }
        //let rcpt = recipient;       
        //dispatch(addRcptName(e.value, propertyname));
        //dispatch(addRcptName({ ...rcpt }));
    }


    const addRecipientToStore = () => {
        console.log("[addREcipientstoStore] ");
        let rcpt = recipient;
        rcpt.tag = `Recipient ${recipients.length + 1}`;
        //rcpt.signingOrder = recipients.length + 1
        //let rcpt = { ...recipient, signingOrder: recipients.length + 1, id: guid() };
        //let rcpt = { recipient, signingOrder: recipients.length + 1, id: guid() };
        console.log("recipients length is " + recipients.length);
        dispatch(addRecipient(rcpt));
    };


    useEffect(() => { }, [dueDate]);


    const handleclick = () => {
        addRecipientToStore();
    }

    const handleRemove = (index) => {
        let _dataItems = [...recipients];
        _dataItems.splice(index, 1)
        for (let i = 0; i < _dataItems.length; i++) {
            let updated = {
                ..._dataItems[i],
                signingOrder: i + 1
            };

            _dataItems[i] = updated;

        };

        dispatch(replaceRecipient(_dataItems));
    }

    const getRecipient = () => {
        const recipient = {
            name,
            identificationNum,
            identificationType,
            emailAddress,
            mobileNum,
            dueDate,
            signingOrder
        };
        setSigningOrder(signingOrder + 1);

        return recipient;
    };

    // const setRecipient = (data) => {
    //     console.log('Name:', data.name);
    //     setName(data.name);
    //     setIdentificationNum(data.identificationNum);
    //     setIdentificationType(data.identificationType);
    //     setEmailAddress(data.emailAddress);
    //     setMobileNum(data.mobileNum);
    //     setDueDate(data.dueDate);
    // };

    const ValidateNric = (val) => {
        if (!val) return true;
        if (val.length === 4) {
            let numbers = val.substring(0, 3);
            let lastChar = val.substring(3, 4);

            if (!isNaN(numbers) && /^[a-zA-Z]+$/.test(lastChar)) return true;
            else return false;
        }
        return false;
    };

    const ValidateEmail = (val) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val) || val === '') {
            return true;
        }
        return false;
    };

    const validate = () => {
        let isNameValid = false;
        // let isDueDateValid = false;
        let isNricValid = false;
        let isValidEmail = false;
        if (name === '' || name.trim() === '') {
            setIsName(true);
        } else {
            setIsName(false);
            isNameValid = true;
        }
        // if (dueDate === '' || dueDate.trim === '') {
        //     setIsDueDate(true);
        // } else {
        //     setIsDueDate(false);
        //     isDueDateValid = true;
        // }
        if (!ValidateNric(identificationNum)) {
            setIsNric(true);
        } else {
            setIsNric(false);
            isNricValid = true;
        }
        if (!ValidateEmail(emailAddress)) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
            isValidEmail = true;
        }
        //cheat due to set state is async, can' confirm it work after called
        //if (isNricValid && isNameValid && isDueDateValid && isValidEmail) {
        if (isNricValid && isNameValid && isValidEmail) {
            //closeRef.current.click();
            return true;
        }
        return false;
    };


    const clearRecipientForm = () => {
        setIsName(false);
        setIsDueDate(false);
        // setRecipient(defaultData);
    };
    const addArranger = () => {
        let dueDate = new Date();
        dueDate.setDate(dueDate.getDate() + 3);
        dueDate.setHours(23, 59, 0);

        //dueDate = dueDate.toISOString().split('T')[0];
        // setRecipient({
        //     name: 'John Lee',
        //     identificationNum: 'T5001060G',
        //     identificationType: 'NRIC',
        //     emailAddress: 'john.lee@crimsonlogic.com',
        //     mobileNum: '987654321',
        //     dueDate: dueDate
        // });
    };



    const handleChange = () => {
        setChecked(!checked);
        setDraggable(!draggable);
        let SigOrderType;
        dispatch(storeSignatureOrderType(!draggable ? SigOrderType = 'Sequential' : 'Concurrent'));

    }


    const dragStart = (ev, index) => {
        dragItem.current = index;
        // if (draggable) {
        //     ev.preventDefault();
        // }
    }


    const onDragEnter = (e) => {
        console.log('Drag is entered:', 'Entered');
        dragOverItem.current = e;
    };


    const dragEnd = () => {
        let _dataItems = [...recipients];
        const draggedItemContent = _dataItems.splice(dragItem.current, 1)[0]
        _dataItems.splice(dragOverItem.current, 0, draggedItemContent)
        dragItem.current = null
        dragOverItem.current = null

        console.log("reordering signature");
        for (let i = 0; i < _dataItems.length; i++) {
            let updated = {
                ..._dataItems[i],
                signingOrder: i + 1
            };

            _dataItems[i] = updated;

        };

        dispatch(replaceRecipient(_dataItems));

    }

    // if (Array.isArray(recipients) && recipients.length) {
    //     itemNodes = recipients.map((rcpt, index) => <Recipient data={rcpt} key={index} />);
    // }

    if (!isDocRasterized && rasterProgress === 95) {
        dispatch(fetchDocumentPageSnapshotsAsync(
            {
                arrangementId: arrangementId,
                pageNum: 1
            }));
    }

    const showDraggable = () => {
        if (allowSignerReordering) {
            return (<div className="card-dragable">
                <i className="fal fa-grip-vertical">
                </i>
            </div>)
        }
    }




    return (
        <div className="row">
            <div className="col-xl-3 col-lg-4">
                <div className="uploaded-doc">
                    <h5><strong>Uploaded Document</strong></h5>
                    <span>
                        <strong>{fileName}</strong>

                        {/* <button type="button" className="btn icon-only" data-toggle="tooltip" data-bs-placement="top" data-original-title="View">
                            <i class="fal fa-eye"></i>
                        </button>
                        <button type="button" className="btn icon-only" data-toggle="tooltip" data-bs-placement="top" data-original-title="Replace">
                            <i class="fal fa-exchange"></i>
                        </button>
                        <button type="button" className="btn icon-only" data-toggle="Delete" data-bs-placement="top" data-original-title="Replace">
                            <i class="fal fa-trash"></i>
                        </button> */}
                    </span>
                    <h5 className="mb-0 mt-3">
                        <strong>Configurations</strong>
                    </h5>
                    <div className="custom-control  custom-control-right custom-switch">
                        <input type="checkbox"
                            className="custom-control-input"
                            id="radioSwitch1"
                            value={storeSignatureOrder}
                            checked={storeSignatureOrder === "Sequential"}
                            onChange={e => { dispatch(toggleSigOrderType()); }} />
                        <label className="custom-control-label" htmlFor="radioSwitch1">
                            Enforce signing order &nbsp;


                            <i className="fa fa-info-circle" data-tooltip-id="my-tooltip-signon" data-tooltip-content="Turning on enforcing signing order will require signers to sign in a specific order which you have defined on the right. If this is turned off, any signers can sign the document without following a specific order">
                            </i>

                            <Tooltip id="my-tooltip-signon" place="top" />

                        </label>
                    </div>

                </div>
            </div>
            <div className="col-xl-9 col-lg-8">
                <div className="recipients-card">
                    {recipients.map((item, index) => (

                        <div className="card" key={index} draggable={allowSignerReordering}
                            onDragOver={(ev) => ev.preventDefault()}
                            // onDragStart={(ev) => dragItem.current = index}
                            //onChange={(ev) => { updaterecipientstore(ev.target.value, ev.target.name, index) }}
                            onDragStart={(ev) => dragStart(ev, index)}
                            onDragEnter={(ev) => dragOverItem.current = index}
                            onDragEnd={dragEnd}>
                            {showDraggable()}



                            <div className="card-order">
                                <input className="form-control text-center" type="text" value={storeSignatureOrder === "Sequential" ? index + 1 : 1} readOnly />

                            </div>
                            <div className="card-header">
                                <h4 className="card-title">
                                    <span>
                                        <i className="fal fa-user"></i>
                                    </span>
                                    &nbsp;&nbsp;
                                    {item.tag}
                                    &nbsp;
                                    <i className="fa fa-info-circle" data-tooltip-id="my-tooltip" data-tooltip-content="The date specified here will be the last day the signer have to sign till the signing request expires">
                                    </i>

                                    <Tooltip id="my-tooltip" place="top" />

                                </h4>

                                <div className="dropdown cards-dropdown">
                                    <a href="#" className="dots dropdown-toggle" data-toggle="dropdown" aria-expanded="false">Options</a>
                                    <div className="dropdown-menu">
                                        {/* <a className="dropdown-item" onClick={handleRemove}>Delete</a> */}
                                        <button className="dropdown-item" onClick={() => handleRemove(index)}>Delete</button>
                                        <button className="dropdown-item" onClick={(e) => {
                                            updaterecipientstore(true, 'isReminderDisplayed', index)
                                        }}>Edit Reminder Notification</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-group">

                                    <input
                                        type="text"
                                        id="myInput"
                                        className="form-control"
                                        name="Recipient"
                                        aria-label=""
                                        aria-describedby=""
                                        placeholder="Name *"
                                        value={item.name}
                                        onChange={(ev) => { updaterecipientstore(ev.target.value, ev.target.name, index) }}
                                    />
                                    <div className={isName ? `message text-danger` : `message text-danger`}>{item.errorMessage.find(element => { return element.propertyname === 'name' }) !== undefined && item.errorMessage.find(element => { return element.propertyname === 'name' }).msg}</div>
                                    {/* <div className={isName ? `message text-danger` : `message text-danger`}>{item.errormessage}</div> */}
                                </div>
                                {/* <div>{errMessage}</div> */}
                                <div className="form-group required">
                                    <div className="flatpickr-wrapper">
                                        <Flatpickr
                                            className="input flatpickr-input d-block"
                                            placeholder="Sign by end of *"
                                            // options={
                                            //     minDate: dueDate === undefined ? new Date() : docDueDate,
                                            //     dateFormat: 'd-F-Y'
                                            // }}

                                            options={{
                                                minDate: new Date().toISOString(),
                                                dateFormat: 'd-F-Y'
                                            }}
                                            value={item.dueDate}
                                            ref={datePicker}
                                            name="SignDate"
                                            onChange={(date) => {
                                                updaterecipientstoredate(date, index)
                                            }} />
                                        {/* onChange={(ev) => { updaterecipientstore(ev, ev.name, index) }} /> */}
                                        <div className={isDueDate ? `message text-danger` : `message text-danger`}>{item.errorMessage.find(element => { return element.propertyname === 'dueDate' }) !== undefined && item.errorMessage.find(element => { return element.propertyname === 'dueDate' }).msg}</div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <select id="idType" className="form-select form-control" placeholder="Identity Type" name="identityType"
                                        value={item.identificationType}
                                        onChange={(e) => updaterecipientstore(e.target.value, e.target.name, index)}>
                                        {
                                            appSettings.hideEmail === "true" ?
                                                item.identificationTypeList.filter(x => x.key === 0).map(items =>
                                                    <option key={items.key} value={items.key}>{items.value}</option>
                                                ) : item.identificationTypeList.map(items =>
                                                    <option key={items.key} value={items.key}>{items.value}</option>
                                                )
                                        }
                                    </select>

                                    <div className={`message text-danger`}>{item.errorMessage.find(element => { return element.propertyname === 'identityType' }) !== undefined && item.errorMessage.find(element => { return element.propertyname === 'identityType' }).msg}</div>

                                </div>

                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={item.identificationNum}
                                        name="IdentNum"
                                        aria-label=""
                                        aria-describedby=""
                                        placeholder="Identity No"
                                        className="form-control"
                                        maxLength="4"
                                        onChange={(e) => {
                                            updaterecipientstore(e.target.value, e.target.name, index);
                                        }}
                                    />
                                    {/* <div className={isNric ? `message text-danger` : `message text-danger hide-error`}>NRIC entered is invalid.</div> */}
                                    <div className={isNric ? `message text-danger` : `message text-danger`}>{item.errorMessage.find(element => { return element.propertyname === 'identificationNum' }) !== undefined && item.errorMessage.find(element => { return element.propertyname === 'identificationNum' }).msg}</div>
                                </div>

                                <div className="form-group">

                                    <input
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        aria-describedby="email"
                                        autoComplete="email"
                                        spellCheck="false"
                                        value={item.emailAddress}
                                        onChange={(e) => {
                                            updaterecipientstore(e.target.value, e.target.name, index);
                                        }}
                                    />

                                    <div className={isValidEmail ? `message text-danger` : `message text-danger`}>{item.errorMessage.find(element => { return element.propertyname === 'emailAddress' }) !== undefined && item.errorMessage.find(element => { return element.propertyname === 'emailAddress' }).msg}</div>
                                    {/* <div className={isValidEmail ? `message text-danger` : `message text-danger hide-error`}>Email is invalid.</div> */}
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="mobilenum"
                                        value={item.mobileNum}
                                        placeholder="Mobile No"
                                        onChange={(e) => {
                                            updaterecipientstore(e.target.value, e.target.name, index);
                                        }}
                                    />
                                </div>

                                <div className={"col-xl-12 nopadding " + (!item.isReminderDisplayed ? "d-none" : "")} >
                                    <div className="col-xl-12 nopadding">
                                        <input
                                            style={{ transform: "scale(1.3)" }}
                                            type="checkbox"
                                            name="isReminderRequired"
                                            checked={item.isReminderRequired}
                                            onChange={(e) => {
                                                updaterecipientstore(e.target.checked, e.target.name, index);
                                            }}
                                        />
                                        <label class="form-check-label" for="flexCheckDefault" style={{ marginLeft: "5px" }}>
                                            Set reminder
                                        </label> &nbsp;
                                        <i class="fa fa-info-circle" data-tooltip-id="reminder-tooltip" data-tooltip-content="Instructions: Please note that enabling this option will send an automated reminder for the specified number of days leading up to the expiry date.">
                                        </i>

                                        <Tooltip id="reminder-tooltip" place="top" />

                                    </div>
                                    <div className={"col-xl-12 nopadding mt-2 " + (!item.isReminderRequired ? "d-none" : "")}>
                                        <input
                                            className="form-control"
                                            type="number"
                                            min='1'
                                            name="reminderTriggerDays"
                                            value={item.reminderTriggerDays}
                                            placeholder="Amount of days before sending reminder"
                                            onChange={(e) => {
                                                updaterecipientstore(e.target.value, e.target.name, index);
                                            }}
                                        />
                                        <div className={isValidEmail ? `message text-danger` : `message text-danger`}>{item.errorMessage.find(element => { return element.propertyname === 'reminderTriggerDays' }) !== undefined && item.errorMessage.find(element => { return element.propertyname === 'reminderTriggerDays' }).msg}</div>
                                    </div>
                                    <div className={"col-xl-12 nopadding mt-3 " + (!item.isReminderRequired ? "d-none" : "")}>
                                        <textarea
                                            rows="5"
                                            className="form-control"
                                            type="textarea"
                                            name="emailTemplateContent"
                                            value={item.emailTemplateContent}
                                            placeholder="Template"
                                            onChange={(e) => {
                                                updaterecipientstore(e.target.value, e.target.name, index);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* <br /> */}
                    <div className={isDueDate ? `message text-danger Align mb-2` : `message text-danger Align mb-2`} >{errMessageDate}</div>

                    <div className="Align mb-2">
                        <button type="button" className="btn btn-primary my-2" data-toggle="modal" data-target="#add_recipients" onClick={handleclick}><i className="fas fa-plus-circle mr-2" />Add Recipients</button>
                    </div>
                </div>
            </div >
            <Modal show={showProgressModal} className='upload-doc'>
                <Modal.Header>
                    <Modal.Title>Loading the document...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProgressBar now={rasterProgress} label={`${rasterProgress}%`} />
                </Modal.Body>

                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </div >
    );



}



