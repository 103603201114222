import React, { useState } from 'react';
import { SIGN_UI_URL } from '../../../../AppConstants';

export function CredentialDetail(props) {
    const [accessCode] = useState(props.accessCode);
    const [accessRef] = useState(props.accessRef);
    const dueDate = props.dueDate;

    const copyToClipboard = (value, tooltipId) => {
        navigator.clipboard.writeText(value);
        console.log(value);

        let tooltip = document.getElementById(tooltipId);

        if (tooltip !== null) {
            tooltip.textContent = 'Copied: ' + value;
        }
    };

    const resetToolTip = (tooltipId) => {
        let tooltip = document.getElementById(tooltipId);

        if (tooltip !== null) {
            tooltip.textContent = 'Copy to clipboard';
        }
    };

    return (
        <div className="static-table">
            <div className="table-responsive" id="dvAccessDetails">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col" style={{ borderBottomColor: "#751c24" }}>URL</th>
                            <th scope="col" style={{ borderBottomColor: "#751c24" }}>Access Code</th>
                            <th scope="col" style={{ borderBottomColor: "#751c24" }}>Access Reference</th>
                            <th scope="col" style={{ borderBottomColor: "#751c24" }}>Sign by end of</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td data-title="URL">
                                <div className="form-panel">
                                    <div className="copyclipboard">
                                        <input
                                            type="text"
                                            className="form-control border-0 form-control-lg accesstext"
                                            value={`${SIGN_UI_URL}?accessCode=${accessCode}`}
                                            id="txtAccessCode1"
                                            readOnly
                                        />
                                        <button
                                            className="btn btn-outline-primary"
                                            style={{ color: "#751c24", borderColor: "#751c24" }}
                                            type="button"
                                            onClick={(e) => {
                                                copyToClipboard(`${SIGN_UI_URL}?accessCode=${accessCode}`, 'tooltipUrl');
                                                return false;
                                            }}
                                            onMouseOut={(e) => {
                                                resetToolTip('tooltipUrl');
                                            }}
                                        >
                                            <i className="far fa-copy" />
                                            <span className="tooltiptext" id="tooltipUrl">
                                                Copy to clipboard
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </td>
                            <td data-title="Access Code">
                                <div className="form-panel">
                                    <div className="copyclipboard">
                                        <input type="text" className="form-control accesstext" value={accessCode} id="txtAccessCode2" readOnly />
                                        <button
                                            className="btn btn-outline-primary"
                                            style={{ color: "#751c24", borderColor: "#751c24" }}
                                            type="button"
                                            onClick={(e) => {
                                                copyToClipboard(accessCode, 'tooltipAccessCode');
                                                return false;
                                            }}
                                            onMouseOut={(e) => {
                                                resetToolTip('tooltipAccessCode');
                                            }}
                                        >
                                            <i className="far fa-copy" />
                                            <span className="tooltiptext" id="tooltipAccessCode">
                                                Copy to clipboard
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </td>
                            <td data-title="Access Reference">
                                <div className="form-panel">
                                    <div className="copyclipboard">
                                        <input type="text" className="form-control accesstext" value={accessRef} id="txtAccessRef1" readOnly />
                                        <button
                                            className="btn btn-outline-primary"
                                            style={{ color: "#751c24", borderColor: "#751c24" }}
                                            type="button"
                                            onClick={(e) => {
                                                copyToClipboard(accessRef, 'copyTTRef1');
                                                return false;
                                            }}
                                            onMouseOut={(e) => {
                                                resetToolTip('copyTTRef1');
                                            }}
                                        >
                                            <i className="far fa-copy" />
                                            <span className="tooltiptext" id="copyTTRef1">
                                                Copy to clipboard
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </td>
                            <td data-title="Sign by end of">{dueDate}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
