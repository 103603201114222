import axios from 'axios';

const cache = {};
export const loadAppSettings = async () => {
    console.log("loading appsettings");
    let appSettings = cache["AppSettings"];

    if (!appSettings) {
        console.log("appsettings not found in cache.  Making axios call.");
        const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/Ui/AppSettings`);
        console.log(`appsettings result: `, res);
        appSettings = cache["AppSettings"] = res.data;

    }
    console.log(`returning appsettings: `, appSettings);
    return appSettings;
}