import React, { useEffect } from "react";
import { DocPreviewKonvaCanvas } from "./docPreviewKonvaCanvas";
import { useSelector, useDispatch } from "react-redux";
import {
    decrementCanvasPage,
    incrementCanvasPage,
    selectCurrentCanvasPage,
    selectUploadedDocSnapshot,
    selectCurrentPageSnapshot,
    selectTotalPageCount,
    fetchDocumentPageSnapshotsAsync,
    selectArrangementId,
    selectStatus
} from "../signingRequestWizardSlice";



export function DocPreviewContainer(props) {

    const docSnapshot = useSelector(selectUploadedDocSnapshot);
    const totalPageCount = useSelector(selectTotalPageCount);
    const pageSnapshot = useSelector(selectCurrentPageSnapshot);
    const dispatch = useDispatch();
    const currentPage = useSelector(selectCurrentCanvasPage);
    const arrangementId = useSelector(selectArrangementId);
    const status = useSelector(selectStatus);
    const incrementPage = () => {

        // var nextPage = docSnapshot.find((t) => t.page === currentPage + 1);
        // if (nextPage === undefined) {
        //     dispatch(fetchDocumentPageSnapshotsAsync({
        //         arrangementId: arrangementId,
        //         pageNum: currentPage + 1
        //     }));
        // }

        dispatch(incrementCanvasPage())


    }
    const decrementPage = () => { dispatch(decrementCanvasPage()) }

    useEffect(() => { }, [currentPage, pageSnapshot])

    let pButtonClass = "btn-sm btn-link";
    let nButtonClass = "btn-sm btn-link";
    if (currentPage === 1) {
        pButtonClass = pButtonClass.concat(" d-none");
    }
    if (currentPage === totalPageCount) {
        nButtonClass = nButtonClass.concat(" d-none");
    }

    const renderCanvas = () => {

        if (pageSnapshot !== undefined) {
            return (<DocPreviewKonvaCanvas
                appSettings={props.appSettings}
                pageSnapshot={pageSnapshot.snapshot}
                pageHeight={pageSnapshot.height}
                pageWidth={pageSnapshot.width}
                key={pageSnapshot.page}
                draggable={props.draggable}
                pageNum={pageSnapshot.page}
                readOnly={props.readOnly}
            />)
        } else {
            return (
                <div className="container">
                    <div className="card col-12" style={{
                        minHeight: "400px"
                    }}>
                        <div className="card-body">
                            Please wait while we load the page....
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="doc-preview-container" style={{ backgroundColor: "#E7E9EB" }}>

            <div className="row" style={{ "width": "100%" }}>
                <div className="col-12">
                    <button className={pButtonClass} onClick={e => {
                        if (currentPage > 1) {
                            decrementPage();
                        }

                    }} > <span><i className="fal fa-angle-left"></i></span> </button> &nbsp;
                    <span> {currentPage} of {totalPageCount}</span> &nbsp;
                    <button className={nButtonClass} onClick={e => {
                        if (currentPage < totalPageCount) {
                            incrementPage();
                        }
                    }}><span><i className="fal fa-angle-right"></i></span></button>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {renderCanvas()}
                </div>
            </div>
        </div>
    );

}

