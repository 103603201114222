export const DueDateExtensionSubmitButton = (props) => {
    const isDisabled = props.disabled;
    const isHidden = props.hidden;
    const submit = props.handleSubmit;

    return (
        !isHidden &&
        <button
            className="btn btn-primary btn-action w-100"
            onClick={submit}
            disabled={isDisabled}
        >
            Submit
        </button>
    )
}