import React, { useState } from 'react';
import { AddSignaturePlaceholderCard } from './addSignaturePlaceholderCard.jsx';

export function AddSignaturePlaceholderCardList(props) {
    //const [data, setDatta] = useState(props.data);
    const [data] = useState(props.data);
    const dataNode = data.map((signer, index) => (
        <AddSignaturePlaceholderCard
            signerTag={signer.tag}
            signerName={signer.name}
            signerId={signer.id}
            signingmethod={signer.signingmethod}
            key={index}
            stampPlaceholderHandler={props.stampPlaceholderHandler}
            updateStampedSnapshotList={props.updateStampedSnapshotList}

        />




    ));

    return (
        <div className="col-lg-3 col-md-4">
            <div className="card has-list has-options text-left h-auto mb-2">
                <div className="card-body">{dataNode}</div>
            </div>
        </div>
    );
}
