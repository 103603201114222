import React, { useState, useEffect } from 'react';

import { DocPreviewContainer } from '../shared/docPreviewContainer';
import { AddSignaturePlaceholderCardList } from './addSignaturePlaceholderCardList';
import { useDispatch, useSelector } from 'react-redux';
import { selectRecipients, selectCurrentStep, selectError, selectIsDocumentRasterized, fetchDocumentSnapshotsAsync, selectArrangementId, selectStatus, fetchDocumentPageSnapshotsAsync, selectTotalPageCount, selectUploadedDocSnapshot } from '../signingRequestWizardSlice';

export function AddPlaceHolderForm(props) {
    const dispatch = useDispatch();
    const recipients = useSelector(selectRecipients);
    const currentStep = useSelector(selectCurrentStep);
    const placeholderError = useSelector(selectError);
    const isDocRasterized = useSelector(selectIsDocumentRasterized);
    const arrangementId = useSelector(selectArrangementId);
    const formStatus = useSelector(selectStatus);
    const totalPageCount = useSelector(selectTotalPageCount);
    const snapshotsRasterized = useSelector(selectUploadedDocSnapshot);

    if (isDocRasterized) {
        //Fetch the rest pages in the background until all the pages are fectched.
        if (formStatus !== "loading-silent" && snapshotsRasterized.length < totalPageCount) {
            dispatch(fetchDocumentPageSnapshotsAsync(
                {
                    arrangementId: arrangementId,
                    pageNum: snapshotsRasterized.length + 1
                }));
        }

        return (
            <div>
                <h2 className="text-left my-3">
                    Add Placeholder
                    <span className="has-popover">
                        <span className="pop-overlay" />
                        <span className="popover-content align-center">
                            <span className="popover-close">
                                <i className="fal fa-times" />
                            </span>
                            <span className="pop-title">Title</span> Lorem Ipsum is simply dummy text of the printing an d typesetting industry. Lorem Ipsum has been the industry's standard dummy
                        </span>
                    </span>
                </h2>

                <div className="row">
                    <AddSignaturePlaceholderCardList data={recipients} />

                    <div className="col-lg-9 col-md-8">
                        {currentStep === 3 && placeholderError && (
                            <>
                                <div className="alert alert-danger" role="alert">
                                    {placeholderError}
                                </div>
                            </>
                        )}
                        <DocPreviewContainer draggable={true} readOnly={false} appSettings={props.appSettings} />
                    </div>
                </div>
            </div>
        );
    } else {
        //Fetch the first batch of the pages.
        // if (formStatus !== "loading-silent") {
        //     setTimeout(() => {
        //         dispatch(fetchDocumentPageSnapshotsAsync(
        //             {
        //                 arrangementId: arrangementId,
        //                 pageNum: 1
        //             }));
        //     }, 3000);
        // }

        return (<div style={{ marginBottom: "20%" }} >
            <div className="loader"></div>
        </div>
        )

    }
}
