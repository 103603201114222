import React, { useState } from 'react';
import KeycloakService from '../keycloakService';
import { Tooltip } from 'react-tooltip';
import { HOME_URL, DSS_LOGO_URL, CLDIGITAL_USER_MANUAL_URL } from '../../AppConstants';

const Header = () => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(false);
    const menuMove = () => {
        setIsNavCollapsed(!isNavCollapsed);
    };

    const logOut = () => {
        KeycloakService.CallLogout();
    }

    return (
        <>
            <header className={`${isNavCollapsed ? 'nav-open' : ''} navbar-container`}>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <a className="navbar-brand" href={HOME_URL} target='_blank'>
                            <img src={DSS_LOGO_URL} alt="Logo" />
                        </a>
                        <div className={`${isNavCollapsed ? 'change active' : ''} navbar-toggler`} onClick={() => menuMove()}>
                            <div className="menu-icon">
                                <div className="menu-bar1"></div>
                                <div className="menu-bar2"></div>
                                <div className="menu-bar3"></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <i className="fa fa-info-circle" data-tooltip-id="my-tooltip-signon" data-tooltip-content="How do I sign with CrimsonLogic Digital?"></i>
                        <Tooltip id="my-tooltip-signon" place="top" />
                        <a href={CLDIGITAL_USER_MANUAL_URL} target="_blank">&nbsp;&nbsp;&nbsp;How do I sign with CrimsonLogic Digital?</a>

                    </div>
                    <div className={`${isNavCollapsed ? 'nav-open' : ''} navbar-collapse`}>
                        <ul className="navbar-nav ml-md-auto">
                            <li className="nav-item ml-2 ml-md-0 my-2 my-md-0">
                                <div className="dropdown user-dropdown d-inline-block">
                                    <a href="#" className="user-status user-default dropdown-toggle" title="User" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-user"></i>
                                    </a>
                                    <div className="dropdown-menu">
                                        <div className="username">
                                            Welcome <strong>{KeycloakService.GetUserName()}</strong>
                                        </div>
                                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => logOut()}>
                                            Logout
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>

        </>
    );
};

export default Header;
