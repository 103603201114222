import React, { useRef, useState, useEffect } from 'react';
import { Stage, Layer, Image } from 'react-konva';
import { SigPlaceholderImage } from '../shared/SigPlaceholderImage';
import useImage from 'use-image';
import { useSelector, useDispatch } from 'react-redux';
import { deleteStamp, selectCurrentCanvasPage, selectStampsAsFlatArray, updateStampCoordinateAction } from '../signingRequestWizardSlice';
import { PDFViewerWidth } from './common';
import { PLACEHOLDER_IMAGE_PATH } from '../../../../AppConstants';


const MAX_PAGE_WIDTH = 890;
const MAX_PAGE_HEIGHT = 1259;



export function DocPreviewKonvaCanvas(props) {
    const curPage = useSelector(selectCurrentCanvasPage);
    const stamps = useSelector(selectStampsAsFlatArray(curPage));
    const dispatch = useDispatch();
    const [pageSnapshot] = useState(props.pageSnapshot);
    const [size, setSize] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
        zoom: Math.round(window.devicePixelRatio * 100) >= 100 ? 100 : Math.round(window.devicePixelRatio * 100)
    });


    useEffect(() => {
        const checkSize = () => {
            console.log("check size is called")
            const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
            console.log(`Device Zoom Level: ${browserZoomLevel}`)

            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
                zoom: browserZoomLevel >= 100 ? 100 : browserZoomLevel
            });
        };

        window.addEventListener("resize", checkSize);
        checkSize();
        return () => window.removeEventListener("resize", checkSize);
    }, []);

    const stageNode = useRef();

    // const [pageHeight, setPageHeight] = useState(props.pageHeight);
    // const [pageWidth, setPageWidth] = useState(props.pageWidth);
    let pageHeight = props.pageHeight;
    let pageWidth = props.pageWidth;
    let scaleRatio = 1;
    const portraitScaleRatio = pageHeight / MAX_PAGE_HEIGHT;
    const landscapeScaleRatio = pageWidth / MAX_PAGE_WIDTH;

    const isPortrait = props.pageHeight > props.pageWidth;

    const pdfViewerWidth = PDFViewerWidth(size.width, size.zoom);
    console.log(`PDF Viewer Width: ${pdfViewerWidth}`);
    if (isPortrait) {
        let newPdfViewerWidth = pdfViewerWidth;
        if (pdfViewerWidth > MAX_PAGE_WIDTH) {
            newPdfViewerWidth = MAX_PAGE_WIDTH * portraitScaleRatio;
        }
        if (pageHeight > MAX_PAGE_HEIGHT) {
            console.log('isPortrait');
            pageWidth = newPdfViewerWidth / portraitScaleRatio;
            pageHeight = MAX_PAGE_HEIGHT;
            console.log('new dim:');
            console.log('(' + pageHeight + ',' + pageWidth + ')');
            scaleRatio = portraitScaleRatio;
        }
    } else {
        if (pageWidth > MAX_PAGE_WIDTH) {
            console.log('isLandscape');
            pageWidth = MAX_PAGE_WIDTH;
            pageHeight = pageHeight / landscapeScaleRatio;
            scaleRatio = landscapeScaleRatio;
        }
    }

    //********* ORIGINAL LOGIC *********/
    //     console.log("Set to normal size")
    //     //set to normal size
    //     pageWidth = props.pageWidth;
    //     pageHeight = props.pageHeight;
    //     console.log("Original width: " + props.pageWidth)
    //     console.log("Original height: " + props.pageHeight)

    //     if (isPortrait) {
    //         if (pageHeight > MAX_PAGE_HEIGHT) {
    //             console.log('isPortrait');
    //             scaleRatio = pageHeight / MAX_PAGE_HEIGHT;
    //             pageHeight = MAX_PAGE_HEIGHT;
    //             pageWidth = pageWidth / scaleRatio;

    //             // console.log('new dim:');
    //             console.log('(' + pageHeight + ',' + pageWidth + ')');
    //         }
    //     } else {
    //         if (pageWidth > MAX_PAGE_WIDTH) {
    //             scaleRatio = pageWidth / MAX_PAGE_WIDTH;
    //             pageWidth = MAX_PAGE_WIDTH;
    //             pageHeight = pageHeight / scaleRatio;
    //         }
    //     }

    console.log('scale ratio is: ');
    console.log(scaleRatio);

    // useEffect(() => {
    // },[curPage])


    const [bgImage] = useImage(pageSnapshot);

    const updateCoordinate = (id, signerId, page, x, y) => {
        const payload = {
            id,
            signerId,
            coordinate: { page, x, y, scaleRatio }
        };
        console.log('about to dispatch update coordinate with payload: ' + JSON.stringify(payload));
        dispatch(updateStampCoordinateAction(payload));
    };

    const removeStamp = (id, signerId) => {
        console.log('removing placeholder with id : ' + id);
        dispatch(deleteStamp({ id, signerId }));
    };

    return (
        <>
            <Stage width={pageWidth} height={pageHeight} ref={stageNode}>
                <Layer>
                    <Image image={bgImage} width={pageWidth} height={pageHeight} />
                </Layer>
                <Layer>
                    {stamps.map((stamp) => (
                        <SigPlaceholderImage
                            //src={placeholderImagePath1}
                            src={PLACEHOLDER_IMAGE_PATH[stamp.signingmethod]}
                            //src={stamp.signingmethod === "1" ? placeholderImagePath : ''}
                            appSettings={props.appSettings}
                            scaleRatio={scaleRatio}
                            draggable={props.draggable}
                            page={stamp.coordinate.page}
                            key={stamp.id}
                            id={stamp.id}
                            x={stamp.coordinate.x}
                            y={stamp.coordinate.y}
                            signerId={stamp.signerId}
                            readOnly={!props.draggable}
                            updateCoordinate={(x, y) => {
                                updateCoordinate(stamp.id, stamp.signerId, stamp.coordinate.page, x, y);
                            }}
                            removeStamp={(e) => {
                                removeStamp(stamp.id, stamp.signerId);
                            }}
                            hasSecureCode={stamp.hasSecureCode}
                            secureCode={stamp.secureCode}
                            label={`${stamp.signerName} (${stamp.counter})`}
                            signerTag={stamp.signerTag}
                            currentBrowserSize={size}
                        />

                    ))}



                </Layer>
            </Stage>
        </>

    );
}