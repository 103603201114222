import React from 'react';

export function DocumentInfo(props) {
    return (
        <div>
            <div className="card has-options text-left h-auto mb-2">
                <div className="card-header">
                    <h5 className="card-title">Document Title</h5>
                </div>
                <div className="card-body">
                    <div className="card-item d-flex">
                        <span className="d-inline-block mr-auto">{props.docTitle}</span>
                    </div>
                </div>
            </div>

            <div className="card has-options text-left h-auto mb-2 ">
                <div className="card-header">
                    <h5 className="card-title">Uploaded Document</h5>
                </div>
                <div className="card-body">
                    <div className="card-item d-flex">
                        <span className="d-inline-block mr-auto">{props.uploadedDocument}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
