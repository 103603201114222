import { MY_DASHBOARD_URLS } from '../../../AppConstants';
import KeycloakService from '../../keycloakService';

const APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function onFetchSignaturesFailed(response, status, queryParams, retry) {
    console.log(`2. [onFetchSignaturesFailed] retry: ${retry}`);
    if (retry > 0) {

        let pSig = null;
        await new Promise((res, rej) => {
            setTimeout(async () => {
                console.log(`3. calling fetchSignature after response 403. status: ${status}, queryParams: ${queryParams}, retryLeft; ${retry}`);
                res(fetchSignatures(status, queryParams, retry));
                //await new Promise((resolve, reject) => {}

            });
        }, 1000);



    } else {
        console.log("No more retry left.  Rejecting");

        await new Promise((resolve, reject) => {
            reject(response);
        })



    }

}

export async function fetchSignatures(status, queryParams, retry = 15) {
    console.log(`[fetchSignatures] entered.  retry: ${retry} `);

    let token = await KeycloakService.GetToken();
    const headers = {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS'
    };

    const params = new URLSearchParams();

    if (queryParams.searchKeyword !== '') params.append('searchKeyword', queryParams.searchKeyword);
    if (queryParams.sortBy !== '') params.append('sortBy', queryParams.sortBy);

    params.append('pageNumber', queryParams.pageNumber);
    params.append('pageSize', queryParams.pageSize);
    status.forEach((d, i) => {
        params.append(`Status[${i}]`, `${d}`);
    });

    return new Promise(async (resolve, reject) => {
        fetch(`${APP_SERVER_URL}${MY_DASHBOARD_URLS.GET_SIGNATURE_URL}?${params.toString()}`, {
            method: 'GET',
            headers: headers
        })
            .then(async response => {
                console.log(response);
                if (response.ok) {
                    console.log("response received ok");
                    resolve(response.json());
                }
                else {
                    console.log("response received but not ok");
                    if (retry <= 0 || response.status !== 403) {
                        reject(response);
                    }
                    else {

                        await sleep(500);
                        let p = await fetchSignatures(status, queryParams, retry - 1);
                        resolve(p);
                    }
                }

            })
            .catch((error) => {
                console.log("error occured while fetching signatures");
                reject(Error(error));
                //reject(Error(error));
                //onFetchSignaturesFailed(error, status, queryParams, retry-1);
            });
    })


}

export async function deleteArrangement(id) {

    const headers = {
        Authorization: 'Bearer ' + (await KeycloakService.GetToken()),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS'
    };

    return new Promise((resolve, reject) => {
        fetch(`${APP_SERVER_URL}${MY_DASHBOARD_URLS.DELETE_ARRANGEMENT_URL}/${id}/delete`, {
            method: 'POST',
            headers: headers
        })
            .then((response) => {
                if (!response.ok) {
                    reject(response);
                }
                resolve();
            })
            .catch((error) => {
                reject(Error(error.message));
            });
    });
}

export async function fetchArrangementDetail(id) {

    const headers = {
        Authorization: 'Bearer ' + (await KeycloakService.GetToken()),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET'
    };
    return new Promise((resolve, reject) => {
        fetch(`${APP_SERVER_URL}${MY_DASHBOARD_URLS.GET_ARRANGEMENT_DETAIL_URL}/${id}/Recipients`, {
            method: 'GET',
            headers: headers
        })
            .then((response) => {
                if (response.ok) {
                    resolve(response.json());
                }
                reject(response);
            })
            .catch((error) => {
                reject(Error(error.message));
            });
    });
}

export async function fetchDocumentAsync(id, title) {

    const headers = {
        Authorization: 'Bearer ' + (await KeycloakService.GetToken()),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS'
    };
    return new Promise((resolve, reject) => {
        fetch(`${APP_SERVER_URL}/MyDashboard/${id}/directSignedDocument`, {
            method: 'GET',
            headers: headers
        })
            .then(response => response.blob())
            .then(blob => {

                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = `${title}.pdf`;
                link.click();

                resolve({ Status: "OK" });

            }).catch((error) => {
                reject(Error(error.message))
            });

    })
}

export async function patchDueDateAsync(id, payload) {

    const headers = {
        Authorization: 'Bearer ' + (await KeycloakService.GetToken()),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS'
    };
    return new Promise((resolve, reject) => {
        fetch(`${APP_SERVER_URL}${MY_DASHBOARD_URLS.EXTEND_DUE_DATE_URL}/${id}/DueDate`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (!response.ok)
                    reject(Error('The server encountered an error. Please try again later.'))

                resolve({ status: "OK", message: "Due dates extended successfully" });
            })
            .catch((error) => {
                reject(Error('The server encountered an error. Please try again later.'))
            });


    });
}