import React, { useState } from 'react';

export function ArrangementListSearch(props) {
    const [keyword, setKeyword] = useState('');

    const handleChange = (event) => {
        setKeyword(event.target.value);
    };

    const handleClick = (evt) => {
        evt.preventDefault();
        props.onSearchClicked(keyword);
    };
    return (
        <div className="form-group">
            <div className="input-group input-group-with-icon">
                <input type="text" className="form-control form-control-md" placeholder="Search via Document Title" aria-label="" value={keyword} onChange={handleChange} aria-describedby="" />
                <div className="input-group-append">
                    <button className="btn" type="button" onClick={handleClick}>
                        <i className="fal fa-search"></i>
                    </button>
                </div>
            </div>
        </div>
    );
}
