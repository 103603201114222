import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentStep, selectIsStep1Complete, selectIsStep2Complete, selectIsStep3Complete } from '../signingRequestWizardSlice';

export function WizardProgressBar() {
    const curStep = useSelector(selectCurrentStep);
    const s1Complete = useSelector(selectIsStep1Complete);
    const s2Complete = useSelector(selectIsStep2Complete);
    const s3Complete = useSelector(selectIsStep3Complete);

    useEffect(() => {}, [curStep]);

    const decideProgressBarNodeClassName = (stepNum) => {
        if (curStep === stepNum) {
            return 'active';
        }
        if (s1Complete && stepNum === 1) {
            return 'completed';
        }
        if (s2Complete && stepNum === 2) {
            return 'completed';
        }
        if (s3Complete && stepNum === 3) {
            return 'completed';
        }

        return '';
    };

    if (curStep >= 1 && curStep <= 4) {
        return (
            <div className="wizard-progress-bar my-4">
                <ul>
                    <li className={decideProgressBarNodeClassName(1)}>
                        <span>Upload Document</span>
                    </li>
                    <li className={decideProgressBarNodeClassName(2)}>
                        <span>Add Recipients</span>
                    </li>
                    <li className={decideProgressBarNodeClassName(3)}>
                        <span>Add Placeholders</span>
                    </li>
                    <li className={decideProgressBarNodeClassName(4)}>
                        <span>Preview and Submit</span>
                    </li>
                </ul>
            </div>
        );
    }
}
