
export function DueDateExtensionModalHeader(props) {
    const documentName = props.documentName;
    const reset = props.reset;

    return (
        <div className="modal-header">
            <h3 className="modal-title" id="jdsModalLabel5">
                <small>Extend Your Signers' Due Dates</small>
                <br />
                {documentName}
            </h3>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={reset}>
                <i aria-hidden="true" className="fal fa-times"></i>
            </button>
        </div>
    )
}

