import React from 'react';

export function ArrangementListSorter(props) {
    let sortOption;
    if (props.data !== undefined) {
        sortOption = props.data.map((sortOpt) => (
            <option value={sortOpt} key={sortOpt}>
                {sortOpt}
            </option>
        ));
    }

    const handleChange = (e) => {
        props.onSortBySelected(e.target.value);
    };

    return (
        <div className="form-group">
            <select className="form-control form-control-sm" onChange={(e) => handleChange(e)}>
                <option value="">Filter to Folder</option>
                {sortOption}
            </select>
        </div>
    );
}
