export const PDFViewerWidth = (size, zoom) => {
    if (size < 768) {
        return parseInt(size * (zoom / 100));
    }
    else if (size >= 768 && size < 992) {
        return parseInt(((size * 62.66) / 100) * (zoom / 100));
    }
    else {
        return parseInt(((size * 70) / 100) * (zoom / 100));
    }
}

export const SignaturePadSize = (Width, Height, appSettings) => {
    const SIGNATURE_DIMENSION = appSettings.SignatureDimension;
    const SMALL_SIZE_WINDOW_ADJUSTMENT = 120;
    const WINDOW_CHANGED_DETECTION_SIZE = 1300;
    //console.log('Width: ' + Width)
    //console.log('Height: ' + Height)
    if (Width < 768) {
        //console.log("A")
        const t = {
            Height: SIGNATURE_DIMENSION.Height - 100,
            Width: parseInt((SIGNATURE_DIMENSION.Width / WINDOW_CHANGED_DETECTION_SIZE) * Width) - SMALL_SIZE_WINDOW_ADJUSTMENT,
            WidthWithSecureCode: parseInt((SIGNATURE_DIMENSION.WidthWithSecureCode / WINDOW_CHANGED_DETECTION_SIZE) * Width) - SMALL_SIZE_WINDOW_ADJUSTMENT
        };
        //console.log(t)
        return t;
    }
    else if (Width >= 768 && Width < 992) {
        //console.log("B")
        const t = {
            Height: SIGNATURE_DIMENSION.Height * (2 / 3) - 50,
            Width: parseInt((SIGNATURE_DIMENSION.Width / WINDOW_CHANGED_DETECTION_SIZE) * Width) * (2 / 3) - 100,
            WidthWithSecureCode: parseInt((SIGNATURE_DIMENSION.WidthWithSecureCode / WINDOW_CHANGED_DETECTION_SIZE) * Width) * (2 / 3) - 100
        };
        //console.log(t);
        return t;
    }
    else {
        //console.log("C")
        const t = {
            Height: (SIGNATURE_DIMENSION.Height) * (3 / 4) - 30,
            Width: parseInt((SIGNATURE_DIMENSION.Width / 1300) * Width) * (3 / 4) - 100,
            WidthWithSecureCode: parseInt((SIGNATURE_DIMENSION.WidthWithSecureCode / 1300) * Width) * (2 / 3) - 100
        };
        //console.log(t);
        return t;
    }
}