import React from 'react';

export function Spinner(props) {
    return(
        <div >
           <div className="alert alert-primary" role="alert">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Just a moment.</span>&nbsp;
                            </div>{' '}
                            Please wait...{' '}
                        </div>
            </div>
    )
}