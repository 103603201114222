import Keycloak from 'keycloak-js';
import { loadAppSettings } from '../AppSettings';

const keycloakInstance = new Keycloak(`${process.env.REACT_APP_SERVER_URL}/Ui/KeycloakSettings`);

keycloakInstance.onAuthSuccess = () => {
    keycloakInstance.authCallback();
}

const OnAuthCallback = (callback) => {
    keycloakInstance.authCallback = callback;
}

const Login = () => {
    keycloakInstance.login().catch((e) => {
        console.dir(e);
        console.log(`keycloak login exception: ${e}`);
    });;
}

const Init = () => {
    loadAppSettings().then(d => {
        keycloakInstance
            .init({
                onLoad: 'login-required',
                checkLoginIframe: d.CheckLoginIFrame
            })
            .then(function (authenticated) {
                if (authenticated) {
                    console.log("user is authenticated");
                    keycloakInstance.authCallback();
                } else {
                    console.warn('not authenticated!');
                    console.log("about to login");
                    //keycloakInstance.login({ redirectUri: "http://localhost:3000"});
                    //keycloakInstance.login("http://localhost:3000");
                }

            })
            .catch((e) => {
                console.dir(e);
                console.log(e);
                console.dir(`keycloak init exception: ${e}`);
            });
    })

};


const InitAsync = (onAuthenticatedCallback) => {

    keycloakInstance.authCallback = onAuthenticatedCallback;

    keycloakInstance.init({
        onLoad: 'login-required'

    })
        .then(function (authenticated) {
            if (authenticated) {
                console.log("user is authenticated");
            } else {
                console.warn('not authenticated!');
                console.log("about to login");
                //keycloakInstance.login({ redirectUri: "https://localhost:7152/postLogin/index"});
                //keycloakInstance.login("http://localhost:3000");
                keycloakInstance.login();
            }
        })
        .catch((e) => {
            console.dir(e);
            console.log(e);
            console.dir(`keycloak init exception: ${e}`);
        });
}


const UserName = () => keycloakInstance.tokenParsed?.preferred_username;

const Email = () => keycloakInstance.tokenParsed?.email;
// const UserRoles = async () => {
//     if (keycloakInstance.resourceAccess === undefined) return [];
//     else if (keycloakInstance.resourceAccess[KEYCLOAK_CLIENT_ID] === undefined) return [];
//     else return keycloakInstance.resourceAccess[KEYCLOAK_CLIENT_ID].roles;
// };

const UserRoles = (keycloakClientId) => {
    if (keycloakInstance.resourceAccess === undefined) return [];
    else if (keycloakInstance.resourceAccess[keycloakClientId] === undefined) return [];
    else return keycloakInstance.resourceAccess[keycloakClientId].roles;
}

const Logout = async () => {
    const appSettings = await loadAppSettings();
    LogOutofApi(appSettings.AppServerUrl, appSettings.LogOutUrl, keycloakInstance.token);
    keycloakInstance.logout();
}


const LogOutofApi = (baseUrl, logOutUrl, token) => {
    const headers = {
        Authorization: 'Bearer ' + (token),
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    return new Promise((resolve, reject) => {
        const url = `${baseUrl}${logOutUrl}`;
        fetch(url, {
            method: 'post',
            headers: headers,
        })
            .then((response) => {
            });
    });

}

const IsLoggedIn = () => !!keycloakInstance.token;

const IsTokenExpired = () => {
    let isExpired = false;
    if (keycloakInstance.tokenParsed.exp < new Date().getTime()) isExpired = true;

    return isExpired;
};
const GetToken = () => {
    return new Promise((resolve, reject) => {

        keycloakInstance
            .updateToken(36000)
            .then((refreshed) => {
                if (!refreshed) {
                    console.log("Failed to refresh token");
                }
                resolve(keycloakInstance.token);

            })
            .catch(() => {
                reject(DoLogin);
            });
    });
};
const DoLogin = keycloakInstance.login;

const UpdateToken = (successCallback) => keycloakInstance.updateToken(5).then(successCallback).catch(DoLogin);

const Authenticated = () => keycloakInstance.authenticated;

const KeycloakService = {
    Init: Init,
    InitAsync: InitAsync,
    CallLogin: Login,
    CallLogout: Logout,
    GetUserName: UserName,
    GetEmail: Email,
    GetUserRoles: UserRoles,
    IsLoggedIn: IsLoggedIn,
    IsTokenExpired: IsTokenExpired,
    GetToken: GetToken,
    UpdateToken: UpdateToken,
    Authenticated: Authenticated,
    OnAuthCallback: OnAuthCallback,
    Instance: keycloakInstance
};

export default KeycloakService;
