import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { decrementCurrentStep, incrementCurrentStep, selectCurrentStep, selectIsStep1Complete, selectIsStep2Complete, selectIsStep3Complete, selectIsLoading, validateRecipientStore } from '../signingRequestWizardSlice';
import { error } from 'jquery';



export function WizardNavButtons(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const curStep = useSelector(selectCurrentStep);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
         in place of 'smooth' */
        });
    };


    const nextStep = (e) => {

        if (curStep === 2) {
            console.log('curStep 2 is called to show progressmodal');
            dispatch(validateRecipientStore());
            props.onShowProgressModal();


        }
        else {
            scrollToTop();
            dispatch(incrementCurrentStep());
        }
    };
    const prevStep = (e) => {
        scrollToTop();
        dispatch(decrementCurrentStep());
    };

    const cancelRequest = (e) => {
        navigate('/', true);
    };

    const isLoading = useSelector(selectIsLoading);

    const isStep1Complete = useSelector(selectIsStep1Complete);
    const isStep2Complete = useSelector(selectIsStep2Complete);
    const isStep3Complete = useSelector(selectIsStep3Complete);





    useEffect(() => { }, [curStep]);

    //useEffect(()=>{ setIsLoading(props.isLoading)},[props.isLoading])

    useEffect(() => {
        console.log('Update detected in isStep1Complete');
        console.log(isStep1Complete);
    }, [isStep1Complete]);

    useEffect(() => { }, [isLoading]);

    if (curStep === 5) return <div></div>;

    if (curStep === 4) {
        return (
            <div className="btn-center py-4">
                <div className="container-fluid">
                    <button type="button" className="btn btn-large btn-primary btn-action" onClick={cancelRequest}>
                        Cancel
                    </button>
                    <button disabled={isLoading} type="button" className="btn btn-large btn-primary btn-action" onClick={prevStep}>
                        Previous
                    </button>
                    <button disabled={isLoading} type="button" className="btn btn-large btn-primary btn-action" onClick={props.onSubmitArrangement}>
                        Submit
                    </button>
                </div>
            </div>
        );
    } else if (curStep === 1) {
        return (
            <div className="btn-center py-4">
                <div className="container-fluid">
                    <button type="button" className="btn btn-large btn-primary btn-action" onClick={cancelRequest}>
                        Cancel
                    </button>
                    <button type="button" disabled={!isStep1Complete} className="btn btn-large btn-primary btn-action " onClick={nextStep}>
                        Next
                    </button>
                </div>
            </div>
        );
    } else {
        let isCurrentStepCompleted = (curStep === 2 && isStep2Complete) || (curStep === 3 && isStep3Complete);

        return (
            <div className="btn-center py-4">
                <div className="container-fluid">
                    <button type="button" className="btn btn-large btn-primary btn-action" onClick={cancelRequest}>
                        Cancel
                    </button>
                    <button type="button" disabled={isLoading} className="btn btn-large btn-primary btn-action" onClick={prevStep}>
                        Previous
                    </button>
                    <button type="button" disabled={!isCurrentStepCompleted || isLoading} className="btn btn-large btn-primary btn-action" onClick={nextStep}>
                        Next
                    </button>
                </div>
            </div>
        );
    }
}
