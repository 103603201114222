import React from 'react';

export function MaxItemLimiter(props) {
    let options;

    if (props.data !== undefined) {
        options = props.data.map((opt) => (
            <option value={opt} key={opt}>
                {opt}
            </option>
        ));
    }
    const handleChange = (e) => {
        props.onPageSizeChanged(e.target.value);
    };
    return (
        <div className="item_per_page">
            <span>
                Show&nbsp;&nbsp;
                <select className="custom-select" onChange={(e) => handleChange(e)}>
                    {options}
                </select>
                &nbsp;&nbsp;entries
            </span>
        </div>
    );
}
