import React, { useState, useEffect, useCallback } from 'react';

import { SigningRequestArrangement } from './signingRequestArrangement';
import { MaxItemLimiter } from './maxItemLimiter';
import Loader from '../../components/Loader';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';
const PAGE_NEIGHBOR = 1;

const range = (from, to, step = 1) => {
    let i = from;
    const items = [];

    while (i <= to) {
        items.push(i);
        i += step;
    }

    return items;
};

export function ArrangementList(props) {
    const [arrangementList, setArrangementList] = useState(props.data);
    const [pages, setPages] = useState([]);
    const tab = props.tab;

    const fetchPageNumbers = useCallback(() => {
        const totalPages = props.totalPages;
        const currentPage = props.currentPage;

        const totalNumbers = PAGE_NEIGHBOR * 2 + 3;
        const totalBlocks = totalNumbers + 2;
        if (totalPages > totalBlocks) {
            let pageDetails = [];

            const leftBound = currentPage - PAGE_NEIGHBOR;
            const rightBound = currentPage + PAGE_NEIGHBOR;
            const beforeLastPage = totalPages - 1;

            const startPage = leftBound > 2 ? leftBound : 2;
            const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

            pageDetails = range(startPage, endPage);
            const pagesCount = pageDetails.length;
            const singleSpillOffset = totalNumbers - pagesCount - 1;
            const leftSpill = startPage > 2;
            const rightSpill = endPage < beforeLastPage;

            const leftSpillPage = LEFT_PAGE;
            const rightSpillPage = RIGHT_PAGE;

            if (leftSpill && !rightSpill) {
                const extraPages = range(startPage - singleSpillOffset, startPage - 1);
                pageDetails = [leftSpillPage, ...extraPages, ...pageDetails];
            } else if (!leftSpill && rightSpill) {
                const extraPages = range(endPage + 1, endPage + singleSpillOffset);
                pageDetails = [...pageDetails, ...extraPages, rightSpillPage];
            } else if (leftSpill && rightSpill) {
                pageDetails = [leftSpillPage, ...pageDetails, rightSpillPage];
            }
            setPages([1, ...pageDetails, totalPages]);
        } else {
            setPages(range(1, totalPages));
        }
    });

    useEffect(() => {
        if (arrangementList !== props.data) {
            fetchPageNumbers();
            setArrangementList(props.data);
        }
    }, [props.data, arrangementList, fetchPageNumbers]);

    const renderListBody = (arrangementNodes) => {
        if (arrangementNodes === '') {
            return (
                <tr aria-colspan={6}>
                    <td>You have no signature request.</td>
                </tr>
            );
        } else {
            return arrangementNodes;
        }
    };

    let arrangementNodes = '';

    if (Array.isArray(arrangementList) && arrangementList.length) {
        arrangementNodes = arrangementList.map((arrangement) => <SigningRequestArrangement data={arrangement} key={arrangement.id} tab={tab} />);
    }

    const handleClick = (page, evt) => {
        evt.preventDefault();
        gotoPage(page);
    };

    const handleMoveLeft = (evt) => {
        evt.preventDefault();
        gotoPage(props.currentPage - PAGE_NEIGHBOR * 2 - 1);
    };

    const handleMoveRight = (evt) => {
        evt.preventDefault();
        gotoPage(props.currentPage + PAGE_NEIGHBOR * 2 + 1);
    };

    const gotoPage = (page) => {
        const currentPage = Math.max(0, Math.min(page, props.totalPages));

        const paginationData = {
            pageNumber: currentPage,
            pageSize: props.pageSize
        };

        props.onPageChanged(paginationData);
    };

    return (
        <>
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Folder</th>
                            <th scope="col">Document Title</th>
                            <th scope="col">
                                No. of
                                <br />
                                Recipients
                            </th>
                            <th scope="col">Created on</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>{renderListBody(arrangementNodes)}</tbody>
                </table>
                <nav aria-label="Pagination">
                    <div className="pagination-container">
                        <MaxItemLimiter data={props.maxRecordsOption} onPageSizeChanged={props.onPageSizeChanged} />
                        <div className="item_count">
                            {props.totalRecords > 0 && (
                                <>
                                    {(props.currentPage - 1) * props.pageSize + 1}-{(props.currentPage - 1) * props.pageSize + props.currentPageRecords} of {props.totalRecords} items
                                </>
                            )}
                        </div>
                        <ul className="pagination">
                            {pages.map((page, index) => {
                                if (page === LEFT_PAGE)
                                    return (
                                        <li key={index} className="page-item">
                                            <a className="page-link" href="#" aria-label="Previous" onClick={handleMoveLeft}>
                                                <span aria-hidden="true">&laquo;</span>
                                                <span className="sr-only">Previous</span>
                                            </a>
                                        </li>
                                    );

                                if (page === RIGHT_PAGE)
                                    return (
                                        <li key={index} className="page-item">
                                            <a className="page-link" href="#" aria-label="Next" onClick={handleMoveRight}>
                                                <span aria-hidden="true">&raquo;</span>
                                                <span className="sr-only">Next</span>
                                            </a>
                                        </li>
                                    );

                                return (
                                    <li key={index} className={`page-item${props.currentPage === page ? ' active' : ''}`}>
                                        <a className="page-link" href="#" onClick={(e) => handleClick(page, e)}>
                                            {page}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    );
}
