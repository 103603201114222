import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteArrangement, fetchMyArrangements, fetchArrangementDetail, fetchSignatures, fetchDocumentAsync, patchDueDateAsync } from './myDashboardAPI';
import { loadAppSettings } from '../../../AppSettings';

const initialState = {
    // data: {
    //     searchKeyword: '',
    //     sortByValue: 10,
    //     sortByOption: ['All'],
    //     pendingSignatureCount: 0,
    //     signedSignatureCount: 0,
    //     expiredSignatureCount: 0,
    //     pendingSigningRequests: [],
    //     signedSigningRequests: [],
    //     expiredSigningRequests: [],
    //     maxRecordsPerPage: 10,
    //     maxRecordsOption: ['10'],
    //     currentPage: 1
    // },
    pendingSigning: {
        signingRequestCount: 0,
        signingRequestArrangements: [],
        pageSize: 0,
        totalPages: 0,
        currentPage: 1,
        maxRecordsOption: ['10'],
        searchKeyword: '',
        sortByOptions: [],
        sortBy: ''
    },
    expiredSigning: {
        signingRequestCount: 0,
        signingRequestArrangements: [],
        pageSize: 0,
        totalPages: 0,
        currentPage: 1,
        maxRecordsOption: ['10'],
        searchKeyword: '',
        sortByOptions: [],
        sortBy: ''
    },
    signedSigning: {
        signingRequestCount: 0,
        signingRequestArrangements: [],
        pageSize: 0,
        totalPages: 0,
        currentPage: 1,
        maxRecordsOption: ['10'],
        searchKeyword: '',
        sortByOptions: [],
        sortBy: ''
    },
    declinedSigning: {
        signingRequestCount: 0,
        signingRequestArrangements: [],
        pageSize: 0,
        totalPages: 0,
        currentPage: 1,
        maxRecordsOption: ['10'],
        searchKeyword: '',
        sortByOptions: [],
        sortBy: ''
    },
    arrangementDetail: [],
    // status: 'idle',
    status: {
        general: 'idle',
        appSettingsFetching: 'idle',
        pendingSignaturesFetching: 'idle',
        signedSignaturesFetching: 'idle',
        expiredSignaturesFetching: 'idle',
        declinedSignaturesFetching: 'idle'
    },
    arrangementItmStatus: 'idle',
    reloadRequired: false,
    error: null,
    appSettings: null
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchAppSettingsAsync = createAsyncThunk('myDashboard/appSettings', async () => {
    const response = await loadAppSettings();
    return response
})
export const fetchPendingSignaturesAsync = createAsyncThunk('myDashboard/fetchPendingSignatures', async (queryParams) => {
    const status = ['DRAFT', 'SUBMITTED', 'INPROGRESS'];
    const response = await fetchSignatures(status, queryParams);
    console.log("[fetchPendingSignaturesAsync] response:");
    console.log(response);
    return response;

});

export const fetchExpiredSignaturesAsync = createAsyncThunk('myDashboard/fetchExpiredSignatures', async (queryParams) => {
    const status = ['EXPIRED', 'ERROR'];
    const response = await fetchSignatures(status, queryParams);
    console.log("[fetchExpiredSignaturesAsync] response:");
    console.log(response);
    return response;
});

export const fetchDeclinedSignaturesAsync = createAsyncThunk('myDashboard/fetchDeclinedSignatures', async (queryParams) => {
    const status = ['DECLINED'];
    const response = await fetchSignatures(status, queryParams);
    console.log("[fetchDeclinedSignaturesAsync] response:");
    console.log(response);
    return response;
});

export const fetchSignedSignaturesAsync = createAsyncThunk('myDashboard/fetchSignedSignatures', async (queryParams) => {
    const status = ['COMPLETED'];
    const response = await fetchSignatures(status, queryParams);
    console.log("[fetchSignedSignaturesAsync] response:");
    console.log(response);
    return response;
});

export const fetchArrangementDetailAsync = createAsyncThunk('myDashboard/fetchArrangementDetail', async (id) => {
    const response = await fetchArrangementDetail(id);
    return response;
});

export const deleteArrangementAsync = createAsyncThunk('myDashboard/deleteArrangement', async (id) => {
    return deleteArrangement(id);
});

export const downloadDocumentAsync = createAsyncThunk('myDashboard/downloadDocumentAsync', async (payload) => {

    const response = await fetchDocumentAsync(payload.id, payload.documentTitle);
    return response;
}

);

export const ExtendDueDateAsync = createAsyncThunk('myDashboard/extendDueDateAsync', async (payload) => {

    const { id, dueDate, recipients } = payload;
    const body = { dueDate, recipients }
    const response = await patchDueDateAsync(id, body);
    return response;
}

);

export const MyDashboardSlice = createSlice({
    name: 'myDashboard',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        getMyArrangementsAction: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.viewModel = initialState;
        },
        setReloadRequired: (state) => {
            state.reloadRequired = true;
        },
        unSetReloadRequired: (state) => {
            state.reloadRequired = false;
        },
        updateRecipients: (state, action) => {
            const { recipients, arrangementId } = action.payload;
            const arrangement = state.pendingSigning.signingRequestArrangements.find(req => req.id === arrangementId);
            arrangement.recipients = recipients;
            return state;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppSettingsAsync.pending, (state) => {
                state.status.general = 'loading';
                state.status.appSettingsFetching = 'loading';
            })
            .addCase(fetchAppSettingsAsync.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.status.general = 'fulfilled';
                    state.status.appSettingsFetching = 'fulfilled';
                    state.appSettings = action.payload;
                }
            })
            .addCase(fetchAppSettingsAsync.rejected, (state, action) => {
                state.status.general = 'failed';
                state.status.appSettingsFetching = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchPendingSignaturesAsync.pending, (state) => {
                state.status.general = 'loading';
                state.status.pendingSignaturesFetching = 'loading';
            })
            .addCase(fetchSignedSignaturesAsync.pending, (state) => {
                state.status.general = 'loading';
                state.status.signedSignaturesFetching = 'loading';
            })
            .addCase(fetchExpiredSignaturesAsync.pending, (state) => {
                state.status.general = 'loading';
                state.status.expiredSignaturesFetching = 'loading';
            })
            .addCase(fetchDeclinedSignaturesAsync.pending, (state) => {
                state.status.general = 'loading';
                state.status.declinedSignaturesFetching = 'loading';
            })
            .addCase(fetchPendingSignaturesAsync.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.status.general = 'fulfilled';
                    state.status.pendingSignaturesFetching = 'fulfilled';
                    state.pendingSigning = action.payload;
                }

            })
            .addCase(fetchSignedSignaturesAsync.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.status.general = 'fulfilled';
                    state.status.signedSignaturesFetching = 'fulfilled';
                    state.signedSigning = action.payload;
                }

            })
            .addCase(fetchExpiredSignaturesAsync.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.status.general = 'fulfilled';
                    state.status.expiredSignaturesFetching = 'fulfilled';
                    state.expiredSigning = action.payload;
                }
            })
            .addCase(fetchDeclinedSignaturesAsync.fulfilled, (state, action) => {
                if (action.payload !== undefined) {
                    state.status.general = 'fulfilled';
                    state.status.declinedSignaturesFetching = 'fulfilled';
                    state.declinedSigning = action.payload;
                }
            })
            .addCase(fetchPendingSignaturesAsync.rejected, (state, action) => {
                state.status.general = 'failed';
                state.status.pendingSignaturesFetching = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchSignedSignaturesAsync.rejected, (state, action) => {
                state.status.general = 'failed';
                state.status.signedSignaturesFetching = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchExpiredSignaturesAsync.rejected, (state, action) => {
                state.status.general = 'failed';
                state.status.expiredSignaturesFetching = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchDeclinedSignaturesAsync.rejected, (state, action) => {
                state.status.general = 'failed';
                state.status.declinedSignaturesFetching = 'failed';
                state.error = action.error.message;
            })
            //fetchArrangementDetailAsync
            .addCase(fetchArrangementDetailAsync.pending, (state) => {
                state.status.general = 'loading';
            })
            .addCase(fetchArrangementDetailAsync.fulfilled, (state, action) => {
                state.status.general = 'fulfilled';

                const i = state.arrangementDetail.findIndex((e) => e.arrangementId);
                if (i === undefined) {
                    state.arrangementDetail.push(action.payload);
                } else {
                    //  let newDetail =
                    //      {
                    //          ...state.arrangementDetail[i]
                    //      };
                    //todo update arrangementdetail
                }
            })
            .addCase(fetchArrangementDetailAsync.rejected, (state, action) => {
                state.status.general = 'failed';
                state.error = action.error.message;
            })

            .addCase(deleteArrangementAsync.pending, (state, action) => {
                state.status.general = 'loading';
            })
            .addCase(deleteArrangementAsync.rejected, (state, action) => {
                state.status.general = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteArrangementAsync.fulfilled, (state, action) => {
                {
                    state.status.general = 'fulfilled'
                    let id = action.meta.arg;
                    //console.log("id to delete: " + id);

                    let i = state.pendingSigning.signingRequestArrangements.findIndex((e) => e.id === id);
                    // let i = state.data.pendingSigningRequests.findIndex((e) => e.id === id);
                    //console.log("Found item to delete. index: " + i);
                    if (i > -1) {
                        state.pendingSigning.signingRequestArrangements.splice(i, 1);
                        state.pendingSigning.signingRequestCount = state.pendingSigning.signingRequestCount - 1;
                        return;
                    }
                    i = state.signedSigning.signingRequestArrangements.findIndex((e) => e.id === id);
                    if (i > -1) {
                        state.signedSigning.signingRequestArrangements.splice(i, 1);
                        state.signedSigning.signingRequestCount = state.signedSigning.signingRequestCount - 1;
                        return;
                    }

                    i = state.expiredSigning.signingRequestArrangements.findIndex((e) => e.id === id);
                    if (i > -1) {
                        state.expiredSigning.signingRequestArrangements.splice(i, 1);
                        state.expiredSigning.signingRequestCount = state.expiredSigning.signingRequestCount - 1;
                    }

                    i = state.declinedSigning.signingRequestArrangements.findIndex((e) => e.id === id);
                    if (i > -1) {
                        state.declinedSigning.signingRequestArrangements.splice(i, 1);
                        state.declinedSigning.signingRequestCount = state.declinedSigning.signingRequestCount - 1;
                    }
                }
            })
            .addCase(downloadDocumentAsync.pending, (state, action) => {
                state.status.general = 'loading';
            })
            .addCase(downloadDocumentAsync.rejected, (state, action) => {
                state.status.general = 'failed';
                state.error = action.error.message;
            })
            .addCase(downloadDocumentAsync.fulfilled, (state, action) => {
                if (action.payload.response === "OK") {
                    state.status.general = 'fulfilled';
                }
                else {
                    state.status.general = "failed";
                }
            })
            .addCase(ExtendDueDateAsync.pending, (state, action) => {
                state.status.general = 'loading';
            })
            .addCase(ExtendDueDateAsync.rejected, (state, action) => {
                state.status.general = 'failed';
                state.error = action.error.message;
            })
            .addCase(ExtendDueDateAsync.fulfilled, (state, action) => {
                if (action.payload.status === "OK") {
                    state.status.general = 'fulfilled';
                }
                else {
                    state.status.general = "failed";
                }
            });
    }
});

export const { getMyArrangementsAction, setReloadRequired, unSetReloadRequired, updateRecipients } = MyDashboardSlice.actions;
export const selectPendingData = (state) => state.myDashboard.pendingSigning;
export const selectExpiredData = (state) => state.myDashboard.expiredSigning;
export const selectSignedData = (state) => state.myDashboard.signedSigning;
export const selectDeclinedData = (state) => state.myDashboard.declinedSigning;
export const selectStatus = (state) => state.myDashboard.status;
export const selectArrangmentItmStatus = (state) => state.myDashboard.arrangementItmStatus;
export const selectReloadRequired = (state) => state.myDashboard.reloadRequired;
export const selectArrangementDetail = (state) => (id) => state.arrangementDetail(id);
export const selectAppSettings = (state) => state.myDashboard.appSettings;
export const selectIsLoading = (state) => {
    return (state.myDashboard.status.pendingSignaturesFetching !== 'fulfilled' ||
        state.myDashboard.status.signedSignaturesFetching !== 'fulfilled' ||
        state.myDashboard.status.expiredSignaturesFetching !== 'fulfilled' ||
        state.myDashboard.status.declinedSignaturesFetching !== 'fulfilled' ||
        state.myDashboard.status.appSettingsFetching !== 'fulfilled' ||
        state.myDashboard.status.general === 'loading');
}
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectCount = (state) => state.counter.value;

export default MyDashboardSlice.reducer;
