import React from 'react';
import Layout from './app/components/Layout';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { ArrangerDashboard } from './app/features/myDashboard/arrangerDashboard';
import { NewSigningRequestWizard } from './app/features/newSigningRequestWizard/newSigningRequestWizard';

const App = () => {
    return (
        <>
            <Layout>
                <HashRouter>
                    <Routes>
                        <Route path="/" element={<ArrangerDashboard />} />
                        <Route path="MyDashboard" element={<ArrangerDashboard />} />
                        <Route path="NewSigningRequest" element={<NewSigningRequestWizard />} />
                        
                        {/* <Route path="/prepare/MyDashboard" element={<ArrangerDashboard />} />
                        <Route path="/prepare/NewSigningRequest" element={<NewSigningRequestWizard />} /> */}
                    </Routes>
                </HashRouter>
            </Layout>
        </>
    );
};

export default App;
