import React, { useState } from 'react';

export function SignerDetails(props) {
    // const [isRejected, setIsRejected] = useState(props.isRejected);
    // const [email, setEmail] = useState(props.email);
    // const [phone, setPhone] = useState(props.phone);

    const [isRejected] = useState(props.isRejected);
    const [email] = useState(props.email);
    const [phone] = useState(props.phone);

    const displayRejected = () => {
        if (!isRejected) return;
        return <span className="jds-tag has-error-light">Rejected </span>;
    };
    const displayEmail = () => {
        if (email === '') return;
        return (
            <span>
                <i className="fal fa-envelope mr-1"></i>
                {email}
            </span>
        );
    };
    const displayPhone = () => {
        if (phone === '') return;
        return (
            <span>
                <i className="fal fa-phone mr-1"></i>
                {phone}
            </span>
        );
    };

    return (
        <div className="signer-details align-items-center">
            {displayRejected()}
            {displayEmail()}
            {displayPhone()}
        </div>
    );
}
