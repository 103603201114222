const displayMonth = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec"
}

export const convertISOToDisplayDate = (dateISO) => {

    const date = new Date(dateISO);
    const displayDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${displayDay} ${displayMonth[date.getMonth()]} ${date.getFullYear()}`;
}

export const convertToISOString = (date) => {
    try {
        const dateObject = new Date(date);
        dateObject.setHours(23, 59, 0);
        const dateISO = dateObject.toISOString();
        return dateISO;
    } catch (e) {
        console.log(`Invalid date: ${date}`);
    }
}