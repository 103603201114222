export const HOME_URL = process.env.PUBLIC_URL;
export const DSS_LOGO_URL = `${HOME_URL}/assets/uds/images/dss-logo.png`;
export const CL_LOGO_URL = `${HOME_URL}/assets/uds/images/cl-logo.png`;

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const MY_DASHBOARD_URLS = {
    GET_SIGNATURE_URL: '/MyDashboard/LoadData',
    DELETE_ARRANGEMENT_URL: '/MyArrangements',
    GET_ARRANGEMENT_DETAIL_URL: '/MyArrangements',
    EXTEND_DUE_DATE_URL: '/MyArrangements'
};

export const SIGN_UI_URL = process.env.REACT_APP_SIGN_UI_URL;

export const INFO_HOME_URL = process.env.REACT_APP_INFO_BASE_URL;
export const INFO_FAQ_URL = `${INFO_HOME_URL}/FAQ.html`;
export const INFO_CONTACT_URL = `${INFO_HOME_URL}/Contact.html`;
export const INFO_TERM_OF_SERVICE_URL = `${INFO_HOME_URL}/TermsOfService.pdf`;
export const CLDIGITAL_USER_MANUAL_URL = `${INFO_HOME_URL}/CLDigital_v1 1.pdf`;

const CL_HOME_URL = process.env.REACT_APP_CL_BASE_URL;
export const CL_PRIVACY_POLICY_URL = `${CL_HOME_URL}/privacy-policy`;

export const IDENTIFICATION_TYPE_LIST = [
    {
        key: 99,
        value: 'Singpass/CrimsonLogic Digital',
        selected: true
    },
    {
        key: 0,
        value: 'Singpass',
        selected: false
    },
    {
        key: 1,
        value: 'CrimsonLogic Digital',
        selected: false
    }
]

export const PLACEHOLDER_IMAGE_PATH = {
    0: `${HOME_URL}/assets/images/Signature_area.svg`,
    "Unspecified": `${HOME_URL}/assets/images/Signature_area.svg`,

    10: `${HOME_URL}/assets/images/Signature_area.svg`,
    30: `${HOME_URL}/assets/images/Signature_area.svg`,
    "Singpass": `${HOME_URL}/assets/images/Signature_area.svg`,
    "DeferredSingpass": `${HOME_URL}/assets/images/Signature_area.svg`,

    40: `${HOME_URL}/assets/images/DSS_Signature.svg`,
    50: `${HOME_URL}/assets/images/DSS_Signature.svg`,
    "OrgCertificate": `${HOME_URL}/assets/images/DSS_Signature.svg`,
    "DeferredOrgCertificate": `${HOME_URL}/assets/images/DSS_Signature.svg`,
}