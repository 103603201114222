import React, { useState, useEffect } from 'react';

export function AccessCodeInstruction() {
    const [isExpanded, setIsExpanded] = useState(false);

    // useEffect(() => {

    // },[isExpanded])

    const expandInfo = () => {
        if (isExpanded) {
            return (
                <div className="information-d">
                    <div className="information-steps">
                        <p>
                            <span>
                                <i className="fal fa-link" />
                            </span>
                            Document URL
                        </p>
                        <p>
                            <span>
                                <i className="fal fa-key" />
                            </span>
                            Access Code
                        </p>
                        <p>
                            <span>
                                <i className="fal fa-keyboard" />
                            </span>
                            Access Reference
                        </p>
                    </div>
                    <br></br>
                    <ul>
                        <li>The credentials are specific to each signer. Do check that you’re sending them to the right signer.</li>
                        <li>Arranger can send the document url with access code and reference via email, sms or any other preferred mode.</li>
                    </ul>
                </div>
            );
        }
    };

    return (
        <div className="alert alert-default has-icon alert-large" role="alert">
            <span className="has-icon">
                <i className="fal fa-lightbulb-on"></i>
            </span>
            <div className="alert-content">
                <h4 className="color-primary">
                    To get the document signed, the following information must be sent to each signer...
                    <button
                        type="button"
                        className={`btn btn-outline-secondary show-more ${isExpanded && 'selected'} ml-3`}
                        onClick={(e) => {
                            console.log('expanding ');
                            setIsExpanded(!isExpanded);
                        }}
                    ></button>
                </h4>
                {expandInfo()}
            </div>
        </div>
    );
}
