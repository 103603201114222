import React, {} from "react";

export function ArrangementDetailHeader(props) {
    return(
        <div className="modal-header">
            <h3 className="modal-title" id="jdsModalLabel5">
                <small>Access Code Details</small>
                <br/>
                {props.documentName}
            </h3>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i aria-hidden="true" className="fal fa-times"></i>
            </button>
        </div>
    )
}

