import React from 'react';
import Header from './Header';
import Footer from './Footer';
import ScrollButton from './ScrollButton';

const Layout = (props) => {
    return (
        <div className="wrapper sticky" id="page">
            <Header />
            <main className="main-content bg-white">{props.children}</main>
            <Footer />
            <ScrollButton />
        </div>
    );
};

export default Layout;
