import { useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import { convertISOToDisplayDate, convertToISOString } from '../../../utils/dateFormat';

export const DueDateExtensionRecipients = (props) => {
    const datePicker = useRef();
    const isOperationComplete = props.isOperationComplete;
    const lastSavedRecipients = props.lastSavedRecipients;
    const recipients = props.recipients;
    const errorMessages = props.errorMessages;
    const updateArrangementRecipients = props.updateArrangementRecipients;
    const setArrangementDueDate = props.setArrangementDueDate;
    const setErrorMessages = props.setErrorMessages;

    const updateArrangementDueDate = (modifiedRecipients) => {
        const recipientsDueDateList = modifiedRecipients.map(recipient => new Date(recipient.dueDate))
        const dueDate = convertToISOString(Math.max.apply(null, recipientsDueDateList));
        setArrangementDueDate(dueDate);
    }

    const validateRecipients = (modifiedRecipients) => {
        const isSequential = !modifiedRecipients.every(recipient => recipient.signingOrder === 1);
        const moreThanOneRecipient = modifiedRecipients.length > 1;
        let hasError = false;
        let minDueDate = new Date();
        let errorMessageList = errorMessages;

        if (isSequential && moreThanOneRecipient) {
            let prevRecipientsDueDates = [];
            modifiedRecipients.forEach(recipient => {
                if (prevRecipientsDueDates.length > 0) {
                    let currentDate = new Date(recipient.dueDate);

                    // Update the min due date for the error message if error does not exist yet
                    if (!hasError) {
                        hasError = prevRecipientsDueDates.some(date => currentDate < date);
                        minDueDate = new Date(Math.max.apply(null, prevRecipientsDueDates));
                        minDueDate.setHours(23, 59, 0);
                    }
                    let minDueDateString = minDueDate.toISOString().split("T")[0];

                    // Display the error message if current recipient due Date is invalid
                    let invalidDueDate = prevRecipientsDueDates.some(date => currentDate < date);
                    if (invalidDueDate) {
                        const hasErrorMessageForRecipient = errorMessageList.some(item => item.id === recipient.id);
                        let errMsg = "";
                        if (hasErrorMessageForRecipient) {
                            errMsg = errorMessageList.find(item => item.id === recipient.id);
                            errMsg.message = `Please select a due date later than the ${minDueDateString}`;
                        } else {
                            errMsg = {
                                id: recipient.id,
                                message: `Please select a due date later than the ${minDueDateString}`
                            }
                            errorMessageList.push(errMsg);
                        }
                    } else {
                        errorMessageList = errorMessageList.filter(item => item.id !== recipient.id);
                    }
                }
                prevRecipientsDueDates.push(new Date(recipient.dueDate));
            })
        }
        setErrorMessages(errorMessageList);
    }

    const modifyRecipientDueDate = (id, date) => {
        const dueDateISO = convertToISOString(date);
        const modifiedRecipients = recipients.map(recipient => {
            if (recipient.id === id)
                return { ...recipient, dueDate: dueDateISO, displayDueDate: convertISOToDisplayDate(dueDateISO) };
            return recipient;
        });

        validateRecipients(modifiedRecipients);
        updateArrangementRecipients(modifiedRecipients);
        updateArrangementDueDate(modifiedRecipients);
    }

    const resetRecipientDueDate = (id) => {
        const lastSavedDueDate = lastSavedRecipients.find(recipient => recipient.id === id).dueDate;
        const resetRecipients = recipients.map(recipient => {
            if (recipient.id === id)
                return { ...recipient, dueDate: lastSavedDueDate, displayDueDate: convertISOToDisplayDate(lastSavedDueDate) };
            return recipient;
        });

        validateRecipients(resetRecipients);
        updateArrangementRecipients(resetRecipients);
    }

    const displayErrorMessageIfAny = (id) => {
        const hasErrorMessage = Array.isArray(errorMessages) && errorMessages.some(msg => msg.id === id);
        const message = hasErrorMessage && errorMessages.find(msg => msg.id === id).message;

        return (
            Array.isArray(errorMessages) &&
            hasErrorMessage &&
            <div className="message text-danger mw-100">
                {message}
            </div>
        )
    }

    const displayResetButton = (id, isHidden) => {
        return (
            !isHidden &&
            <button className="btn btn-sm btn-primary mx-2" onClick={() => resetRecipientDueDate(id)}>
                <i className="fas fa-redo-alt" />
            </button>
        )
    }

    return (
        recipients.map(recipient => {
            const { id, name, dueDate, signingOrder } = recipient;
            const lastSaved = lastSavedRecipients.find(recipient => recipient.id === id).dueDate;
            const minPickableDate = lastSaved;
            const isResetHidden = isOperationComplete;
            const isDatePickerDisabled = isOperationComplete;
            return (

                <div className="card py-3 border-0" key={id}>
                    <div className="card-order">
                        <input className="form-control text-center" type="text" value={signingOrder} readOnly />
                    </div>
                    <div className="card-header">
                        <h4 className="card-title">
                            <span>
                                <i className="fal fa-user"></i>
                            </span>
                            <span className="w-auto bg-transparent">{name}</span>
                        </h4>
                    </div>
                    <div className="card-body">
                        <div className="form-group required">
                            <div className="d-flex">
                                <div className="flatpickr-wrapper">
                                    <Flatpickr
                                        className="input flatpickr-input d-block"
                                        placeholder="Due Date"
                                        options={{
                                            clickOpens: !isDatePickerDisabled,
                                            minDate: minPickableDate,
                                            dateFormat: 'd-F-Y'
                                        }}
                                        value={new Date(dueDate).toISOString()}
                                        ref={datePicker}
                                        name="DueDate"
                                        onChange={date => modifyRecipientDueDate(id, date)}
                                    />
                                    {displayErrorMessageIfAny(id)}
                                </div>
                                {displayResetButton(id, isResetHidden)}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    )
}