import React from 'react';
//import { RecipientsList } from './recipientsList.jsx';
import { AddRecipientForm } from './addRecipientForm.jsx';


export function AddRecipientsStep() {
    return (
        <div>

            <AddRecipientForm />

        </div>
    );
}
