import React, { useState } from 'react';
import { ArrangementDetailHeader } from './arrangementDetailHeader.jsx';
import { AccessCodeInstruction } from './accessCodeInstruction';
import { RecipientList } from './recipientList.jsx';

export function ArrangementItemDetail(props) {
    // const [arrangementId, setArrangementId] = useState(props.arrangementId);
    // const [documentName, setDocumentName] = useState(props.documentName);
    // const [recipients, setRecipients] = useState(props.recipients);

    const [arrangementId] = useState(props.arrangementId);
    const [documentName] = useState(props.documentName);
    const [documentTitle] = useState(props.documentTitle);
    const recipients = props.recipients;

    const modalId = 'arrangement_detail-' + arrangementId;
    return (
        <div className="modal fade show" id={modalId} tabIndex="-1" aria-labelledby="arrangement_detail" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog modal-fullwidth" role="document">
                <div className="modal-content">
                    <ArrangementDetailHeader documentName={documentName} />
                    <div className="modal-body">
                        <AccessCodeInstruction />
                        <RecipientList recipients={recipients} documentTitle={documentTitle} />
                    </div>
                </div>
            </div>
        </div>
    );
}
