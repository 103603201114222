import React, { useState } from 'react';

export function RejectionReason(props) {
    //const [reason, setReason] = useState(props.reason);
    const [reason] = useState(props.reason);

    if (reason !== '') {
        return (
            <div className="rejection-reasons my-4">
                <h4 style={{ color: "#751c24" }}> Reasons for Rejection</h4>
                {reason}
            </div>
        );
    }
}
