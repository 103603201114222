import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCanvasPage, addStamp, selectCurrentPageSnapshot } from '../signingRequestWizardSlice';

export function AddSignaturePlaceholderCard(props) {
    // const [coordinate, setCoordinate] = useState({ x: 5, y: 5, page: currentCanvasPage });
    // const [counter, setCounter] = useState(1);
    // const [signerName, setSignerName] = useState(props.signerName);
    // const [signerId, setSignerId] = useState(props.signerId);
    const currentCanvasPage = useSelector(selectCurrentCanvasPage);
    const pageSnapshot = useSelector(selectCurrentPageSnapshot);

    const [coordinate, setCoordinate] = useState({ x: 5, y: 5, page: currentCanvasPage, scaleRatio: 1 });
    const [counter] = useState(1);
    const [signerName] = useState(props.signerName);
    const [signerId] = useState(props.signerId);
    const signingmethod = props.signingmethod;
    const signerTag = props.signerTag;

    const [showSecureCodeForm, setShowSecureCodeForm] = useState(false);
    const [secureCode, setSecureCode] = useState('');

    useEffect(() => {
        if (pageSnapshot != undefined) {
            setCoordinate({
                ...coordinate,
                scaleRatio: pageSnapshot.scaleRatio
            });
        }

    }, [pageSnapshot])
    const dispatch = useDispatch();

    return (
        <div className="card-item d-flex">
            <h5>{signerName}</h5>
            <button
                type="button"
                className="btn btn-primary btn-small mb-1 w-100"
                onClick={(e) => {
                    const newStamp = {
                        signerTag,
                        signerId,
                        signerName,
                        counter,
                        hasSecureCode: false,
                        secureCode: '',
                        coordinate: {
                            ...coordinate,
                            page: currentCanvasPage
                        },
                        signingmethod
                    };
                    dispatch(addStamp(newStamp));
                }}
            >
                Add Signature
            </button>
            {!showSecureCodeForm && (
                <button disabled type="button" className="btn btn-primary btn-small w-100 add-secure-code" onClick={(e) => setShowSecureCodeForm(!showSecureCodeForm)}>
                    Add Signature with Secure Code
                </button>
            )}

            {showSecureCodeForm && (
                <div className="secure-code w-100 mt-3">
                    Enter message for secure code
                    <textarea className="form-control" value={secureCode} onChange={(e) => setSecureCode(e.target.value)}></textarea>
                    <div className="form-check"></div>
                    <button
                        type="button"
                        className="btn btn-primary btn-small w-100 mb-2"
                        disabled={secureCode.trim().length === 0}
                        onClick={(e) => {
                            const newStamp = {
                                signerId,
                                signerName,
                                counter,
                                hasSecureCode: true,
                                secureCode: secureCode,
                                coordinate: {
                                    ...coordinate,
                                    page: currentCanvasPage
                                }
                            };
                            dispatch(addStamp(newStamp));
                            setSecureCode('');
                            setShowSecureCodeForm(!showSecureCodeForm);
                        }}
                    >
                        Add secure code
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary btn-small w-100 remove-secure-code"
                        onClick={(e) => {
                            setSecureCode('');
                            setShowSecureCodeForm(!showSecureCodeForm);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </div>
    );
}
