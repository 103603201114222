import React, { useState, useEffect } from 'react';
import { SIGNED, PENDING, EXPIRED, DECLINED } from './common';

export function ArrangementListSwitcher(props) {
    const [pendingSignatureCount, setPendingSignatureCount] = useState(props.pendingSignatureCount);
    const [signedSignatureCount, setSignedSignatureCount] = useState(props.signedSignatureCount);
    const [expiredSignatureCount, setExpiredSignatureCount] = useState(props.expiredSignatureCount);
    const [declinedSignatureCount, setDeclinedSignatureCount] = useState(props.declinedSignatureCount);

    const pendingTabSelected = props.tab === PENDING ? 'active' : '';
    const signedTabSelected = props.tab === SIGNED ? 'active' : '';
    const expiredTabSelected = props.tab === EXPIRED ? 'active' : '';
    const declinedTabSelected = props.tab === DECLINED ? 'active' : '';

    useEffect(() => {
        if (props.pendingSignatureCount !== pendingSignatureCount) {
            setPendingSignatureCount(props.pendingSignatureCount);
        }
        if (props.signedSignatureCount !== signedSignatureCount) {
            setSignedSignatureCount(props.signedSignatureCount);
        }
        if (props.expiredSignatureCount !== expiredSignatureCount) {
            setExpiredSignatureCount(props.expiredSignatureCount);
        }
        if (props.declinedSignatureCount !== declinedSignatureCount) {
            setDeclinedSignatureCount(props.declinedSignatureCount);
        }
    }, [props, pendingSignatureCount, expiredSignatureCount, signedSignatureCount, declinedSignatureCount]);

    const handleClick = (name, evt) => {
        evt.preventDefault();
        props.onTabChanged(name);
    };

    return (
        <ul className="nav nav-tabs left">
            <li className="nav-item" onClick={(e) => handleClick(PENDING, e)}>
                <a className={`nav-link ${pendingTabSelected}`} data-toggle="tab" href="#h-tab-1">
                    Pending Signature
                    <strong className="nos">{pendingSignatureCount}</strong>
                </a>
            </li>
            <li className="nav-item" onClick={(e) => handleClick(SIGNED, e)}>
                <a className={`nav-link ${signedTabSelected}`} data-toggle="tab" href="#h-tab-2">
                    Signed Signature
                    <strong className="nos">{signedSignatureCount}</strong>
                </a>
            </li>
            <li className="nav-item" onClick={(e) => handleClick(EXPIRED, e)}>
                <a className={`nav-link ${expiredTabSelected}`} data-toggle="tab" href="#h-tab-3">
                    Expired Signature
                    <strong className="nos">{expiredSignatureCount}</strong>
                </a>
            </li>
            <li className="nav-item" onClick={(e) => handleClick(DECLINED, e)}>
                <a className={`nav-link ${declinedTabSelected}`} data-toggle="tab" href="#h-tab-4">
                    Declined Signature
                    <strong className="nos">{declinedSignatureCount}</strong>
                </a>
            </li>
        </ul>
    );
}
