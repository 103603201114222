import React, { useState, useEffect } from 'react';
import { SignersNavigation } from './signersNavigation';
import { RecipientCard } from './recipientCard';

export function RecipientList(props) {
    const [documentTitle] = useState(props.documentTitle);
    const [recipients, setRecipients] = useState(props.recipients);
    const [activeRecipient, setActiveRecipient] = useState(props.recipients[0]);

    useEffect(() => {
        if (recipients !== props.recipients) {
            setRecipients(props.recipients);
        }

    }, [recipients, setRecipients, props.recipients]);

    const onActiveRecipientChanged = (id) => {
        if (activeRecipient.id !== id) {
            setActiveRecipient(recipients.find((x) => x.id === id));
        }
    };
    return (
        <div className="vertical-boxed-tab accordion-tab has-icon d-flex my-4 py-0 px-4">
            {activeRecipient && (
                <>
                    <SignersNavigation recipients={recipients} activeRecipient={activeRecipient} onActiveRecipientChanged={onActiveRecipientChanged} />
                    <div id="tab-content" className="accordion tab-content" role="tablist">
                        {(() => {
                            if (Array.isArray(recipients) && recipients.length > 0) {
                                return recipients.map((p, i) => <RecipientCard index={i} recipient={p} key={p.id} activeRecipient={activeRecipient} documentTitle={documentTitle} />);
                            }
                        })()}
                    </div>
                </>
            )}
        </div>
    );
}
