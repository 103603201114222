import React from 'react';
import { SIGN_UI_URL } from '../../../AppConstants';

export function CredentialDetailWithName(props) {
    const uniqueRecipients = (data) => {
        //props is protected with Object.preventExtensions(). Cannot add a new property.
        let tempProps = JSON.parse(JSON.stringify(data));

        return tempProps.reduce((unique, item) => {
            //create unique recipients
            if (!unique.some((obj) => obj.signerName === item.signerName && obj.code === item.code && obj.accessCodeRef === item.accessCodeRef && obj.secret === item.secret)) {
                unique.push(item);
            }

            //push order to a new property signingPlaceholders
            unique.map((d) => {
                if (d.signerName === item.signerName && d.code === item.code && d.accessCodeRef === item.accessCodeRef && d.secret === item.secret) {
                    if (d.hasOwnProperty('signingPlaceholders')) {
                        d.signingPlaceholders = [...d.signingPlaceholders, item.order];
                    } else d.signingPlaceholders = [item.order];
                }
            });
            return unique;
        }, []);
    };
    const recipients = uniqueRecipients(props.recipients);
    const copyToClipboard = (value, tooltipId) => {
        navigator.clipboard.writeText(value);
        var tooltip = document.getElementById(tooltipId);
        if (tooltip !== null) {
            tooltip.textContent = `Copied: ${value}`;
        }
    };

    const resetToolTip = (tooltipId) => {
        var tooltip = document.getElementById(tooltipId);

        if (tooltip !== null) {
            tooltip.textContent = '';
        }
    };

    return (
        <div className="static-table">
            <div className="table-responsive" id="dvAccessDetails">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Signer</th>
                            <th scope="col">Signing Order</th>
                            <th scope="col">URL</th>
                            <th scope="col">Access Code</th>
                            <th scope="col">Access Reference</th>
                            <th scope="col">Sign by end of</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(() => {
                            console.log('about to map recipients');

                            if (recipients !== null) {
                                console.log('recipients is not null');
                                return recipients.map((recipient, i) => (
                                    <tr key={i}>
                                        <td data-title="Signer">
                                            <div className="signer-details">
                                                <strong>{recipient.signerName}</strong>
                                                <br />
                                                <span />
                                                <br />
                                                <span />
                                            </div>
                                        </td>
                                        <td data-title="Signing Order">
                                            <span className="jds-tag has-secondary-light">{recipient.signingOrder}</span>
                                        </td>
                                        <td data-title="URL">
                                            <div className="form-panel">
                                                <div className="copyclipboard">
                                                    <input
                                                        type="text"
                                                        className="form-control accesstext"
                                                        value={`${SIGN_UI_URL}?accessCode=${recipient.code}`}
                                                        id="txtURL1"
                                                        readOnly
                                                    />
                                                    <button
                                                        className="btn btn-outline-primary"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Copy to clipboard"
                                                        data-original-title="Copy to clipboard"
                                                        onClick={() => copyToClipboard(`${SIGN_UI_URL}?accessCode=${recipient.code}`, 'tooltipUrl')}
                                                        onMouseOut={() => resetToolTip('tooltipUrl')}
                                                    >
                                                        <i className="far fa-copy" />
                                                        <span className="tooltiptext" id="tooltipUrl">
                                                            Copy to clipboard
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title="Access Code">
                                            <div className="form-panel">
                                                <div className="copyclipboard">
                                                    <input type="text" className="form-control accesstext" value={recipient.code} id="txtAccessCode1" readOnly />
                                                    <button
                                                        className="btn btn-outline-primary"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Copy to clipboard"
                                                        data-original-title="Copy to clipboard"
                                                        onClick={() => copyToClipboard(recipient.code, 'tooltipAccessCode')}
                                                        onMouseOut={() => {
                                                            resetToolTip('tooltipAccessCode');
                                                        }}
                                                    >
                                                        <i className="far fa-copy" />
                                                        <span className="tooltiptext" id="tooltipAccessCode">
                                                            Copy to clipboard
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title="Access Reference">
                                            <div className="form-panel">
                                                <div className="copyclipboard">
                                                    <input type="text" className="form-control accesstext" value={recipient.secret} id="txtAccessRef1" readOnly />
                                                    <button
                                                        className="btn btn-outline-primary"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Copy to clipboard"
                                                        data-original-title="Copy to clipboard"
                                                        onClick={() => copyToClipboard(recipient.secret, 'copyTTRef1')}
                                                        onMouseOut={() => resetToolTip('copyTTRef1')}
                                                    >
                                                        <i className="far fa-copy" />
                                                        <span className="tooltiptext" id="copyTTRef1">
                                                            Copy to clipboard
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title="Sign by end of">{recipient.dueDate}</td>
                                    </tr>
                                ));
                            } else {
                                console.log('recipient is empty');
                                return (
                                    <tr>
                                        {' '}
                                        <td colSpan={6}>
                                            <span>Nothing to display</span>
                                        </td>
                                    </tr>
                                );
                            }
                        })()}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
