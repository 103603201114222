import React, { useRef } from 'react';
import { Group, Image, Text, Rect } from 'react-konva';
import useImage from 'use-image';
import { } from '../../../appSettings';
import { GetTextPixelLength } from '../../../utils/charactorPx';
//import { SignaturePadSize } from './common'

const SIG_MARKER_ORIGINAL_WIDTH = 152;
const SIG_MARKER_ORIGINAL_HEIGHT = 72;
const SIG_MARKER_RATIO = SIG_MARKER_ORIGINAL_WIDTH / SIG_MARKER_ORIGINAL_HEIGHT;

export function SigPlaceholderImage(props) {
    const SIGNATURE_DIMENSION = props.appSettings.signatureDimension;
    const SECURE_CODE_LIMIT = props.appSettings.secureCodeLimit;
    //const WINDOW_CHANGED_DETECTION_SIZE = 1300;

    const [image] = useImage(props.src);

    const groupNode = useRef();
    const imageNode = useRef();
    const deleteGroup = useRef();
    const labelNode = useRef();
    const secureCodeNode = useRef();
    const rectNode = useRef();

    const scaleRatio = props.scaleRatio;
    //const currentBrowserSize = props.currentBrowserSize;

    let pHeight = SIGNATURE_DIMENSION.height / scaleRatio;
    let pWidth = props.hasSecureCode ? SIGNATURE_DIMENSION.widthWithSecureCode / scaleRatio : SIGNATURE_DIMENSION.width / scaleRatio;
    let sigMarkerHeight = pHeight * 0.75;
    let sigMarkerWidth = sigMarkerHeight * SIG_MARKER_RATIO;
    let x = props.x;
    let y = props.y;
    let signerTag = props.signerTag;
    const tagXPadding = 20;

    // if (currentBrowserSize.width < WINDOW_CHANGED_DETECTION_SIZE) {
    //     console.log('Signature pad need to resize');
    //     const data = SignaturePadSize(currentBrowserSize.width, currentBrowserSize.height, props.appSettings);
    //     console.log(data);
    //     pHeight = data.Height / scaleRatio;
    //     pWidth = props.hasSecureCode ? data.WidthWithSecureCode / scaleRatio : data.Width / scaleRatio;
    //     sigMarkerHeight = pHeight * 0.75;
    //     sigMarkerWidth = sigMarkerHeight * SIG_MARKER_RATIO;

    //     console.log("Original X: " + props.x);
    //     console.log("Original Y: " + props.y);
    // }
    // else {
    console.log("Signature Pad normal size");
    pHeight = SIGNATURE_DIMENSION.height / scaleRatio;
    pWidth = props.hasSecureCode ? SIGNATURE_DIMENSION.widthWithSecureCode / scaleRatio : SIGNATURE_DIMENSION.width / scaleRatio;
    sigMarkerHeight = pHeight * 0.75;
    sigMarkerWidth = sigMarkerHeight * SIG_MARKER_RATIO;
    //}

    console.log('pHeigh and width:');
    console.log(pHeight);
    console.log(pWidth);

    const onGroupNodeMouseOver = (e) => {
        if (props.draggable) {
            deleteGroup.current.show();
        }
    };
    const onGroupNodeMouseOut = (e) => {
        deleteGroup.current.hide();
    };
    const onGroupNodeDragStart = (e) => { };
    const onGroupNodeDragEnd = (e) => {
        props.updateCoordinate(groupNode.current.attrs.x, groupNode.current.attrs.y);
    };
    const onGroupNodeLoad = (e) => {
        deleteGroup.current.hide();
    };

    const onImageMouseOver = (e) => {
        if (props.draggable) {
            document.body.style.cursor = 'pointer';
        }
    };
    const onImageMouseOut = (e) => {
        if (props.draggable) {
            document.body.style.cursor = 'default';
        }
    };

    let opacity = 0.8;
    if (props.readOnly) {
        opacity = 1;
    }
    return (
        <Group
            ref={groupNode}
            draggable={props.draggable}
            onMouseOver={onGroupNodeMouseOver}
            onMouseOut={onGroupNodeMouseOut}
            onDragStart={onGroupNodeDragStart}
            onDragEnd={onGroupNodeDragEnd}
            onLoad={onGroupNodeLoad}
            x={x}
            y={y}
        >
            <Rect ref={rectNode} width={pWidth} height={pHeight} shadowBlur={1} stroke="#235c76" fill={props.readOnly ? '#ffffff' : ''} strokeWidth={0.5} dash={props.readOnly ? [] : [5, 3]} />

            <Image
                image={image}
                opacity={opacity}
                ref={imageNode}
                height={sigMarkerHeight}
                width={sigMarkerWidth}
                onMouseOver={onImageMouseOver}
                onMouseOut={onImageMouseOut}
                x={pWidth / 2 - sigMarkerWidth / 2}
                y={pHeight / 2 - sigMarkerHeight / 2}
            />
            {props.hasSecureCode && (
                <Text
                    x={5}
                    y={pHeight - 35}
                    fill="#388697"
                    text={`Secure Code: ${props.secureCode.substring(0, SECURE_CODE_LIMIT)}${props.secureCode.length > SECURE_CODE_LIMIT ? '...' : ''}`}
                    ref={secureCodeNode}
                />
            )}
            <Text x={5} y={pHeight - 15} fill="#388697" text={props.label} ref={labelNode} />
            <Group x={-5} y={-5}>
                <Rect x={0} y={-10} width={tagXPadding * 2 + GetTextPixelLength(signerTag)} height={20} cornerRadius={3} fill="#235c76" stroke="#235c76" />
                <Text
                    x={tagXPadding}
                    y={-5}
                    text={signerTag}
                    fill="white"
                />
            </Group>
            <Group
                x={-8}
                y={-7}
                onClick={(e) => {
                    if (props.draggable) {
                        props.removeStamp();
                    }
                }}
                ref={deleteGroup}
                visible={false}
                onMouseEnter={(e) => {
                    // style stage container:
                    const container = e.target.getStage().container();
                    container.style.cursor = 'pointer';
                }}
                onMouseLeave={(e) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = 'default';
                }}
            >
                <Rect x={-6} y={-10} width={25} height={25} cornerRadius={15} fill="#cc2936" stroke="#cc2936" />
                <Text
                    x={3}
                    y={-2}
                    text="X"
                    fill="white"
                    onClick={(e) => {
                        if (props.draggable) {
                            props.removeStamp();
                        }
                    }}
                />
                {/* <Rect x={-6} y={-10} width={20} height={20} cornerRadius={10} fill="#cc2936" stroke="#cc2936" />
                <Text
                    x={0}
                    y={-5}
                    text="X"
                    fill="white"
                    onClick={(e) => {
                        if (props.draggable) {
                            props.removeStamp();
                        }
                    }}
                /> */}
            </Group>
        </Group>
    );
}
