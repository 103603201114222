import React, { useEffect } from 'react';
import { DSS_LOGO_URL } from '../../AppConstants';

const Loader = (props) => {
    let show = props.show;
    useEffect(() => {

    }, [show])

    if (show) {
        return (

            <div id="loader">
                <div id="loading-dialog" className="modal show" tabIndex="-1" aria-modal="true"
                    style={{
                        display: "block"
                    }}>
                    <div className="loader-main">
                        <div className="loader">Loading...</div>
                        <span>Just a moment. We are loading the content...</span>
                        <strong>
                            <img src={DSS_LOGO_URL} alt="Document Signing Service" />
                        </strong>
                    </div>
                </div>
            </div>
        );
    }

    else { return (<></>) }
};

export default Loader;
