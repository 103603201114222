import React, { useState } from 'react';

const ScrollButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else if (scrolled <= 300) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
         in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <>
            <a onClick={scrollToTop} style={{ display: visible ? 'inline' : 'none' }} id="scroll-to-top" title="Scroll to Top">
                <span className="fas fa-chevron-up"></span>
            </a>
        </>
    );
};

export default ScrollButton;
