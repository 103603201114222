import React from "react";
import { PreviewSubmitTitlePane } from "./previewSubmitTitlePane";
import { DocumentInfo } from "./documentInfo";
import SignersList from "./signersList";
import { DocPreviewContainer } from "../shared/docPreviewContainer";
import { useSelector } from "react-redux";
import { selectRecipients, selectUploadedDocName, selectUploadedDocTitle, selectErrorMsg } from "../signingRequestWizardSlice";

export function PreviewSubmitPage(props) {
    const docTitle = useSelector(selectUploadedDocTitle);
    const fileName = useSelector(selectUploadedDocName)
    const recipients = useSelector(selectRecipients);
    const errorMsg = useSelector(selectErrorMsg);

    return (
        <div>
            <PreviewSubmitTitlePane />

            <div className="row">
                <div className="col-lg-3 col-md-4">
                    <DocumentInfo
                        docTitle={docTitle}
                        uploadedDocument={fileName}
                    />
                    <SignersList data={recipients} />
                </div>
                <div className="col-lg-9 col-md-8">
                    <DocPreviewContainer draggable={false} readOnly={true} appSettings={props.appSettings} />
                </div>
            </div>
            {errorMsg && <div className="alert alert-danger mt-3" role="alert">
                {errorMsg}
            </div>
            }

        </div>);

}
