import React, { useEffect, useState } from 'react';

export function SignersNavigationItem(props) {
    const active = props.activeRecipient.id === props.recipientId ? 'active' : '';
    const index = props.index;
    const isNextSigner = props.isNextSigner;
    const [signerState, setSignerState] = useState('');

    const isUnsigned = props.coordinates.every(signature => signature.signatureStateCode === "UNSIGNED");
    const isSigned = props.coordinates.every(signature => signature.signatureStateCode === "COMPLETED");
    const isDeclined = props.coordinates.some(signature => signature.signatureStateCode === "DECLINED");
    const isExpired = props.coordinates.some(signature => signature.signatureStateCode === "EXPIRED");
    const isTerminated = props.coordinates.some(signature => signature.signatureStateCode === "TERMINATED");
    const isInProgress = props.coordinates.some(signature => signature.signatureStateCode === "UNSIGNED") &&
        props.coordinates.some(signature => signature.signatureStateCode === "COMPLETED") &&
        !isDeclined && !isExpired && !isTerminated;

    const ICONS = {
        EXPIRED: <i className="fas fa-history rounded-circle" style={{ color: "#ffbf00", fontSize: "1.5rem" }} />,
        UNSIGNED: <div className="text-center rounded-circle border border-secondary" style={{ width: "25px" }}>{index + 1}</div>,
        IN_PROGRESS: <i className="fas fa-check rounded-circle text-success"
            style={{
                padding: "0.15rem",
                borderLeftStyle: "dashed",
                borderTopStyle: "dashed",
                borderRightStyle: "solid",
                borderBottomStyle: "solid"
            }} />,
        COMPLETED: <i className="fas fa-check bg-success p-1 rounded-circle text-light" />,
        DECLINED: <i className="fa fa-stop bg-danger rounded-circle text-light"
            style={{ fontSize: "0.7rem", padding: "0.4rem" }} />,
        TERMINATED: <i className="fad fa-file-times" style={{ fontSize: "1.4rem", padding: "0.2rem" }} />
    }

    const LABELS = {
        RED: <span
            className="sub-text bg-danger font-weight-normal text-white"
            style={{ fontSize: "12px", marginLeft: "35px", padding: "0.1rem 0.5rem", borderRadius: "12px" }}>
            {props.navLabel}
        </span>,
        YELLOW: <span
            className="sub-text font-weight-normal text-white"
            style={{
                fontSize: "12px", marginLeft: "35px", backgroundColor: "#ffbf00", padding: "0.1rem 0.5rem", borderRadius: "12px"
            }}>
            {props.navLabel}
        </span>,
        GREEN: <span
            className="sub-text bg-success font-weight-normal"
            style={{ fontSize: "12px", marginLeft: "35px" }}>
            {props.navLabel}
        </span>,
        NORMAL: <span className={`sub-text font-weight-normal`}
            style={{ fontSize: "12px", marginLeft: "35px" }}>
            {props.navLabel}
        </span>
    }

    useEffect(() => {
        if (isSigned) setSignerState('COMPLETED');
        else if (isDeclined) setSignerState('DECLINED');
        else if (isExpired) setSignerState('EXPIRED');
        else if (isTerminated) setSignerState('TERMINATED')
        else if (isInProgress) setSignerState('IN_PROGRESS');
        else if (isUnsigned) setSignerState('UNSIGNED');

    }, [signerState, setSignerState, props.coordinates])

    const renderSignerStateIcon = (signerState) => {

        if (signerState === 'EXPIRED') return ICONS.EXPIRED;
        else if (signerState === 'DECLINED') return ICONS.DECLINED;
        else if (signerState === 'UNSIGNED') return ICONS.UNSIGNED;
        else if (signerState === 'IN_PROGRESS') return ICONS.IN_PROGRESS;
        else if (signerState === 'COMPLETED') return ICONS.COMPLETED;
        else if (signerState === 'TERMINATED') return ICONS.TERMINATED;

    }

    const renderSignerLabel = (isNextSigner) => {
        if (isNextSigner && (isUnsigned || isInProgress))
            return LABELS.GREEN;
        else if (isTerminated || isDeclined)
            return LABELS.RED;
        else if (isExpired)
            return LABELS.YELLOW;
        else
            return LABELS.NORMAL;
    }

    const handleClick = (id, evt) => {
        evt.preventDefault();
        props.onActiveRecipientChanged(id);
    };
    return (
        <li className={`nav-item ${active} rounded-left`}>
            <a
                id={`sig-tab-${props.index}`}
                className={`nav-link ${active}`}
                href={'#vbt-' + props.index}
                role="tab"
                data-toggle="tab"
                aria-selected="false"
                onClick={(e) => handleClick(props.recipientId, e)}
            >
                <span>
                    {renderSignerStateIcon(signerState)}
                </span>
                <span className="font-weight-normal">{props.recipientName}</span>
                <br />
                {renderSignerLabel(isNextSigner)}
            </a>
        </li>
    );
}
