import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DueDateExtensionModalHeader } from './dueDateExtensionModalHeader.jsx';
import { DueDateExtensionNotification } from './dueDateExtensionNotification.jsx';
import { DueDateExtensionSubmitButton } from './dueDateExtensionSubmitButton.jsx';
import { DueDateExtensionCloseButton } from './dueDateExtensionCloseButton.jsx';
import { DueDateExtensionRecipients } from './dueDateExtensionRecipients.jsx';
import { ExtendDueDateAsync, updateRecipients } from '../myDashboardSlice.js';
import { convertToISOString } from '../../../utils/dateFormat.js';

export function DueDateExtensionModal(props) {

    const arrangementId = props.arrangementId;
    const documentName = props.documentName;
    const dispatch = useDispatch();
    const formatDueDateRecipients = JSON.parse(JSON.stringify(props.recipients))
        .map(recipient => {
            recipient.dueDate = convertToISOString(recipient.dueDate);
            return recipient;
        })
    const [lastSavedRecipients, setLastSavedRecipients] = useState(formatDueDateRecipients);
    const recipients = formatDueDateRecipients;
    const [arrangementDueDate, setArrangementDueDate] = useState(() => {
        const initRecipientsDueDateList = formatDueDateRecipients.map(recipient => new Date(recipient.dueDate));
        const initArrangementDueDate = convertToISOString(Math.max.apply(null, initRecipientsDueDateList));
        return initArrangementDueDate;
    });
    const [errorMessages, setErrorMessages] = useState([]);
    const [submissionResponse, setSubmissionResponse] = useState(null);
    const [isOperationComplete, setIsOperationComplete] = useState(false);
    const modalId = 'arrangement_duedate-' + arrangementId;

    const submitToExtendDueDateAsync = async () => {
        const noErrorMessages = errorMessages.length === 0;
        if (noErrorMessages) {
            dispatch(ExtendDueDateAsync({
                id: arrangementId,
                dueDate: arrangementDueDate,
                recipients
            }))
                .then((response) => {
                    const ok = response?.payload?.status && response.payload.status === "OK";
                    const message = ok ? response?.payload?.message : response?.error?.message;
                    setSubmissionResponse({ ok, message })
                    if (ok) {
                        setLastSavedRecipients(recipients);
                        setIsOperationComplete(true);
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    const updateArrangementRecipients = (recipients) => {
        dispatch(updateRecipients({ recipients, arrangementId }));
    }

    const resetAll = () => {
        updateArrangementRecipients(lastSavedRecipients);
        setErrorMessages([])
        setSubmissionResponse(null);
        setIsOperationComplete(false);
    }

    return (
        <div className="modal fade show w-auto h-auto" style={{ top: "5%", left: "40%" }} id={modalId} tabIndex="-1" aria-labelledby="arrangement_duedate" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-scrollable m-0" role="document">
                <div className="modal-content">
                    <DueDateExtensionModalHeader documentName={documentName} reset={resetAll} />
                    <div className="modal-body">
                        <div className="container">
                            <div className="row row-cols-1 justify-content-center g-5">
                                <div className="col">
                                    <div className="recipients-card">
                                        <DueDateExtensionRecipients
                                            isOperationComplete={isOperationComplete}
                                            lastSavedRecipients={lastSavedRecipients}
                                            recipients={recipients}
                                            errorMessages={errorMessages}
                                            setArrangementDueDate={setArrangementDueDate}
                                            updateArrangementRecipients={updateArrangementRecipients}
                                            setErrorMessages={setErrorMessages}
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <DueDateExtensionNotification response={submissionResponse} />
                                </div>
                                <div className="col mb-2">
                                    <DueDateExtensionSubmitButton
                                        hidden={isOperationComplete}
                                        disabled={errorMessages.length > 0}
                                        handleSubmit={submitToExtendDueDateAsync}
                                    />
                                </div>
                                <div className="col">
                                    <DueDateExtensionCloseButton reset={resetAll} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}