import React, { useEffect, useState } from 'react';
import { SignersNavigationItem } from './signersNavigationItem';

export function SignersNavigation(props) {
    const [recipients, setRecipients] = useState(props.recipients);
    const [nextSignerIndex, setNextSignerIndex] = useState(0);
    const isConcurrentSigningOrder = props.recipients.every(r => r.signingOrder === 1);

    useEffect(() => {
        if (recipients !== props.recipients) {
            setRecipients(props.recipients);
        }

        if (isConcurrentSigningOrder)
            setNextSignerIndex(-1);
        else {
            setNextSignerIndex(() => {
                let recipients = props.recipients;
                let idx = 0;
                for (let recipient of recipients) {
                    let isUnsigned = recipient.coordinates.every(c => c.signatureStateCode === "UNSIGNED");
                    let isSigned = recipient.coordinates.every(c => c.signatureStateCode === "COMPLETED");
                    let isDeclined = recipient.coordinates.some(c => c.signatureStateCode === "DECLINED");
                    let isExpired = recipient.coordinates.some(c => c.signatureStateCode === "EXPIRED");
                    let isTerminated = recipient.coordinates.some(c => c.signatureStateCode === "TERMINATED");
                    let isInProgress = recipient.coordinates.some(c => c.signatureStateCode === "UNSIGNED") &&
                        recipient.coordinates.some(c => c.signatureStateCode === "COMPLETED") &&
                        !isDeclined && !isExpired && !isTerminated;

                    if (isSigned)
                        idx++;
                    else
                        return idx;
                }
            })
        }
    }, [props.recipients, recipients, setRecipients]);

    const stringifyNumber = (n) => {
        let special = ['Zeroth', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth', 'Eleventh', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth', 'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth'];
        let deca = ['Twent', 'Thirt', 'Fort', 'Fift', 'Sixt', 'Sevent', 'Eight', 'Ninet'];
        if (n < 20) return special[n];
        if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth';
        return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
    }

    return (
        <div className="nav-left mt-5">
            <h4>{isConcurrentSigningOrder}</h4>
            <h4 className="pb-0">List of Signers</h4>
            <ul className="nav nav-tabs" role="tablist">
                {(() => {
                    if (Array.isArray(recipients) && recipients.length > 0) {
                        return recipients.map((p, i) => (
                            <SignersNavigationItem
                                key={p.id}
                                index={i}
                                navLabel={`${stringifyNumber(i + 1)} Signer`}
                                recipientName={p.name}
                                recipientId={p.id}
                                activeRecipient={props.activeRecipient}
                                onActiveRecipientChanged={props.onActiveRecipientChanged}
                                coordinates={p.coordinates}
                                isNextSigner={isConcurrentSigningOrder || nextSignerIndex === i}
                            ></SignersNavigationItem>
                        ));
                    }
                })()}
            </ul>
        </div>
    );
}
