
export function DueDateExtensionNotification(props) {
    const response = props.response;
    const isSuccess = response?.ok;
    const message = response?.message;
    const headerCSS = isSuccess ? "alert-success" : "alert-danger";
    const textCSS = isSuccess ? "text-success" : "text-danger";

    if (response) {
        return (
            <div className={`alert ${headerCSS}`} role="alert">
                <div className={`${textCSS}`} role="status">
                    {message}
                </div>
            </div>
        )
    }

}