import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    newDraftArrangementAsync,
    newDraftArrangementFormAsync,
    selectStatus,
    selectUploadedDocName,
    selectUploadedDocTitle,
    selectUploadedDocForum,
    updateDocumentTitle,
    updateDocumentForum,
    selectUploadedDocPageSerialization,
    updatePageSerialization,
    selectError,
    selectCurrentStep,
    unSetError,
    setRasterProgress
} from '../signingRequestWizardSlice';
//import {KonvaCanvas} from "../konva-test";
import $ from 'jquery';
import KeycloakService from '../../../keycloakService';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { isStringNullOrEmpty, isArrayNullOrEmpty } from '../../../utils/validation';


export function UploadDocument(props) {
    const [selectedFile, setSelectedFile] = useState(null);
    const appSettings = props.appSettings;
    const sFileError = useSelector(selectError);
    const sDocTitle = useSelector(selectUploadedDocTitle);
    const sFileName = useSelector(selectUploadedDocName);
    const sDocForum = useSelector(selectUploadedDocForum);
    const sRequirePageSerialization = useSelector(selectUploadedDocPageSerialization);
    const status = useSelector(selectStatus);
    const dispatch = useDispatch();
    const wizardStatus = useSelector(selectStatus);
    const currentStep = useSelector(selectCurrentStep);
    const [error, setError] = useState({ docTitle: null });
    const [availableForums, setAvailableForums] = useState([]);
    const fileInputRef = useRef();

    useEffect(() => {
        if (appSettings) {
            const userRoles = KeycloakService.GetUserRoles(appSettings.keycloakClientId);
            const userEmail = KeycloakService.GetEmail();
            setAvailableForums([...userRoles, userEmail]);
        }
    }, [appSettings])

    let showModal = false;
    let percent = 0;
    const [isFileSelected, SetIsFileSelected] = useState(false);
    if (isFileSelected) showModal = true;

    switch (status) {
        case 'loading':
            percent = 75;
            break;
        case 'fulfilled':
            percent = 100;
            showModal = false
            break;
        case 'failed':
            percent = 100;
            showModal = false;
            break;
    }

    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
        readFileContent();
    });

    const fileUploadButtonClick = useCallback(() => {
        fileInputRef.current?.click();
    }, [])

    const selectFile = () => {
        fileUploadButtonClick()
    };
    // useEffect(() => {
    //     if (selectedFile !== null) {
    //         reader.readAsDataURL(selectedFile);

    //     }
    // }, [selectedFile]);

    const onFileDragandDrop = (e) => {
        dispatch(unSetError());

        if (isStringNullOrEmpty(sDocTitle)) {
            setError({ ...error, docTitle: "Document title is required" });
            fileInputRef.current.value = "";
            return;
        }
        setError({ docTitle: null });

        let fileToUpload = e;
        console.log('File Content:', fileToUpload);
        console.log(fileToUpload.name);
        setSelectedFile(fileToUpload);
        if (isStringNullOrEmpty(sDocForum)) {
            dispatch(updateDocumentForum(availableForums[0]));
        }
        console.log("file to upload:");
        console.log(fileToUpload);
        const payload = {
            file: fileToUpload,
            docTitle: sDocTitle,
            docForum: isStringNullOrEmpty(sDocForum) ? availableForums[0] : sDocForum,
            docRequirePageSerialization: sRequirePageSerialization
        };
        dispatch(newDraftArrangementFormAsync(payload));
    };


    const onFileSelected = (e) => {
        SetIsFileSelected(true);

        dispatch(unSetError());
        setError({ docTitle: null });
        if (isStringNullOrEmpty(sDocTitle)) {
            setError({ ...error, docTitle: "Document title is required" });
            fileInputRef.current.value = "";
            return;
        }

        let fileToUpload = e.target.files[0];
        console.log('File Content:', fileToUpload);
        console.log(fileToUpload.name);
        setSelectedFile(fileToUpload);

        if (isStringNullOrEmpty(sDocForum)) {
            dispatch(updateDocumentForum(availableForums[0]));
        }

        console.log("file to upload:");
        console.log(fileToUpload);
        const payload = {
            file: fileToUpload,
            docTitle: sDocTitle,
            docForum: isStringNullOrEmpty(sDocForum) ? availableForums[0] : sDocForum,
            docRequirePageSerialization: sRequirePageSerialization
        };

        dispatch(newDraftArrangementFormAsync(payload));
    };

    const readFileContent = (e) => {
        const fileContent = reader.result;

        if (isStringNullOrEmpty(sDocForum)) {
            dispatch(updateDocumentForum(availableForums[0]));
        }
        const payload = {
            file: selectedFile,
            docTitle: sDocTitle,
            docForum: isStringNullOrEmpty(sDocForum) ? availableForums[0] : sDocForum,
            docRequirePageSerialization: sRequirePageSerialization
        };

        dispatch(newDraftArrangementFormAsync(payload));
    };



    let isUploadingInfo = 'd-none';
    if (wizardStatus === 'loading') {
        isUploadingInfo = '';
    }

    const onDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    // const onDragEnter = (e) => {
    //     e.stopPropagation();
    // };

    const displayForums = () => {
        return availableForums.map((forum, idx) => {
            return (
                <option value={forum} key={idx}>
                    {forum}
                </option>
            );
        });
    };
    return (
        <div>
            <h2 className="text-left my-3">Upload Document</h2>
            <div className="col-md-8">
                <div className="form-check text-left">
                    <label hidden className="form-check-label" htmlFor="flexCheckChecked">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="checkbox-name"
                            id="flexCheckChecked"
                            checked={sRequirePageSerialization}
                            onChange={(e) => dispatch(updatePageSerialization(e.target.checked))}
                        />
                        Require page serialization<span className="checkbox-overlay"></span>
                    </label>
                </div>
            </div>

            {appSettings && !isArrayNullOrEmpty(availableForums) && (
                <div className="col-md-8">
                    <div className="form-group required">
                        <label htmlFor="inDocForum" className="col-form-label">
                            Folder
                        </label>
                        <select id="inDocForum" className="form-select form-control" value={sDocForum} onChange={(e) => dispatch(updateDocumentForum(e.target.value))}>
                            <option value="" key="-1">
                                Select Folder
                            </option>
                            {displayForums()}
                        </select>
                    </div>
                </div>
            )}

            <div className="col-md-8">
                <div className="form-group required">
                    <label className="col-form-label">Document Title</label>
                    <input
                        type="text"
                        id="inDocTitle"
                        className={`form-control ${error.docTitle && "is-invalid"}`}
                        name="inDocTitle"
                        placeholder=""
                        aria-label=""
                        aria-describedby=""
                        value={sDocTitle}
                        onChange={(e) => {
                            dispatch(updateDocumentTitle(e.target.value));
                            //setDocTitle(e.target.value);
                        }}
                    />
                    {error.docTitle && <div className="invalid-feedback">
                        {error.docTitle}
                    </div>}
                </div>
            </div>
            <div className="col-md-12">
                <div className="form-group required">


                    <Modal show={showModal} className='upload-doc'>
                        <Modal.Header>
                            <Modal.Title>Uploading the document...</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <ProgressBar now={percent} label={`${percent}%`} />
                        </Modal.Body>

                        <Modal.Footer>

                        </Modal.Footer>
                    </Modal>


                    <label className="col-form-label pb-0">Upload the document that requires signature</label>
                    <small className="form-text text-muted mt-0 mb-3">Only 1 document upload is allowed</small>

                    <label htmlFor="file-input">{sFileName}</label>

                    <input id="file-input" ref={fileInputRef} type="file" name="file-input" accept=".pdf" className="d-none" onChange={onFileSelected} />
                    <div
                        className="dropzone"
                        // onDragEnter={(e) => onDragEnter(e)}
                        onDragOver={(e) => onDragOver(e)}

                        onDrop={(e) => {
                            e.preventDefault();
                            console.log('Files:', e.dataTransfer.files);
                            console.log('Files Main:', e);
                            e.stopPropagation();
                            onFileDragandDrop(e.dataTransfer.files[0]);
                        }}
                        onClick={(e) => {
                            selectFile();
                        }}
                    >
                        <div className="dropzone-icon">
                            <span>
                                <i className="fal fa-upload" />
                            </span>
                        </div>
                        <div className="dropzone-text">
                            <h3>Drop Zone</h3>
                            <p>Click here to upload your document</p>
                            <p>Recommended file size: 45mb and below</p>
                            <p>File formats: PDF</p>
                        </div>
                    </div>
                </div>
                {currentStep === 1 && sFileError && (
                    <div className="alert alert-danger" role="alert">
                        {sFileError}
                    </div>
                )}
            </div>
        </div >
    );
}
