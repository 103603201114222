import KeycloakService from '../../keycloakService';
import { error } from 'jquery';

const APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const FetchNewDraftArrangementUrl = '/UploadDocument/CreateNewDraftArrangement';
const SubmitArrangementUrl = '/SubmitArrangement';
const FetchDocumentSnapshotsUrl = '/UploadDocument/GetPdfSnapshot';
const FetchPageSnapshotsUrl = '/UploadDocument/GetPdfPageSnapshot';

function jsonDataToFormData(data) {
    const formData = new FormData();

    for (const name in data) {
        formData.append(name, data[name]);
    }

    return formData;
}
export async function fetchNewDraftArrangementForm(file, docTitle, docForum, requirePageSerialization) {
    console.log("[api]: fileTo upload:");
    console.log(file);
    let formData = new FormData();
    formData.append("File", file);
    formData.append("DocumentTitle", docTitle);
    formData.append("Forum", docForum);
    formData.append("RequirePageSerialization", requirePageSerialization);

    const headers = {
        Authorization: 'Bearer ' + (await KeycloakService.GetToken()),
        Accept: 'application/json'
        //,'Content-Type': 'multipart/form-data '
    };

    return new Promise((resolve, reject) => {
        const url = `${APP_SERVER_URL}${FetchNewDraftArrangementUrl}`;
        fetch(url, {
            method: 'post',
            headers: headers,
            body: formData,
            cache: 'default'
        })
            .then((response) => {
                return new Promise((resolve) =>
                    response.json().then((json) => {
                        if (response.ok) {
                            resolve({
                                status: response.status,
                                ok: response.ok,
                                ...json
                            });
                        } else {
                            resolve({
                                status: response.status,
                                ok: response.ok,
                                json
                            });
                        }
                    })
                );
            })
            .then((response) => {
                if (response.ok) resolve(response);
                else {
                    // reject(Error(response.json.title));
                    reject(Error(response.json.errors.DocumentTitle[0]));

                }
            })
            .catch((error) => {
                reject(Error(error.message));
            });
    });


}

export async function fetchDocumentSnapshots(arrangementId) {
    console.log("about to fetch doc snapshots (" + arrangementId + ")");
    const headers = {
        Authorization: 'Bearer ' + (await KeycloakService.GetToken()),
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    return new Promise((resolve, reject) => {
        const url = `${APP_SERVER_URL}${FetchDocumentSnapshotsUrl}?arrangementId=${arrangementId}`;
        fetch(url, {
            method: 'get',
            headers: headers,
            cache: 'default'
        })
            .then((response) => {
                return new Promise((resolve) =>
                    response.json().then((json) => {
                        if (response.ok) {
                            console.log("response received ok");
                            resolve({
                                status: response.status,
                                ok: response.ok,
                                ...json
                            });
                        } else {
                            console.log("response received nok");
                            resolve({
                                status: response.status,
                                ok: response.ok,
                                json
                            });
                        }
                    })
                );
            })
            .then((response) => {
                console.log("response received after second then");
                if (response.ok) resolve(response);
                else {
                    reject(Error(response.json.title));
                }
            })
            .catch((error) => {
                console.log("error received");
                reject(Error(error.message));
            });
    });

}

export async function fetchDocumentPageSnapshots(arrangementId, pageNumber) {
    console.log(`about to fetch page snapshots (${arrangementId}) [${pageNumber}]`);
    const headers = {
        Authorization: 'Bearer ' + (await KeycloakService.GetToken()),
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    return new Promise((resolve, reject) => {
        const url = `${APP_SERVER_URL}${FetchPageSnapshotsUrl}?arrangementId=${arrangementId}&pageNum=${pageNumber}`;
        fetch(url, {
            method: 'get',
            headers: headers,
            cache: 'default'
        })
            .then((response) => {
                return new Promise((resolve) =>
                    response.json().then((json) => {
                        if (response.ok) {
                            console.log("response received ok");
                            resolve({
                                status: response.status,
                                ok: response.ok,
                                ...json
                            });
                        } else {
                            console.log("response received nok");
                            resolve({
                                status: response.status,
                                ok: response.ok,
                                json
                            });
                        }
                    })
                );
            })
            .then((response) => {
                console.log("response received after second then");
                if (response.ok) resolve(response);
                else {
                    reject(Error(response.json.title));
                }
            })
            .catch((error) => {
                console.log("error received");
                reject(Error(error.message));
            });
    });

}

export async function fetchNewDraftArrangement(fileContent, fileName, docTitle, docForum, requirePageSerialization) {
    let data = JSON.stringify({
        Content: fileContent,
        FileName: fileName,
        DocumentTitle: docTitle,
        Forum: docForum,
        RequirePageSerialization: requirePageSerialization
    });

    const headers = {
        Authorization: 'Bearer ' + (await KeycloakService.GetToken()),
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    return new Promise((resolve, reject) => {
        const url = `${APP_SERVER_URL}${FetchNewDraftArrangementUrl}`;
        fetch(url, {
            method: 'post',
            headers: headers,
            body: data,
            cache: 'default'
        })
            .then((response) => {
                return new Promise((resolve) =>
                    response.json().then((json) => {
                        if (response.ok) {
                            resolve({
                                status: response.status,
                                ok: response.ok,
                                ...json
                            });
                        } else {
                            resolve({
                                status: response.status,
                                ok: response.ok,
                                json
                            });
                        }
                    })
                );
            })
            .then((response) => {
                if (response.ok) resolve(response);
                else {
                    reject(Error(response.json.title));
                }
            })
            .catch((error) => {
                reject(Error(error.message));
            });
    });
}

export async function submitArrangement(payload) {


    let data = JSON.stringify(payload);
    console.log(payload);
    const headers = {
        Authorization: 'Bearer ' + (await KeycloakService.GetToken()),
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };
    return new Promise((resolve, reject) => {
        const url = `${APP_SERVER_URL}${SubmitArrangementUrl}`;
        fetch(url, {
            method: 'post',
            headers: headers,
            body: data,
            cache: 'default'
        })

            .then((response) => {
                if (response.ok) {
                    resolve(response.json());
                }
                console.log('response:', response);
                //console.log('response.json:', response.json());
                return response.json();
            }).then((message) => {
                console.log('message:', message.errmessage);
                console.log('messagetostring:', message);
                const obj = JSON.parse(message.errmessage);
                console.log('jsonparseValue:', obj.Message);
                console.log('jsonreplace:', obj.Message.replace(/[^a-zA-Z0-9 ]/g, ''));
                if (obj.Message.includes("1 or more of the signature placeholders")) {
                    reject(Error(obj.Message.replace(/[\[\]"\\]/g, '')));
                }
                else {
                    reject(Error('The server encountered an error. Please try again later.'));
                }

            })
            .catch((error) => {
                reject(Error('The server encountered an error. Please try again later.'));

            });
    });
}
