import React, { Component } from 'react';

class SignersList extends Component {
    signers = [];
    constructor(props) {
        super(props);
        this.signers = this.props.data;
    }
    render() {
        let arrayNode = this.signers.map((recipient, index) => (
            <div className="card-item d-flex" key={index}>
                <h5>{recipient.name}</h5>
            </div>
        ));

        return (
            <div className="card has-list has-options text-left h-auto mb-2">
                <div className="card-header">
                    <h5 className="card-title">Signers</h5>
                </div>
                <div className="card-body">{arrayNode}</div>
            </div>
        );
    }
}

export default SignersList;
