import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectStatus,
    fetchPendingSignaturesAsync,
    fetchExpiredSignaturesAsync,
    fetchSignedSignaturesAsync,
    fetchDeclinedSignaturesAsync,
    fetchAppSettingsAsync,
    selectPendingData,
    selectSignedData,
    selectExpiredData,
    selectDeclinedData,
    selectReloadRequired,
    unSetReloadRequired,
    selectAppSettings,
    selectIsLoading
} from './myDashboardSlice';
import { initStartStep } from '../newSigningRequestWizard/signingRequestWizardSlice';
import { SIGNED, PENDING, EXPIRED, DECLINED, IDLE } from './common';
import { ArrangementListSwitcher } from './arrangementListSwitcher';
import { ArrangementListSearch } from './arrangementListSearch';
import { ArrangementListSorter } from './arrangementListSorter';
import { ArrangementList } from './arrangementList';
import KeycloakService from '../../keycloakService';
import Loader from '../../components/Loader';

export function ArrangerDashboard() {
    const dispatch = useDispatch();
    // const [loading, setLoading] = useState(true);
    const appSettings = useSelector(selectAppSettings);
    const dashboardStatus = useSelector(selectStatus);
    const pendingSigning = useSelector(selectPendingData);
    const expiredSigning = useSelector(selectExpiredData);
    const signedSigning = useSelector(selectSignedData);
    const declinedSigning = useSelector(selectDeclinedData);
    const reloadRequired = useSelector(selectReloadRequired);
    const isLoading = useSelector(selectIsLoading);
    const [tab, setTab] = useState(PENDING);

    const pendingTabSelected = tab === PENDING ? 'active' : '';
    const signedTabSelected = tab === SIGNED ? 'active' : '';
    const expiredTabSelected = tab === EXPIRED ? 'active' : '';
    const declinedTabSelected = tab === DECLINED ? 'active' : '';

    const fetchAppSettings = useCallback(async () => {
        dispatch(fetchAppSettingsAsync());
    }, [dispatch]);

    const navigate = useNavigate();
    console.log("dashboard status is: ");
    console.log(dashboardStatus);
    useEffect(() => {
        // if (loading) {
        //     setLoading(false);
        //     fetchAppSettings()
        // }
        if (dashboardStatus.appSettingsFetching === IDLE) {
            fetchAppSettings();
        }
        if (dashboardStatus.general === IDLE || reloadRequired) {

            dispatch(fetchPendingSignaturesAsync(constructAPIParams(1, pendingSigning.maxRecordsOption[0], '', '')));
            dispatch(fetchExpiredSignaturesAsync(constructAPIParams(1, expiredSigning.maxRecordsOption[0], '', '')));
            dispatch(fetchSignedSignaturesAsync(constructAPIParams(1, signedSigning.maxRecordsOption[0], '', '')));
            dispatch(fetchDeclinedSignaturesAsync(constructAPIParams(1, declinedSigning.maxRecordsOption[0], '', '')));
            dispatch(unSetReloadRequired());
        }
    }, [expiredSigning.maxRecordsOption, pendingSigning.maxRecordsOption, signedSigning.maxRecordsOption, declinedSigning.maxRecordsOption, dashboardStatus.general, reloadRequired, dispatch, fetchAppSettings]
    );

    const onPageChanged = (data) => {
        if (tab === PENDING) {
            dispatch(fetchPendingSignaturesAsync(constructAPIParams(data.pageNumber, data.pageSize, pendingSigning.searchKeyword, pendingSigning.sortBy)));
        } else if (tab === SIGNED) {
            dispatch(fetchSignedSignaturesAsync(constructAPIParams(data.pageNumber, data.pageSize, signedSigning.searchKeyword, signedSigning.sortBy)));
        } else if (tab === EXPIRED) {
            dispatch(fetchExpiredSignaturesAsync(constructAPIParams(data.pageNumber, data.pageSize, expiredSigning.searchKeyword, expiredSigning.sortBy)));
        } else {
            dispatch(fetchDeclinedSignaturesAsync(constructAPIParams(data.pageNumber, data.pageSize, declinedSigning.searchKeyword, declinedSigning.sortBy)));
        }
    };

    const onPageSizeChanged = (data) => {
        if (tab === PENDING) {
            dispatch(fetchPendingSignaturesAsync(constructAPIParams(1, data, pendingSigning.searchKeyword, pendingSigning.sortBy)));
        } else if (tab === SIGNED) {
            dispatch(fetchSignedSignaturesAsync(constructAPIParams(1, data, signedSigning.searchKeyword, signedSigning.sortBy)));
        } else if (tab === EXPIRED) {
            dispatch(fetchExpiredSignaturesAsync(constructAPIParams(1, data, expiredSigning.searchKeyword, expiredSigning.sortBy)));
        } else {
            dispatch(fetchDeclinedSignaturesAsync(constructAPIParams(1, data, declinedSigning.searchKeyword, declinedSigning.sortBy)));
        }
    };

    const onSortBySelected = (data) => {
        if (tab === PENDING) {
            dispatch(fetchPendingSignaturesAsync(constructAPIParams(1, pendingSigning.pageSize, pendingSigning.searchKeyword, data)));
        } else if (tab === SIGNED) {
            dispatch(fetchSignedSignaturesAsync(constructAPIParams(1, signedSigning.pageSize, signedSigning.searchKeyword, data)));
        } else if (tab === EXPIRED) {
            dispatch(fetchExpiredSignaturesAsync(constructAPIParams(1, expiredSigning.pageSize, expiredSigning.searchKeyword, data)));
        } else {
            dispatch(fetchDeclinedSignaturesAsync(constructAPIParams(1, declinedSigning.pageSize, declinedSigning.searchKeyword, data)));
        }
    };

    const onTabChanged = (data) => {
        setTab(data);
    };

    const onSearchClicked = (data) => {
        if (tab === PENDING) {
            dispatch(fetchPendingSignaturesAsync(constructAPIParams(1, pendingSigning.pageSize, data, pendingSigning.sortBy)));
        } else if (tab === SIGNED) {
            dispatch(fetchSignedSignaturesAsync(constructAPIParams(1, signedSigning.pageSize, data, signedSigning.sortBy)));
        } else if (tab === EXPIRED) {
            dispatch(fetchExpiredSignaturesAsync(constructAPIParams(1, expiredSigning.pageSize, data, expiredSigning.sortBy)));
        } else {
            dispatch(fetchDeclinedSignaturesAsync(constructAPIParams(1, declinedSigning.pageSize, data, declinedSigning.sortBy)));
        }
    };

    const constructAPIParams = (pageNumber, pageSize, searchKeyword, sortBy) => {
        return {
            pageNumber,
            pageSize,
            searchKeyword,
            sortBy
        };
    };


    return (
        <>
            {/* <Loader show={dashboardStatus.general === "loading"} /> */}
            <Loader show={isLoading} />
            <div className="container-fluid py-5">
                {appSettings && KeycloakService.GetUserRoles(appSettings.keycloakClientId).length === 0
                    && KeycloakService.GetEmail().length === 0 &&
                    (
                        <div className="alert alert-danger" role="alert">
                            You are not assigned to any folder. Please check with Administrator.
                        </div>
                    )}


                <div className="horizontal-tab">
                    <button
                        className="btn btn-primary btn-absolute"
                        disabled={appSettings && KeycloakService.GetUserRoles(appSettings.keycloakClientId).length === 0
                            && KeycloakService.GetEmail().length === 0}
                        onClick={(e) => {
                            dispatch(initStartStep());
                            navigate('NewSigningRequest');
                        }}
                    >
                        Request signature
                    </button>
                    <ArrangementListSwitcher
                        pendingSignatureCount={pendingSigning.signingRequestCount}
                        signedSignatureCount={signedSigning.signingRequestCount}
                        expiredSignatureCount={expiredSigning.signingRequestCount}
                        declinedSignatureCount={declinedSigning.signingRequestCount}
                        onTabChanged={onTabChanged}
                        tab={tab}
                    ></ArrangementListSwitcher>
                    <div className="tab-content">
                        <div className={`tab-pane ${pendingTabSelected}`} id="h-tab-1">
                            <div className="static-table">
                                <div className="table-search">
                                    <ArrangementListSearch searchKeyWord={pendingSigning.searchKeyWord} onSearchClicked={onSearchClicked} />
                                    <ArrangementListSorter data={pendingSigning.sortByOptions} onSortBySelected={onSortBySelected} />
                                </div>
                                <ArrangementList
                                    data={pendingSigning.signingRequestArrangements}
                                    maxRecordsOption={pendingSigning.maxRecordsOption}
                                    currentPage={pendingSigning.currentPage}
                                    pageSize={pendingSigning.pageSize}
                                    currentPageRecords={pendingSigning.signingRequestArrangements.length}
                                    totalPages={pendingSigning.totalPages}
                                    totalRecords={pendingSigning.signingRequestCount}
                                    onPageChanged={onPageChanged}
                                    onPageSizeChanged={onPageSizeChanged}
                                    tab={PENDING}

                                />
                            </div>
                        </div>
                        <div className={`tab-pane ${signedTabSelected}`} id="h-tab-2">
                            <div className="static-table">
                                <div className="table-search">
                                    <ArrangementListSearch searchKeyWord={signedSigning.searchKeyWord} onSearchClicked={onSearchClicked} />
                                    <ArrangementListSorter data={signedSigning.sortByOptions} onSortBySelected={onSortBySelected} />
                                </div>
                                <ArrangementList
                                    data={signedSigning.signingRequestArrangements}
                                    maxRecordsOption={signedSigning.maxRecordsOption}
                                    currentPage={signedSigning.currentPage}
                                    pageSize={signedSigning.pageSize}
                                    currentPageRecords={signedSigning.signingRequestArrangements.length}
                                    totalPages={signedSigning.totalPages}
                                    totalRecords={signedSigning.signingRequestCount}
                                    onPageChanged={onPageChanged}
                                    onPageSizeChanged={onPageSizeChanged}
                                    tab={SIGNED}

                                />
                            </div>
                        </div>
                        <div className={`tab-pane ${expiredTabSelected}`} id="h-tab-3">
                            <div className="static-table">
                                <div className="table-search">
                                    <ArrangementListSearch searchKeyWord={expiredSigning.searchKeyWord} onSearchClicked={onSearchClicked} />
                                    <ArrangementListSorter data={expiredSigning.sortByOptions} onSortBySelected={onSortBySelected} />
                                </div>
                                <ArrangementList
                                    data={expiredSigning.signingRequestArrangements}
                                    maxRecordsOption={expiredSigning.maxRecordsOption}
                                    currentPage={expiredSigning.currentPage}
                                    pageSize={expiredSigning.pageSize}
                                    currentPageRecords={expiredSigning.signingRequestArrangements.length}
                                    totalPages={expiredSigning.totalPages}
                                    totalRecords={expiredSigning.signingRequestCount}
                                    onPageChanged={onPageChanged}
                                    onPageSizeChanged={onPageSizeChanged}
                                    tab={EXPIRED}

                                />
                            </div>
                        </div>
                        <div className={`tab-pane ${declinedTabSelected}`} id="h-tab-4">
                            <div className="static-table">
                                <div className="table-search">
                                    <ArrangementListSearch searchKeyWord={declinedSigning.searchKeyWord} onSearchClicked={onSearchClicked} />
                                    <ArrangementListSorter data={declinedSigning.sortByOptions} onSortBySelected={onSortBySelected} />
                                </div>
                                <ArrangementList
                                    data={declinedSigning.signingRequestArrangements}
                                    maxRecordsOption={declinedSigning.maxRecordsOption}
                                    currentPage={declinedSigning.currentPage}
                                    pageSize={declinedSigning.pageSize}
                                    currentPageRecords={declinedSigning.signingRequestArrangements.length}
                                    totalPages={declinedSigning.totalPages}
                                    totalRecords={declinedSigning.signingRequestCount}
                                    onPageChanged={onPageChanged}
                                    onPageSizeChanged={onPageSizeChanged}
                                    tab={DECLINED}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
