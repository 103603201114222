import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
    fetchAppSettingsAsync,
    selectAccessCodes,
    selectArrangementId,
    selectDueDate,
    selectDirectLink,
    selectRecipients,
    selectUploadedDocSnapshot,
    selectCurrentStep,
    submitArrangementAsync,
    selectAllStampsInDoc,
    selectIsLoading,
    selectUploadedDocTitle,
    selectUploadedDocPageSerialization,
    selectUploadedDocForum,
    selectAppSettings,
    storeSignatureOrder,
    unSetError,
    showModal,
    setRasterProgress,
    selectRasterProgress,
    storeSignatureOrderType,
    selectSignatureOrderTypeCode
} from './signingRequestWizardSlice';
import { HelpVideoTour } from './shared/helpVideoTour';
import { WizardNavButtons } from './shared/wizardNavButtons';
import { WizardProgressBar } from './shared/wizardProgressBar';
import { UploadDocument } from './step1/uploadDocument';
import { AddRecipientsStep } from './step2/addRecipientsStep';
import { AddPlaceHolderForm } from './step3/addPlaceholderForm';
import { PreviewSubmitPage } from './step4/previewSubmitPage';
import { Confirmation } from './confirmation';
import KeycloakService from '../../keycloakService';
import { Spinner } from '../../components/Spinner';
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';

// import spinner from '../images/spinner.svg';

const APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

export function NewSigningRequestWizard(props) {
    const curStep = useSelector(selectCurrentStep);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const rasterProgress = useSelector(selectRasterProgress);
    //const [allowNext, setAllowNext] = useState(false);
    const [allowNext] = useState(false);
    //const [isLoading, setIsLoading] = useState(false);
    const appSettings = useSelector(selectAppSettings);
    const isLoading = useSelector(selectIsLoading);
    const arrangementId = useSelector(selectArrangementId);
    const dueDate = useSelector(selectDueDate);
    //const documentTitle = useSelector(selectUploadedDocTitle);
    const documentSnapshot = useSelector(selectUploadedDocSnapshot);
    const accessCodes = useSelector(selectAccessCodes);
    const directLink = useSelector(selectDirectLink);
    const recipients = useSelector(selectRecipients);
    const stamps = useSelector(selectAllStampsInDoc);
    const docTitle = useSelector(selectUploadedDocTitle);
    const requirePageSerialization = useSelector(selectUploadedDocPageSerialization);
    const docForum = useSelector(selectUploadedDocForum);
    const signatureOrderType = useSelector(selectSignatureOrderTypeCode);


    const fetchAppSettings = useCallback(async () => {
        dispatch(fetchAppSettingsAsync());
    }, [dispatch]);
    useEffect(() => {
        if (loading) {
            setLoading(false);
            fetchAppSettings()
        }
    }, [loading, fetchAppSettings])

    //singnalR
    const [connection, setConnection] = useState(null);
    useEffect(() => {
        const CreateConnection = async () => {
            const newConnection = new HubConnectionBuilder()
                .withUrl(`${APP_SERVER_URL}/hubs/rasterprogress`,
                    { transport: HttpTransportType.LongPolling })
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);
        }

        CreateConnection();

    }, []);

    useEffect(() => {
        if (connection) {
            connection.start({ transport: 'longPolling' })
                .then(result => {
                    console.log('Connected!');

                    connection.on('ReceiveMessage', message => {
                        console.log('msg received!', message);
                        dispatch(setRasterProgress(Math.trunc(Number(message) * 95 / 100)));
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    //singnalR

    useEffect(() => {
        console.log("[newSigningRequestWizard] rerendering due to documentSnapshot update");
    }, [documentSnapshot]);

    useEffect(() => {
        console.log("[newSigningRequestWizard] rerendering due to change of status in isLoading");
    }, [isLoading]);

    // const enableNextStep = () => {
    //     setAllowNext(true);
    // };

    const getSigCoordinates = (signerId, signerName) => {
        if (!stamps.hasOwnProperty(signerId)) return [];

        const sigCoordinates = stamps[signerId].map((t) => ({
            X: t.coordinate.x,
            Y: t.coordinate.y,
            Page: t.coordinate.page,
            ScaleRatio: t.coordinate.scaleRatio,
            Counter: t.counter,
            Label: `${t.signerName} (${t.counter})`,
            SecureCodeSecret: t.secureCode,
            RequireSecureCode: t.hasSecureCode
        }));
        return sigCoordinates;
    };
    const submitArrangement = () => {
        dispatch(unSetError());
        const payload = {
            ArrangementId: arrangementId,
            DueDate: dueDate,
            DocumentTitle: docTitle,
            RequirePageSerialization: requirePageSerialization,
            Forum: docForum,
            SignatureOrderType: signatureOrderType,
            Recipients: recipients.map((t) => ({
                Name: t.name,
                IdentificationNum: t.identificationNum,
                EmailAddress: t.emailAddress,
                MobileNum: t.mobileNum,
                DueDate: t.dueDate,
                SigningOrder: t.signingOrder,
                SigCoordinates: getSigCoordinates(t.id, t.Name),
                SigningMethod: t.signingmethod,
                identificationType: t.identificationType,
                ReminderTriggerDays: t.reminderTriggerDays,
                EmailTemplateContent: t.isReminderRequired ? t.emailTemplateContent : '',  //Add condition in order to clear dirty data
                EmailTemplateSubject: t.isReminderRequired ? t.emailTemplateSubject : '', //Add condition in order to clear dirty data
                isReminderRequired: t.isReminderRequired,
                isReminderDisplayed: t.isReminderDisplayed
            }))
        };
        dispatch(submitArrangementAsync(payload));
    };

    const showProgressModal = () => {
        dispatch(showModal(true));
    };


    const showMainPanel = () => {

        if (curStep === 1) {

            return <UploadDocument appSettings={appSettings} />;
        } else if (curStep === 2) {
            //return <AddRecipientsStep onaddrecipienttostore ={ addrecipienttostore} />;
            return <AddRecipientsStep />;
        } else if (curStep === 3) {

            return <AddPlaceHolderForm appSettings={appSettings} />;
        } else if (curStep === 4) {
            return <PreviewSubmitPage appSettings={appSettings} />;
        } else if (curStep === 5) {
            return <Confirmation accessCodes={accessCodes} directLink={directLink} />;
        }
        return '';
    };

    let showHide = 'd-none';
    if (isLoading) {
        showHide = '';
    }

    return (
        <>

            {appSettings && KeycloakService.GetUserRoles(appSettings.keycloakClientId).length === 0
                && KeycloakService.GetEmail().length === 0 && <Navigate to="/" />}
            <div>
                <div className="container-fluid py-5">
                    <WizardProgressBar />

                    {showMainPanel()}


                    <div className={showHide}>
                        <Spinner></Spinner>

                    </div>
                </div>

                <WizardNavButtons onSubmitArrangement={submitArrangement} allowNext={allowNext} isLoading={isLoading} onShowProgressModal={showProgressModal} rasterProgress={rasterProgress} />
            </div>
        </>
    );
}
