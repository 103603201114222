import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ArrangementItemDetail } from './arrangementDetail/arrangementItemDetail';
import { deleteArrangementAsync, downloadDocumentAsync } from './myDashboardSlice';
//import { APP_SERVER_URL } from '../../appSettings';
import KeycloakService from '../../keycloakService';
//import { loadAppSettings } from '../../../AppSettings';
import { DueDateExtensionModal } from './dueDateExtensionModal/dueDateExtensionModal';
import { PENDING } from './common';

export function SigningRequestArrangement(props) {
    const defaultData = {
        id: '',
        forum: '',
        documentName: '',
        documentTitle: '',
        documentCategory: '',
        recipientCount: '',
        displayCreatedDate: '',
        hasSignedDocument: false,
        allowView: false,
        allowEdit: false,
        allowDelete: false,
        recipients: []
    };

    let initData = defaultData;
    if (props.data !== null) {
        initData = {
            ...props.data,
            allowDelete: props.data.maintainedBy === KeycloakService.GetUserName(),
            allowView: true,
            allowEdit: false
        };
    }
    const tab = props.tab;
    //const [data, setData] = useState(initData);
    const data = initData;
    //const [appSettings, setAppSettings] = useState(loadAppSettings().then(d => setAppSettings(d)));
    const dispatch = useDispatch();

    useEffect(() => { }, [data]);

    const renderEditAction = () => {
        if (data.allowEdit) {
            return (
                <button type="button" className="btn icon-only" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit">
                    <i className="fas fa-edit" />
                </button>
            );
        }
    };

    const renderDownloadAction = () => {
        if (data.hasSignedDocument === true && data.requestStatus === 'COMPLETED') {
            //const docLink = `${appSettings.AppServerUrl}/MyDashboard/${data.id}/signedDocument`;

            return (
                <button
                    type="button"
                    className="btn icon-only"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Download"
                    download={data.documentName}
                    onClick={(e) => {
                        let payload = {
                            id: data.id,
                            documentTitle: data.documentTitle
                        };
                        dispatch(downloadDocumentAsync(payload));
                    }}
                >
                    <i className="fas fa-download" />
                </button>
            );
        }
    };
    const renderViewAction = () => {
        if (data.allowView && data.recipients !== null && data.recipients.length > 0) {
            return (
                <button
                    type="button"
                    className="btn icon-only"
                    //data-toggle='tooltip'
                    data-placement="top"
                    title=""
                    data-toggle="modal"
                    data-target={`#arrangement_detail-${data.id}`}
                    data-original-title="View"
                >
                    <i className="fas fa-eye" />
                </button>
            );
        }
    };
    const renderDeleteAction = () => {
        if (data.allowDelete) {
            return (
                <button
                    type="button"
                    className="btn icon-only"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Delete"
                    onClick={(e) => {
                        console.log('About to delete aid: ' + data.id);
                        dispatch(deleteArrangementAsync(data.id));
                    }}
                >
                    <i className="fas fa-trash-alt" />
                </button>
            );
        }
    };

    const renderExtendDueDateAction = () => {
        if (tab === PENDING) {
            return (
                <button
                    type="button"
                    className="btn icon-only"
                    data-toggle="modal"
                    data-placement="top"
                    title=""
                    data-target={`#arrangement_duedate-${data.id}`}
                    data-original-title="ExtendDueDates"
                >
                    <i className="far fa-calendar-alt" />
                </button>
            );
        }
    };

    return (
        <tr>
            <td data-title="Folder">{data.forum}</td>
            <td data-title="Document Title">{data.documentTitle}</td>
            <td data-title="No. of Recipients">{data.recipientCount}</td>
            <td data-title="Created on">{data.displayCreatedDate}</td>
            <td data-title="Actions">
                <div className="action">
                    {renderEditAction()}
                    {renderDownloadAction()}
                    {renderViewAction()}
                    {renderDeleteAction()}
                    {renderExtendDueDateAction()}

                    <DueDateExtensionModal arrangementId={data.id} documentName={data.documentName} recipients={data.recipients} />
                    <ArrangementItemDetail arrangementId={data.id} documentName={data.documentName} recipients={data.recipients} documentTitle={data.documentTitle} />
                </div>
            </td>
        </tr>
    );
}
