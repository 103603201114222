import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AccessCodeInstruction } from '../myDashboard/arrangementDetail/accessCodeInstruction';
import { CredentialDetailWithName } from './credentialDetailWithName';
import { setReloadRequired } from '../myDashboard/myDashboardSlice';

export function Confirmation(props) {
    //const [recipients] = useState(props.recipients);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const refScrollToTop = useRef(null);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
         in place of 'smooth' */
        });
    };
    if (!refScrollToTop.current) {
        refScrollToTop.current = scrollToTop();
    }
    return (
        <div ref={refScrollToTop}>
            <div className="modal-dialog modal-dialog modal-fullwidth" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <AccessCodeInstruction />
                        <CredentialDetailWithName recipients={props.accessCodes} />
                        <div className="modal-footer">
                            <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                    dispatch(setReloadRequired());
                                    navigate('/');
                                }}
                            >
                                Go to My Dashboard
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
