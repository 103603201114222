import React from "react";

export function PreviewSubmitTitlePane() {
    return(
        <h2 className="text-left my-3">
            Preview and Submit
            <span className="has-popover">
                    <span className="popover-content align-center">
                <span className="popover-close">
                    <i className="fal fa-times" />
                </span>
                <span className="pop-title">Title</span> Lorem Ipsum is simply dummy
                text of the printing an d typesetting industry. Lorem Ipsum has
                been the industry's standard dummy
              </span></span>
        </h2>
    );
}
