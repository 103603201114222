import React, { useState, useEffect } from 'react';
import { SignerDetails } from './signerDetails';
import { CredentialDetail } from './credentialDetail';
import { RejectionReason } from './rejectionReason';
import KeycloakService from '../../../keycloakService';
import { SIGN_UI_URL } from '../../../../AppConstants';

export function RecipientCard(props) {
    const [index] = useState(props.index);
    const [name] = useState(props.recipient.name);
    const [email] = useState(props.recipient.email);
    const [phoneNumber] = useState(props.recipient.phoneNumber);
    const [accessCode] = useState(props.recipient.accessCode);
    const [accessRef] = useState(props.recipient.accessRef);
    //const [directLink] = useState(props.recipient.directLink);
    const dueDate = props.recipient.displayDueDate;

    const documentTitle = props.documentTitle;
    const [messageTemp, setMessageTemp] = useState([]);

    useEffect(() => {
        setMessageTemp([

            `Dear ${name},`, `${KeycloakService.GetUserName()} has requested your signature for ${documentTitle}.`,

            `Please sign the document at ${SIGN_UI_URL}?accessCode=${accessCode}`,

            `Deadline: ${dueDate}`,

            `You will need the following details from ${KeycloakService.GetUserName()} `,

            `(1) Access code`,
            `(2) Access reference`,

            `Please also ensure that you have access to your Singpass app to sign the document.`,

            `https://go.gov.sg/dss-signing-faq`
        ]);
    }, [])

    const copyToClipboard = (value, tooltipId) => {
        navigator.clipboard.writeText(value);
        console.log(value);

        let tooltip = document.getElementById(tooltipId);

        if (tooltip !== null) {
            tooltip.textContent = 'Copied: ' + value;
        }
    };

    const resetToolTip = (tooltipId) => {
        let tooltip = document.getElementById(tooltipId);

        if (tooltip !== null) {
            tooltip.textContent = 'Copy to clipboard';
        }
    };

    const active = props.activeRecipient.id === props.recipient.id ? 'show active' : '';
    return (
        <div id={'vbt-' + index} className={`tab-pane fade ${active} bg-white rounded`} role="tabpanel" aria-labelledby={`sig-tab-${index}`}>

            <div id={'vb-tab-' + index} className="collapse show px-3" data-parent="#tab-content" aria-labelledby={`sig-header-${index}`}>
                <div className="card-body">
                    <h3 className="tab-title py-3" style={{ color: "#751c24" }}>{name}</h3>

                    <div className="form-panel">
                        <div className="form-panel-body">
                            <SignerDetails isRejected={false} phone={phoneNumber} email={email} />
                            <CredentialDetail accessCode={accessCode} accessRef={accessRef} dueDate={dueDate} />
                            {props.recipient.hasDeclination && <RejectionReason reason={props.recipient.reason} />}
                        </div>
                    </div>

                    <div className="card mt-3">
                        <div className="card-header font-weight-bold border-bottom" style={{ color: "#751c24" }}>
                            Message Template &nbsp;
                            <div className="copyclipboard">
                                <button
                                    className="btn btn-outline-primary"
                                    style={{ color: "#751c24", borderColor: "#751c24" }}
                                    type="button"
                                    onClick={(e) => {
                                        copyToClipboard(messageTemp.join('\n'), 'tooltipMessage');
                                        return false;
                                    }}
                                    onMouseOut={(e) => {
                                        resetToolTip('tooltipMessage');
                                    }}
                                >
                                    <i className="far fa-copy" />
                                    <span className="tooltiptext" id="tooltipMessage">
                                        Copy to clipboard
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            {messageTemp.map((message, index) => {
                                return <p key={index} className="card-text">{message}</p>
                            })}
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
}
